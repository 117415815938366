import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Button,
  Select,
  Switch,
  Modal,
  Input,
  Collapse,
  TimePicker,
} from "antd";
import { EyeOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { CalenderGrey } from "../../../components/svgJS/CalenderRed";
import { Cube } from "../../../components/svgJS/Cube";
import moment from "moment";
import { uniqWith, isEqual, cloneDeep, isEmpty } from "lodash";
import {
  getSurveys,
  getZoneDropdowns,
  getUserTypeAgents,
  getZoneDetails,
  getSocialMediaLinks,
  fetchEventShows,
} from "../../../utils/commonApis";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import "./index.css";
import { toast } from "react-toastify";
import { dialogs } from "../../../components/constsnts/string.constants";
import "./NavigationAndReception.css";
import CreateNewZone from "../../ZoneManagement/CreateNewZone";

const NavigationAndReception = ({
  navReceptionData,
  onChangeData,
  onValid,
  initiateValidations,
}) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const validator = useRef(new SimpleReactValidator());
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const [zones, setZones] = useState([]);
  const [surveyList, setSurveyList] = useState([]);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [isLogoPreview, setIsLogoPreview] = useState(false);
  const [dataLocal, setDataLocal] = useState({});
  const [isZoneModalVisible, setIsZoneModalVisible] = useState(false);
  const [zoneData, setZoneData] = useState({});
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [chatBotDetails, setchatBotDetails] = useState([]);
  const [assetDataVisible, setAssetDataVisible] = useState(false);
  const [assetsArray, setAssetsArray] = useState([]);
  const [mediaLinks, setMediaLinks] = useState([]);

  useEffect(async () => {
    const dropdownZones = await getZoneDropdowns(
      navReceptionData && navReceptionData.event_id
        ? navReceptionData.event_id
        : ""
    );
    setZones(dropdownZones);
    const mediaLinks = await getSocialMediaLinks();
    setMediaLinks(mediaLinks);

    const surveys = await getSurveys();
    setSurveyList(surveys);
    let paramsForAgents = "";
    if (navReceptionData && navReceptionData.event_id)
      paramsForAgents = { event_id: navReceptionData.event_id };
    const agentListAPI = await getUserTypeAgents(paramsForAgents);
    console.log("navReceptionData = ", navReceptionData);
    setAgentsCollectionData(agentListAPI);
    storeReceptionData(navReceptionData);
    if (
      navReceptionData &&
      navReceptionData.reception_zone_editor &&
      navReceptionData.reception_zone_editor.reception_zone_id
    )
      getZoneDetailsAndUpdateCurrentKey(
        navReceptionData.reception_zone_editor.reception_zone_id
      );
  }, []);

  useEffect(() => {
    if (initiateValidations) {
      handleFormSubmit();
    }
  }, [initiateValidations]);

  const handleFormSubmit = () => {
    if (validator.current.allValid()) {
      onValid(true);
    } else {
      onValid(false);
      validator.current.showMessages();
      toast.error(dialogs.missingFields);
    }
  };

  useEffect(() => {
    if (!isEmpty(navReceptionData)) storeReceptionData(navReceptionData);
  }, [navReceptionData]);

  const storeReceptionData = (navReceptionDataHere) => {
    if (navReceptionDataHere) {
      setDataLocal(navReceptionDataHere);
    }
  };

  async function getZoneDetailsAndUpdateCurrentKey(e) {
    const res = await getZoneDetails({ zone_id: e });
    if (res && res.zone_id) {
      onChangeData({ reception_zone_id: e }, "reception_zone_editor");
      if (!isEmpty(res.zones_agents)) {
        let clonedAgents = cloneDeep(agentsCollectionData);
        let rightList = [];
        res.zones_agents.map((val) => {
          if (
            clonedAgents &&
            clonedAgents.some((agent) => agent.user_id === val.user_id)
          ) {
            let selectedAgents_list = [];
            let index = clonedAgents.findIndex(
              (agent) => agent.user_id === val.user_id
            );
            selectedAgents_list.push(clonedAgents[index]);
            selectedAgents_list = uniqWith(selectedAgents_list, isEqual);
            rightList = [...selectedAgents_list];
          }
        });
        setSelectedAgents(rightList);
      }

      const eventShows = await fetchEventShows({ event_id: res.event_id });
      let tempEventShows = [];
      eventShows &&
        eventShows.forEach((elem) => {
          tempEventShows.push({
            start_time: elem.start_time,
            end_time: elem.end_time,
            start_day: moment(elem.start_date).format("DD-MM-YYYY"),
            end_day: moment(elem.end_date).format("DD-MM-YYYY"),
          });
        });
      setchatBotDetails(tempEventShows);
      if (res.zones_assets_id) setAssetsArray(res.zones_assets_id);

      if (res && res.zones_social_media_handles_id) {
        let social_media_list = [];
        mediaLinks &&
          mediaLinks.forEach((obj) => {
            let objToPush = res.zones_social_media_handles_id.find((o) => {
              if (o.social_media_id === obj.social_media_id)
                return { ...o, ...obj };
            });
            if (!objToPush)
              social_media_list.push({ ...obj, is_active: false });
            social_media_list.push(objToPush);
          });
        const filteredData = [];

        social_media_list &&
          social_media_list.forEach((item) => {
            if (item && item.zonesSocialMediaHandles_social_media_id) {
              filteredData.push({
                ...item.zonesSocialMediaHandles_social_media_id,
                ...item,
              });
            } else if (item) filteredData.push(item);
          });
        setSocialMediaList(filteredData);
      }

      setZoneData(res);
    }
  }

  const onHandleChangeSelect = async (key, value, item) => {
    let prevValue = cloneDeep(dataLocal.navigation_main_settings);
    let prevSearch = prevValue;
    let tempArray = [];
    prevSearch.forEach((element) => {
      if (element.type_id === item.type_id) {
        element.selected = value;
        element[key] = value;
        element.navigation_main_setting_id = item.navigation_main_setting_id;
      }
      tempArray.push(element);
    });
    await onChangeData({ navigation_main_settings: tempArray });
  };

  const AssetInfoComponent = ({ assetData }) => {
    return (
      <div className={Tablet ? "mt-4 py-2" : "col-4 py-2 mx-2"}>
        <div className="row border-gray rounded h-100 py-2">
          <div className="row">
            <div className="col-3 text-end">Active</div>
            <div className="col-2">
              <Switch disabled defaultChecked={assetData?.is_active} />
            </div>
            <div className="col-4 text-end">Max D/L's</div>
            <div className="col-3">
              <Button disabled className="py-1 px-3 rounded">
                {assetData?.max_no_of_downloads}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-end">D/L</div>
            <div className="col-2">
              <Switch disabled defaultChecked={assetData?.is_downloadable} />
            </div>
            <div className="col-4 text-end">Whitelist</div>
            <div className="col-3">
              <Button disabled className="py-1 px-3 rounded">
                {assetData?.whitelisted_domains}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col-2"></div>
            <div className="col-4 text-end">BlackList</div>
            <div className="col-3">
              <Button disabled className="py-1 px-3 rounded">
                {assetData?.blacklisted_domains}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-end">Info</div>
            <div
              className="col-7"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <span className="fs-12">
                Filename: {assetData?.original_file_name}
              </span>
              <span className="fs-12">
                Date:
                {assetData && assetData.uploaded_date
                  ? moment(assetData.uploaded_date).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  : ""}
              </span>
              <span className="fs-12">
                Uploaded By: {assetData?.uploaded_by}
              </span>
              <span className="fs-12">View Count: {assetData?.view_count}</span>
              <span className="fs-12">
                D/L Count: {assetData?.total_downloads}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderNavigationSettingsHeader = (tabletClassName) => {
    return (
      <div className={Tablet ? tabletClassName : "col-4"}>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-5 text-end"></div>
          <div className="col-3 text-center">
            <b>Side Nav </b>
          </div>
          <div className="col-3 text-start">
            <b>Reception </b>
          </div>
        </div>
      </div>
    );
  };

  const renderMainSettingSwitches = (key, item) => {
    return (
      <Switch
        onChange={(value) => onHandleChangeSelect(key, value, item)}
        checked={item[key] ? item[key] : false}
      />
    );
  };

  const renderNavigationMainSettings = (indStart, indEnd, isTopEle) => {
    return (
      <>
        {dataLocal &&
          !isEmpty(dataLocal.navigation_main_settings) &&
          dataLocal.navigation_main_settings
            .slice(indStart, indEnd)
            .map((item) => {
              return (
                <div
                  className={isTopEle ? "row" : "row mt-4"}
                  key={"main_setting_" + item.type}
                >
                  <div className="col-5 text-end">{item.type}</div>
                  <div className="col-3">
                    {renderMainSettingSwitches(
                      "is_side_navigation_active",
                      item
                    )}
                  </div>
                  <div className="col-3">
                    {renderMainSettingSwitches("is_reception_active", item)}
                  </div>
                </div>
              );
            })}
      </>
    );
  };

  const renderInteractionSwitches = (key) => {
    return (
      <Switch
        disabled={true}
        checked={zoneData && zoneData[key] ? zoneData[key] : false}
      />
    );
  };

  const renderMediaRow = (ele, isFromDefault) => {
    if (ele)
      return (
        <div className="row mt-4 ">
          <div className="col-2 text-end ">{ele?.social_media}</div>
          <div className="col-1 text-center ">
            <Switch
              checked={isFromDefault ? false : ele.is_active ? true : false}
              disabled={true}
            />
          </div>
          <div className="col-4 text-start ">
            <Input
              placeholder="https://webaddress.com"
              className="w-100 fs-bold-14"
              disabled={true}
              value={ele.social_media_url ? ele.social_media_url : ""}
            />
          </div>
        </div>
      );
  };

  if (dataLocal) {
    return (
      <Fragment>
        <div className="container-fluid">
          <section>
            <div className="row">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">
                  Navigation Settings
                </h6>
              </div>
            </div>

            <div className="row mt-4">
              {renderNavigationSettingsHeader("col-7")}
              {renderNavigationSettingsHeader("d-none")}
              {renderNavigationSettingsHeader("d-none")}
            </div>

            <div className="row mt-4 border-bottom-gray pb-4">
              <div className="col-4 text-end">
                {renderNavigationMainSettings(0, 1, true)}
                {renderNavigationMainSettings(1, 5, false)}
              </div>
              <div className="col-4 text-end">
                {renderNavigationMainSettings(5, 6, true)}
                {renderNavigationMainSettings(6, 10, false)}
              </div>
              <div className="col-4 text-end">
                {renderNavigationMainSettings(10, 11, true)}
                {renderNavigationMainSettings(11, 15, false)}
                <div className="row mt-4"></div>
              </div>
            </div>
          </section>
          {dataLocal &&
          dataLocal.event_id &&
          window.location.pathname === "/editevent" ? (
            <>
              <div className="row mt-4">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20">
                    Reception Zone Selection
                  </h6>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-2 text-end mt-1">
                  Select Zone
                  <RedAsterick />
                </div>
                <div className="col-3 text-start">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="None"
                    defaultValue={
                      dataLocal &&
                      dataLocal.reception_zone_editor &&
                      dataLocal.reception_zone_editor.reception_zone_id
                        ? dataLocal.reception_zone_editor.reception_zone_id
                        : "None"
                    }
                    value={
                      dataLocal?.reception_zone_editor?.reception_zone_id
                        ? dataLocal.reception_zone_editor.reception_zone_id
                        : null
                    }
                    className="w-100 fs-bold-14"
                    onChange={(e, i) => getZoneDetailsAndUpdateCurrentKey(e)}
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {zones.map((item, i) => {
                      return (
                        <Option value={item.zone_id}>{item.zone_title}</Option>
                      );
                    })}
                  </Select>
                  {dataLocal?.reception_zone_editor?.reception_zone_id &&
                    validator.current.message(
                      "Zone",
                      dataLocal?.reception_zone_editor?.reception_zone_id,
                      "required",
                      { className: "text-danger py-1 mx-4" }
                    )}
                </div>
                <div className="col-3">
                  <Button
                    onClick={() => setIsZoneModalVisible(true)}
                    className="px-2 button-pink rounded"
                  >
                    + Create New Zone
                  </Button>
                </div>
              </div>
            </>
          ) : null}
          <section className="my-4 pb-5">
            <Modal
              visible={isZoneModalVisible}
              onCancel={() => setIsZoneModalVisible(false)}
              footer={null}
              width={1200}
            >
              <div className="p-2">
                <CreateNewZone
                  isFromEvent={true}
                  onCancelFromModal={() => setIsZoneModalVisible(false)}
                  onSuccessFromModal={async () => {
                    setIsZoneModalVisible(false);
                    const dropdownZones = await getZoneDropdowns(
                      navReceptionData && navReceptionData.event_id
                        ? navReceptionData.event_id
                        : ""
                    );
                    setZones(dropdownZones);
                  }}
                  event_id={dataLocal.event_id}
                />
              </div>
            </Modal>

            {dataLocal &&
            dataLocal.reception_zone_editor &&
            dataLocal.reception_zone_editor.reception_zone_id ? (
              <>
                <div className="row mt-4">
                  <div className="col-2 text-end">Active</div>
                  <div className="col-3">
                    <Switch
                      disabled={true}
                      checked={zoneData ? zoneData.is_active : false}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary mt-1">
                      Zone Hero Image
                    </label>
                  </div>
                  {dataLocal.event_id && (
                    <div className={Tablet ? "col-2" : "col-1"}>
                      <Button
                        className="rounded d-flex align-items-center  px-3"
                        onClick={() => setIsLogoPreview(true)}
                        icon={<EyeOutlined className="fs-20 " />}
                      >
                        <span>View</span>
                      </Button>
                      <Modal
                        title="Wall Graphics File "
                        visible={isLogoPreview}
                        // onOk={handleOk}
                        onCancel={() => setIsLogoPreview(false)}
                        footer={null}
                      >
                        {zoneData && zoneData.zone_hero_image_url ? (
                          <img
                            src={zoneData && zoneData.zone_hero_image_url}
                            alt="Zone Media upload file"
                            className="img-fluid uploaded-image-modal-preview"
                          />
                        ) : (
                          <h4 className="text-center fs-pink py-3">
                            File not uploaded
                          </h4>
                        )}
                      </Modal>
                    </div>
                  )}
                </div>

                <div className="row mt-4 view-all-common">
                  <div className="col-2 text-end">Zone title</div>
                  <div className="col-5 px-2">
                    <Input
                      disabled={true}
                      defaultValue={zoneData && zoneData?.zone_title}
                      value={zoneData?.zone_title}
                      placeholder="Enter a title here"
                      className="w-100 fs-bold-14 border-gray rounded"
                    />
                  </div>
                </div>

                <div className="row mt-4 pb-4 border-bottom-gray view-all-common">
                  <div className="col-2 text-end">Zone Description</div>
                  <div className="col-5 px-2">
                    <TextArea
                      rows={4}
                      disabled={true}
                      className="w-100 fs-bold-14"
                      placeholder="Enter a description here"
                      defaultValue={zoneData && zoneData?.zone_description}
                      value={zoneData?.zone_description}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </section>

          {dataLocal &&
          dataLocal.reception_zone_editor &&
          dataLocal.reception_zone_editor.reception_zone_id ? (
            <>
              <section>
                <div className="row mt-4">
                  <div className="col-12 mt-4">
                    <h6 className="font-pink fw-bolder fs-20">
                      Event Main Reception Interaction
                    </h6>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-2 text-end"> Text Chat</div>
                  <div className="col-4">
                    <Switch
                      disabled={true}
                      checked={
                        zoneData && zoneData.is_text_chat_enable
                          ? zoneData.is_text_chat_enable
                          : false
                      }
                    />
                  </div>
                </div>
                <div className="row mt-1">
                  {zoneData && zoneData.is_message_board_enable ? (
                    <>
                      <div className="col-1">Moderated?</div>
                      <div className="col-1">
                        {renderInteractionSwitches("is_moderated")}
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="row mt-4">
                  <div className="col-2 text-end">1-1 Video Calls</div>
                  <div className="col-1">
                    {renderInteractionSwitches(
                      "is_one_to_one_video_calls_enable"
                    )}
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-2 text-end mt-1">Survey</div>
                  <div className="col-3 text-start">
                    <Select
                      optionFilterProp="children"
                      disabled={true}
                      defaultValue={
                        zoneData && zoneData.survey_id
                          ? zoneData.survey_id
                          : "None"
                      }
                      value={zoneData?.survey_id ? zoneData.survey_id : null}
                      placeholder="None"
                      className="w-100 fs-bold-14"
                    >
                      {surveyList.map((item, i) => {
                        return (
                          <Option
                            value={item.survey_id}
                            key={"survey_" + item.survey_id}
                          >
                            {item.survey_title}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-2 text-end">On Booth Leads</div>
                  <div className="col-1">
                    {renderInteractionSwitches("is_on_booth_leads_enable")}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-2 text-end">Video Demo Room</div>
                  <div className="col-1">
                    {renderInteractionSwitches("is_video_demo_room_enable")}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-2 text-end">Text Bot</div>
                  <div className="col-1">
                    {renderInteractionSwitches("is_text_bot_enable")}
                  </div>
                </div>

                <div className="row mt-4 pb-4 border-bottom-gray">
                  <div className="col-2 text-end">
                    Zone Calendar
                    <RedAsterick />
                  </div>
                  <div className="col-3 text-decoration-underline fs-pink">
                    <Button
                      className={
                        "px-3  rounded btn-svg" + (Tablet ? " mt-2" : " mx-0")
                      }
                    >
                      <CalenderGrey />
                      <span className="mx-2">Open Calendar</span>
                    </Button>
                  </div>
                </div>
              </section>
              {zoneData && zoneData.is_text_bot_enable ? (
                <section>
                  <div className="row my-3">
                    <div className="col-12 mt-4">
                      <h6 className="font-pink fw-bolder fs-20">
                        Chat Bot Settings
                      </h6>
                    </div>
                  </div>
                  <b>
                    A Chat Bot will be enabled for this product zone for set
                    dates / times
                  </b>
                  <div className="row mt-4 border-bottom-gray pb-4">
                    <div className="col-9 event-date-scroll-div">
                      <Collapse
                        bordered={false}
                        defaultActiveKey={["2"]}
                        expandIcon={({ isActive }) =>
                          (isActive && (
                            <MinusOutlined
                              className="rounded minus-logo"
                              style={{}}
                            />
                          )) || <PlusOutlined className="rounded plus-logo" />
                        }
                        className="site-collapse-custom-collapse"
                      >
                        {chatBotDetails &&
                          chatBotDetails.map((elem, index) => {
                            let start = moment(
                              chatBotDetails[index].start_day,
                              "DD-MM-YYYY"
                            );
                            let end = moment(
                              chatBotDetails[index].end_day,
                              "DD-MM-YYYY"
                            );

                            return (
                              <Collapse.Panel
                                header={
                                  <span>
                                    <span className="fw-bold">
                                      {end.diff(start, "days") + " "} Day Show
                                    </span>
                                    <span
                                      className="font-pink mx-2
                      "
                                    >
                                      {elem.chat_bot_settings_id
                                        ? "[ " +
                                          moment(
                                            chatBotDetails[index].start_day
                                          ).format("DD-MM-YYYY") +
                                          " - " +
                                          moment(
                                            chatBotDetails[index].end_day
                                          ).format("DD-MM-YYYY") +
                                          " , " +
                                          chatBotDetails[index].start_time +
                                          "-" +
                                          chatBotDetails[index].end_time +
                                          " ]"
                                        : "[ " +
                                          chatBotDetails[index].start_day +
                                          " - " +
                                          chatBotDetails[index].end_day +
                                          " , " +
                                          chatBotDetails[index].start_time +
                                          "-" +
                                          chatBotDetails[index].end_time +
                                          " ]"}
                                    </span>
                                  </span>
                                }
                                key={index}
                                className="site-collapse-custom-panel"
                              >
                                <div className="row mt-4">
                                  <div className="col-3 text-end">
                                    <label className="text-secondary mt-1">
                                      Start & End Time
                                    </label>
                                  </div>
                                  <div className="col-6 d-flex m-0">
                                    <TimePicker
                                      suffixIcon={<CalenderGrey width="15" />}
                                      defaultValue={
                                        elem?.start_time
                                          ? moment(
                                              elem?.start_time,
                                              "HH:mm"
                                            ).utcOffset(elem?.start_time)
                                          : null
                                      }
                                      renderExtraFooter={() => "extra footer"}
                                      showTime
                                      className="w-100"
                                      disabled={true}
                                    />
                                    <TimePicker
                                      suffixIcon={<CalenderGrey width="15" />}
                                      renderExtraFooter={() => "extra footer"}
                                      defaultValue={
                                        elem?.end_time
                                          ? moment(
                                              elem?.end_time,
                                              "HH:mm"
                                            ).utcOffset(elem?.end_time)
                                          : null
                                      }
                                      showTime
                                      className="w-100"
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </Collapse.Panel>
                            );
                          })}
                      </Collapse>
                    </div>
                  </div>
                </section>
              ) : null}

              <section>
                <div className="row my-3">
                  <div className="col-12 mt-4">
                    <h6 className="font-pink fw-bolder fs-20">
                      Externals & Social Links
                    </h6>
                  </div>
                </div>

                <div className="social-media-div border-bottom-gray pb-4 mb-4">
                  {socialMediaList && !isEmpty(socialMediaList)
                    ? socialMediaList.map((ele, idx) =>
                        renderMediaRow(ele, false)
                      )
                    : mediaLinks && !isEmpty(mediaLinks)
                    ? mediaLinks.map((ele, idx) => renderMediaRow(ele, true))
                    : null}
                </div>
              </section>
              <section>
                <div className="row mt-4">
                  <div className="col-12 mt-4">
                    <h6 className="font-pink fw-bolder fs-20">
                      Agents assigned to this zone
                    </h6>
                  </div>
                </div>
                <div className="row mt-4 event-editor-dropdown-div">
                  <div className="col-2 text-end mt-4">
                    <span className="text-secondary mt-4">
                      Tagged Agents
                      <RedAsterick />
                    </span>
                  </div>
                  <div
                    id="area1"
                    className={Tablet ? "col-5" : "col-4"}
                    style={{ position: "relative" }}
                  >
                    <label className="py-2 mx-2"></label>
                    <Select
                      getPopupContainer={() => document.getElementById("area1")}
                      showSearch
                      dropdownClassName="agent-dropdown"
                      style={{ width: 300 }}
                      placeholder="No agents assigned to this zone"
                      optionFilterProp="children"
                      value="Agents"
                      open={true}
                      listItemHeight={4}
                      listHeight={130}
                      className="border border-gray fs-bold-14"
                    >
                      {selectedAgents?.map((data) => {
                        if (data && data.user_id)
                          return (
                            <Option
                              value={data.user_id}
                              key={"agents_" + data.user_id}
                              disabled={true}
                            >
                              {data.first_name} {data.last_name}
                            </Option>
                          );
                      })}
                      {selectedAgents.length === 0 && (
                        <Option disabled>
                          No agents are assigned to this zone
                        </Option>
                      )}
                    </Select>
                  </div>
                </div>

                <div className="row mt-4 border-bottom-gray pb-4">
                  <div className="col-2"></div>
                  <div className="col-4">
                    <Button
                      className={
                        "px-3  rounded btn-svg" + (Tablet ? " mt-2" : " mx-2")
                      }
                      onClick={() => {
                        if (zoneData.brand_id)
                          window.open(
                            `/brandCalendar/${dataLocal.event_id}/${zoneData.brand_id}`,
                            "_blank"
                          );
                        else
                          toast.error(
                            "Brand Selection is Necessary for this action"
                          );
                      }}
                    >
                      <CalenderGrey />
                      <span className="mx-2">Staffing Calendar</span>
                    </Button>
                  </div>
                </div>
              </section>
              <section>
                <div className="row mt-4">
                  <div className="col-12 mt-4">
                    <h6 className="font-pink fw-bolder fs-20">Assets</h6>
                  </div>
                </div>

                <div className="row mt-4">
                  {assetsArray && !isEmpty(assetsArray) ? (
                    <div className="col-4 assets-scroll-div">
                      {assetsArray &&
                        assetsArray.map((elem) => (
                          <>
                            <div
                              className={Tablet ? "mt-4" : " py-2"}
                              onClick={() => setAssetDataVisible(elem)}
                            >
                              <div className="row border-gray rounded py-1">
                                <div className="col-1">
                                  <Cube />
                                </div>
                                <div className="col-11">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <span className="fs-18 mx-2">
                                        <b>{elem.asset_name}</b>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <span className="fs-16 mx-2">
                                      {elem.asset_description}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  ) : (
                    <span>Assets are not added to this zone</span>
                  )}

                  {assetDataVisible || !assetsArray ? (
                    <AssetInfoComponent assetData={assetDataVisible} />
                  ) : null}
                </div>
              </section>
            </>
          ) : null}
        </div>
      </Fragment>
    );
  } else return null;
};

export default NavigationAndReception;
