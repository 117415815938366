import React, { Fragment, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { Button, Card, Input, Avatar } from "antd";
import SimpleReactValidator from "simple-react-validator";
import { logo } from "../../components/Images/Images";
import { useMediaQuery } from "react-responsive";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/slice/login";
import { toast } from "react-toastify";
import { ApiCall } from "../../utils/api";
import { auto } from "@popperjs/core";
import CommonLoader from "../../components/Widgets/CommonLoader";
import Vista from "../Vista/src/components/vista";
import VistaApp from "../Vista/src/VistaApp";



const LoginBox = (props) => {


    const dispatch = useDispatch();
    var CryptoJS = require("crypto-js");
    const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
    const [email, setEmail] = useState("");
    const [showError, setshowError] = useState(false);
    const [isError, setisError] = useState("");
    const [password, setPassword] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [userType, setUserType] = useState("");
    const validator = useRef(new SimpleReactValidator());
    const { loading } = useSelector((state) => state.login);

    const [flag, setFlag] = useState(false);

    const btnRef = useRef(null);

    useEffect(() => {

    }, [])

    const handleEmailInputChange = (event) => {
        setEmail(event.target.value);
    };
    const handlePasswordInputChange = (event) => {
        setPassword(event.target.value);
    };

    const submitForm = async (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            let loginData = { email, password };
            // loginData.event_id = 21;
            if (localStorage.getItem("event_id")) {
                // loginData.event_id = localStorage.getItem("event_id");
                loginData.event_id = localStorage.getItem("event_id");
            }
            const loginResp = await dispatch(login(loginData));
            console.log("Login Resp", loginResp);

            if (loginResp?.payload?.code === 200) {
                setFlag(true);
                const sideMenuData = loginResp.payload.data.menu_acces_details;
                const userType = loginResp.payload.data.userType;
                const userName = loginResp.payload.data.user_name;

                const color = loginResp.payload.data.color;
                const eventColor = loginResp.payload.data.eventColor;
                setUserType(loginResp.payload.data.userType);

                var ciphertext = CryptoJS.AES.encrypt(
                    JSON.stringify(sideMenuData),
                    "side_menu"
                ).toString();
                var ciphertext_user_type = CryptoJS.AES.encrypt(
                    JSON.stringify(userType),
                    "user_type"
                ).toString();
                var ciphertext_user_name = CryptoJS.AES.encrypt(
                    JSON.stringify(userName),
                    "user_name"
                ).toString();

                localStorage.setItem("side_menu", ciphertext);
                localStorage.setItem("user_type", ciphertext_user_type);
                localStorage.setItem("user_name", ciphertext_user_name);
                localStorage.setItem("Authorization", loginResp.payload.data.token);
                localStorage.setItem("event_id", loginResp.payload.data.event_id);
                localStorage.setItem("chat_uid", loginResp.payload.data.chat_uid);
                localStorage.setItem("customer_id", loginResp.payload.data.customer_id);

                let customThemeColorAndRoute = {
                    color: "",
                    route: "",
                };

                if (userType && (userType === "AGENT" || userType === "BRAND ADMIN")) {
                    customThemeColorAndRoute = {
                        color: color.brand_color,
                        route: "/mycalendar",
                    };
                } else if (userType === "DELEGATE") {
                    customThemeColorAndRoute = {
                        color: eventColor?.event_color_theme,
                        route: "/expomap",
                    };
                } else if (userType === "SPEAKER") {
                    customThemeColorAndRoute = {
                        color: eventColor?.event_color_theme
                            ? eventColor?.event_color_theme
                            : "#ef0855",
                        route: "/mycalendar",
                    };
                } else {
                    customThemeColorAndRoute = {
                        color: "#ef0855",
                        route: "/eventmanagement",
                    };
                }

                window.less
                    .modifyVars({
                        "@primary-color": customThemeColorAndRoute.color,
                    })
                    .then(() => {
                        // console.log("Theme updated successfully 1234");
                    });
                localStorage.setItem(
                    "event_color_theme",
                    customThemeColorAndRoute.color
                );

                toast.success(loginResp.payload.message);
                console.log(customThemeColorAndRoute.route);

                console.log("props", props);
                props.loginProps.history.push(customThemeColorAndRoute.route)
                setshowError(false);
            }
            if (loginResp?.payload?.code === 403 || loginResp?.payload?.code === 500) {
                localStorage.removeItem("Authorization");
                toast.error(loginResp.payload.message);
                props.loginProps.history.push("/login");
                setshowError(false);
            }

        } else {
            validator.current.showMessages();
            setForceUpdate(!forceUpdate);
        }
    };


    return (
        <>
            {
                props.isRenderLogin ? (
                    <Card
                        bordered={true}
                        style={{ position: "absolute", top: "150px", left: "0", right: "0", marginLeft: "auto", marginRight: "auto" }}
                        className={
                            Tablet
                                ? "m-auto w-50 mt-4 rounded login_temp"
                                : "m-auto w-25 mt-4 rounded login_temp"
                        }
                    >
                        <h5 className="text-center mt-3 fs-26 fs-pink"> Sign in </h5>

                        <form>
                            <div className="form-group text-start mt-4">
                                <label className="fs-16">Email</label>
                                <Input
                                    name="email"
                                    className="form-control py-2"
                                    key={1}
                                    value={email}
                                    placeholder="Enter email address"
                                    // onKeyDown={e => {
                                    //   if (e.key === "Enter") {
                                    //     submitForm(e);
                                    //   }
                                    // }}
                                    onChange={handleEmailInputChange}
                                    onBlur={() => validator.current.showMessageFor("email")}
                                    autoComplete="off"
                                />
                                {validator.current.message(
                                    "email",
                                    email,
                                    "required|email",
                                    {
                                        className: "text-danger"
                                    }
                                )}
                            </div>

                            <div className="form-group text-start mt-4 ">
                                <span className="d-flex justify-content-between">
                                    <label className="fs-16">Password</label>
                                    <a className="fs-pink" href="/forgotpass">
                                        Forgot?
                                    </a>
                                </span>
                                <span>
                                    <Input.Password
                                        name="password"
                                        type="password"
                                        className="login_passInput form-control py-2"
                                        placeholder="Enter password"
                                        // onKeyDown={e => {
                                        //   if (e.key === "Enter") {
                                        //     submitForm(e);
                                        //   }
                                        // }}
                                        // iconRender={visible =>
                                        //     visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                        // }
                                        value={password}
                                        onChange={handlePasswordInputChange}
                                        onBlur={() =>
                                            validator.current.showMessageFor("password")
                                        }
                                        autoComplete="off"
                                    />
                                    {validator.current.message(
                                        "password",
                                        password,
                                        "required|password|min : 5",
                                        { className: "text-danger" }
                                    )}
                                </span>
                            </div>

                            <Button
                                type="primary"
                                className=" my-2 w-100 mt-3 bg-pink"
                                loading={loading}
                                ref={btnRef}
                                onClick={submitForm}
                                size="large"
                            >
                                Sign In
                            </Button>
                        </form>
                        <div className="mt-2 text-danger">
                            {showError && (
                                <span className="text-center fs-14 mt-5">{isError}</span>
                            )}
                        </div>
                        <p className="fs-14">
                            Not Registered?
                            <Link to="/registration">
                                <span className="ms-2 fs-pink">
                                    <u>Register Here</u>
                                </span>
                            </Link>
                        </p>
                    </Card >
                ) : null}

        </>
    )
}

export default LoginBox;