import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Select,
  Empty,
  Layout,
  Divider,
  Input,
  Result,
  DatePicker,
  Popover,
  Tooltip,
  Row,
  Col,
  Modal,
} from "antd";
import {
  LeftOutlined,
  RightOutlined,
  SmileOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { useMediaQuery } from "react-responsive";
import {
  fetchChatbotHistory,
  getChatbotJson,
  getEvents,
  saveChatbotHistory,
  sendMessageAPI,
  videoRequestAPI,
} from "../../utils/commonApis";
import { ApiCall } from "../../utils/api";
import { cloneDeep, isEmpty, isEqual, uniqWith } from "lodash";
import "./eventCalender.css";
import {
  checkIfChatbotTimeSlot,
  getAllSessions,
  getCurrentTimeAndPercentage,
  getHalfAnHourIntervals,
  getSponserTimingsAndHeader,
  ifTimeSlotIncluded,
  isFirstTitleCell,
  isPastDate,
  searchSessionsFromData,
} from "./eventCalender.utils";
import moment from "moment";
import ExpoHallOpen from "./Popups/ExpoHallOpen";
import Sponser from "./Popups/Sponser";
import AudiSession from "./Popups/AudiSession";
import NetworkingSession from "./Popups/NetworkingSession";
import TickerConfiguration from "./Popups/TickerConfiguration";
import { toast } from "react-toastify";
import { TIME_FORMAT } from "../../components/constsnts/common.constants";
import {
  updateExpoHallTitle,
  updateAudiTitle,
  updateNetworkTitle,
} from "../../redux/slice/eventCalender";
import { useDispatch } from "react-redux";
import Text from "antd/lib/typography/Text";
import {
  getDatesBetweenDates,
  getFileNameFromPath,
  getNearestFutureDate,
} from "../../utils/commonFunctions";
import NetworkModalView from "./Popups/NetworkView";
import AudiView from "./Popups/AudiView";
import { Booth } from "../../components/svgJS/Booth";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { Download } from "../../components/svgJS/Download";
import { User } from "../../components/svgJS/User";
import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "../../components/constsnts/common.constants";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import CustomChatbot from "../../components/Widgets/CustomChatbot";

const EventCalendar = () => {
  const [disableEvent, setDisableEvent] = useState(false);
  const messageValidator = useRef(new SimpleReactValidator());
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Option } = Select;
  const { Header, Sider } = Layout;
  var CryptoJS = require("crypto-js");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [eventCalendarData, setEventCalendarData] = useState({});
  const [eventList, setEventList] = useState([]);
  const [currentEventID, setCurrentEventID] = useState(); //105
  const [currentEventData, setCurrentEventData] = useState({});
  const [userType, setUserType] = useState("");
  const [loader, setLoader] = useState(false);
  const today = moment().format("MMMM DD, yyyy");
  const [allSessions, setAllSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [brandsOfEvent, setBrandsOfEvent] = useState([]);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [dateToDisplay, setDateToDisplay] = useState(today);
  const [currentDateForCalender, setCurrentDateForCalender] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [dateForDatePicker, setDateForDatePicker] = useState(moment());
  const [isNoEventDataFound, setIsNoEventDataFound] = useState(false);
  const [isNoDataForTheDay, setIsNoDataForTheDay] = useState(false);
  const [event_dates, set_event_dates] = useState([]);
  const [current_date_to_navigate, set_current_date_to_navigate] = useState({
    currentIndex: 0,
    dateString: moment().format("YYYY-MM-DD"),
    length: 0,
  });
  const [event_dates_to_navigate, set_event_dates_to_navigate] = useState([
    current_date_to_navigate,
  ]);

  const defaultHeaderData = { boxData: {}, currentIndex: 0, count: 0 };
  const defaultSponserData = { ...defaultHeaderData, boxData: [] };
  const [default_sponsor_details, set_default_sponsor_details] = useState({});
  const [expoAvlTimings, setExpoAvlTimings] = useState({ sponsers: [] });
  const [mainAudiAvlTimings, setMainAudiAvlTimings] = useState({
    sessions: [],
    sponsers: [],
  });
  const [satAudiAvlTimings, setSatAudiAvlTimings] = useState({
    sessions: [],
    sponsers: [],
  });
  const [netLoungeAvlTimings, setNetLoungeAvlTimings] = useState({
    sessions: [],
    sponsers: [],
  });
  const [tickerAvlTimings, setTickerAvlTimings] = useState({ sponsers: [] });
  const [socialAvlTimings, setSocialAvlTimings] = useState({ sponsers: [] });
  const [concrsAvlTimings, setConcrsAvlTimings] = useState({ sponsers: [] });

  //expo hall
  const [expoHallData, setExpoHallData] = useState(defaultHeaderData);
  const [expoHallSponsers, setExpoHallSponsers] = useState(defaultSponserData);
  const [expoSponserHeader, setExpoSponserHeader] = useState({});
  const [expoHallCurrentIndex, setExpoHallCurrentIndex] = useState(0);
  const [expoHallOpenModal, setExpoHallOpenModal] = useState({
    isOpen: false,
    expoHallData: null,
  });
  const [sponsorModal, setSponsorModal] = useState({
    isOpen: false,
    sponserData: null,
    title: "",
    existingTimings: [],
  });
  //main audi
  const [mainAudiData, setMainAudiData] = useState(defaultHeaderData);
  const [mainAudiSessions, setMainAudiSessions] = useState(defaultSponserData);
  const [mainAudiSponsers, setMainAudiSponsers] = useState(defaultSponserData);
  const [mainAudiSponserHeader, setMainAudiSponserHeader] = useState({});
  // const [currentMainAudiSession, setCurrentMainAudiSession] = useState({});

  //sat audi
  const [satAudiData, setSatAudiData] = useState(defaultHeaderData);
  const [satAudiSessions, setSatAudiSessions] = useState(defaultSponserData);
  const [satAudiSponsers, setSatAudiSponsers] = useState(defaultSponserData);
  const [satAudiSponserHeader, setSatAudiSponserHeader] = useState({});
  const [satAudiCurrentIndex, setSatAudiCurrentIndex] = useState(0);
  //audi session modal
  const [audiSessionModal, setAudiSessionModal] = useState({
    isOpen: false,
    audiSessionData: null,
    title: "",
    existingTimings: [],
  });

  //audi session for view
  const [audiSessionView, setAudiSessionView] = useState({
    isOpen: false,
    audiSessionData: null,
    title: "",
    existingTimings: [],
  });

  //net lounges
  const [netLoungeData, setNetLoungeData] = useState(defaultHeaderData);
  const [netLoungeSessions, setNetLoungeSessions] =
    useState(defaultSponserData);
  const [netLoungeSponsers, setNetLoungeSponsers] =
    useState(defaultSponserData);
  const [netSponserHeader, setNetSponserHeader] = useState({});
  const [netLoungeCurrentIndex, setNetLoungeCurrentIndex] = useState(0);
  const [netwrkSessionModal, setNetwrkSessionModal] = useState({
    isOpen: false,
    networkSessionData: null,
    title: "",
    existingTimings: [],
  });

  //network modal for view
  const [networkModalForView, setNetworkModalForView] = useState({
    isOpen: false,
    networkSessionData: null,
    title: "",
    existingTimings: [],
  });

  const [tickerData, setTickerData] = useState(defaultSponserData);
  const [socialData, setSocialData] = useState(defaultSponserData);
  const [concourseData, setConcourseData] = useState(defaultSponserData);

  const [tickerConfigModal, setTickerConfigModal] = useState({
    isOpen: false,
    tickerData: null,
    existingTimings: [],
  });
  const [eventThemeColor, setEventThemeColor] = useState("#EF0855");
  const [liveTime, setLiveTime] = useState(getCurrentTimeAndPercentage());
  const halfHours = getHalfAnHourIntervals();
  const [popOverData, setPopOverData] = useState({
    titlePopVisible: false,
    parentTitle: "",
    updateTitle: "",
  });
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [message_text, set_message_text] = useState("");
  const [event_settings, set_event_settings] = useState({
    is_social_wall_active: true,
    is_concourse_active: true,
    is_ticker_tape_active: true,
    is_expo_hall_active: true,
    is_main_hall_active: true,
    is_sat_auditorium_active: true,
    is_networking_active: true,
  });
  const [isChatBot, setIsChatBot] = useState(false);
  const [chatbot_json, set_chatbot_json] = useState([]);
  const [chatbot_history, set_chatbot_history] = useState([
    {
      key: "left_message_0",
      message: "Hi! How can we help you?",
      position: "left",
    },
  ]);

  useEffect(async () => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);
    let chat_uid = localStorage.getItem("chat_uid");
    if (chat_uid) {
      CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
        (response) => {
          if (response) {
            console.log("CometChat Login Successful:", response);
            setCometChatUser({
              isValidUser: true,
              data: response,
              uid: response.getUid(),
            });
          }
        },
        (error) => {
          console.log("CometChat Login failed with exception:", { error });
        }
      );
    }
    const eventsData = await getEvents();
    console.log("eventsData = ", eventsData);

    let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    if (
      user_type === "DELEGATE" ||
      user_type === "SPEAKER" ||
      user_type === "BRAND ADMIN"
    ) {
      const localData_event_id = parseInt(localStorage.getItem("event_id"));
      const events = eventsData.filter((item) => {
        return item.event_id === localData_event_id;
      });

      setEventList(events);
      let id = events[0].event_id;
      setCurrentEventID(id);
      setDisableEvent(true);
    } else {
      setEventList(eventsData);
    }
  }, []);

  useEffect(async () => {
    console.log("change event id = ", currentEventID);
    if (currentEventID) {
      setIsNoEventDataFound(false);
      setIsNoDataForTheDay(false);
      setExpoHallCurrentIndex(0);
      setSatAudiCurrentIndex(0);
      setNetLoungeCurrentIndex(0);

      const chatHistory = await fetchChatbotHistory({
        event_id: currentEventID,
      });
      if (chatHistory && !isEmpty(chatHistory))
        set_chatbot_history(chatHistory);

      const responseEvent = await ApiCall(
        "GET",
        "",
        `/admin/events/details/get?event_id=${currentEventID ?? ""}`,
        ""
      );

      if (responseEvent?.data?.data)
        setCurrentEventData(responseEvent?.data?.data);

      if (responseEvent?.data?.data?.editor_details) {
        console.log(
          "responseEvent?.data?.data?.editor_details = ",
          responseEvent?.data?.data?.editor_details
        );

        let themeColor =
          responseEvent.data.data.editor_details.event_color_theme ?? "#ef0855";
        setEventThemeColor(themeColor);

        const show_details =
          responseEvent.data.data.editor_details.event_shows_details;
        console.log("show_details = ", show_details);
        if (show_details && !isEmpty(show_details)) {
          let event_dates_here = [];
          show_details &&
            show_details.forEach((ele) => {
              if (ele.start_date) {
                if (!event_dates_here.includes(ele.start_date)) {
                  if (ele.start_date === ele.end_date)
                    event_dates_here.push(ele.start_date);
                  else {
                    let datesOfShow = getDatesBetweenDates(
                      ele.start_date,
                      ele.end_date
                    );
                    event_dates_here = [...event_dates_here, ...datesOfShow];
                  }
                }
              }
            });
          console.log("event_dates_here = ", event_dates_here);
          set_event_dates(event_dates_here);
          let nearestDate = getNearestFutureDate(event_dates_here);
          console.log("nearestDate = ", nearestDate);
          let datesToPush = [];
          event_dates_here.forEach((ele, index) => {
            if (ele === nearestDate)
              set_current_date_to_navigate({
                currentIndex: index,
                dateString: nearestDate,
                length: event_dates_here.length,
              });

            datesToPush.push({
              currentIndex: index,
              dateString: ele,
              length: event_dates_here.length,
            });
          });
          set_event_dates_to_navigate(datesToPush);
          console.log("nearestDate = ", nearestDate);
          if (!nearestDate || isEmpty(nearestDate)) {
            nearestDate = moment().format("YYYY-MM-DD");
          }
          setCurrentDateForCalender(nearestDate);
          setDateToDisplay(moment(nearestDate).format("MMMM DD, yyyy"));
          setDateForDatePicker(moment(nearestDate));
          callFetchEventCalenderData(true, nearestDate);
        } else dataNotFound();
      } else dataNotFound();
    }
  }, [currentEventID]);

  function dataNotFound() {
    toast.error("Event data not found");
    setIsNoEventDataFound(true);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentEventID) {
        if (
          expoHallOpenModal.isOpen ||
          audiSessionModal.isOpen ||
          netwrkSessionModal.isOpen ||
          tickerConfigModal.isOpen ||
          sponsorModal.isOpen ||
          audiSessionView.isOpen ||
          networkModalForView.isOpen
        ) {
          //do nothing
        } else callFetchEventCalenderData(false, currentDateForCalender);
      }
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [
    dateForDatePicker,
    expoHallOpenModal,
    audiSessionModal,
    netwrkSessionModal,
    tickerConfigModal,
    sponsorModal,
  ]);

  const callFetchEventCalenderData = (
    displayLoader = true,
    dateSelected = ""
  ) => {
    if (displayLoader) setLoader(true);
    setTimeout(() => {
      setLiveTime(getCurrentTimeAndPercentage());
      if (dateSelected) fetchEventCalenderData(dateSelected);
      else fetchEventCalenderData(currentDateForCalender);
    }, 1000);
  };

  const updateExpoHallStates = (data, currentIndex) => {
    setExpoHallData({
      boxData: data.expo_hall_configurations?.[currentIndex],
      currentIndex,
      count: data.expo_hall_configurations
        ? data.expo_hall_configurations.length
        : 0,
    });

    setExpoHallSponsers({
      boxData:
        data.expo_hall_configurations?.[currentIndex]?.expo_hall_brand_sponsor,
      currentIndex,
      count:
        data.expo_hall_configurations?.[currentIndex]?.expo_hall_brand_sponsor
          .length,
    });

    if (data.expo_hall_configurations) {
      const ele = data.expo_hall_configurations[currentIndex];
      if (ele && ele.expo_hall_brand_sponsor) {
        const res = getSponserTimingsAndHeader(
          ele,
          "expo_hall_brand_sponsor",
          liveTime
        );
        setExpoAvlTimings({ sponsers: res.timings });
        setExpoSponserHeader(res.newHeader);
      }
    }
  };

  const updateSatAudiStates = (data, currentIndex) => {
    setSatAudiData({
      boxData: data.satellite_auditorium_configurations?.[currentIndex],
      currentIndex,
      count: data.satellite_auditorium_configurations
        ? data.satellite_auditorium_configurations.length
        : 0,
    });

    setSatAudiSessions({
      boxData:
        data.satellite_auditorium_configurations?.[currentIndex]
          ?.auditorium_sessions,
      currentIndex,
      count:
        data.satellite_auditorium_configurations?.[currentIndex]
          ?.auditorium_sessions.length,
    });
    setSatAudiSponsers({
      boxData:
        data.satellite_auditorium_configurations?.[currentIndex]
          ?.satellite_auditorium_brand_sponsor,
      currentIndex,
      count:
        data.satellite_auditorium_configurations?.[currentIndex]
          ?.satellite_auditorium_brand_sponsor.length,
    });
    if (data.satellite_auditorium_configurations) {
      const ele = data.satellite_auditorium_configurations[currentIndex];
      let newHeader = {};
      let sessionsTimings = [];
      let sponsersTimings = [];
      if (ele && ele.satellite_auditorium_brand_sponsor) {
        const res = getSponserTimingsAndHeader(
          ele,
          "satellite_auditorium_brand_sponsor",
          liveTime
        );
        sponsersTimings = res.timings;
        newHeader = res.newHeader;
      }

      if (ele && ele.auditorium_sessions) {
        ele.auditorium_sessions.forEach((item) => {
          if (item && item.start_time && item.end_time)
            sessionsTimings.push({
              start_time: item.start_time,
              end_time: item.end_time,
              data: item,
            });
        });
      }
      sessionsTimings = uniqWith(sessionsTimings, isEqual);
      if (newHeader) setSatAudiSponserHeader(newHeader);
      setSatAudiAvlTimings({
        sessions: sessionsTimings,
        sponsers: sponsersTimings,
      });
    }
  };

  const updateNetLounges = (data, currentIndex) => {
    setNetLoungeData({
      boxData: data.networking_lounge_configurations?.[currentIndex],
      currentIndex,
      count: data.networking_lounge_configurations
        ? data.networking_lounge_configurations.length
        : 0,
    });
    setNetLoungeSessions({
      boxData:
        data.networking_lounge_configurations?.[currentIndex]
          ?.networking_lounge_sessions,
      currentIndex,
      count:
        data.networking_lounge_configurations?.[currentIndex]
          ?.networking_lounge_sessions.length,
    });
    setNetLoungeSponsers({
      boxData:
        data.networking_lounge_configurations?.[currentIndex]
          ?.networking_lounge_brand_sponsor,
      currentIndex,
      count:
        data.networking_lounge_configurations?.[currentIndex]
          ?.networking_lounge_brand_sponsor.length,
    });
    if (data.networking_lounge_configurations) {
      let newHeader = {};
      let sessionsTimings = [];
      let sponsersTimings = [];
      const ele = data.networking_lounge_configurations[currentIndex];
      if (ele && ele.networking_lounge_brand_sponsor) {
        const res = getSponserTimingsAndHeader(
          ele,
          "networking_lounge_brand_sponsor",
          liveTime
        );
        sponsersTimings = res.timings;
        newHeader = res.newHeader;
      }
      if (ele && ele.networking_lounge_sessions) {
        ele.networking_lounge_sessions.forEach((item) => {
          sessionsTimings.push({
            start_time: item.start_time,
            end_time: item.end_time,
            data: item,
          });
        });
      }
      sessionsTimings = uniqWith(sessionsTimings, isEqual);

      if (newHeader) setNetSponserHeader(newHeader);
      setNetLoungeAvlTimings({
        sessions: sessionsTimings,
        sponsers: sponsersTimings,
      });
    }
  };

  const fetchEventCalenderData = async (dateSelected = "") => {
    setIsNoDataForTheDay(false);
    console.log("dateSelected = ", dateSelected);
    let apiParams = {
      event_id: currentEventID,
      date:
        dateSelected && !isEmpty(dateSelected)
          ? dateSelected
          : currentDateForCalender, //"2022-03-27",  //2022-04-14 - 169 //aditya
    };
    ApiCall("get", null, "/admin/eventcalendar/details/get", apiParams)
      .then((response) => {
        console.log("response eventcalendar = ", response);
        setLoader(false);
        if (response?.data?.message === "No Data Found")
          setIsNoDataForTheDay(true);
        else if (response && response.message === "Network Error")
          toast.error("Please check the internet connection");
        else if (response?.data?.data?.details) {
          const result = response?.data?.data?.details;
          console.log("result in fetchEventCalenderData = ", result);
          if (result) {
            if (result.brand_details) setBrandsOfEvent(result.brand_details);
            let allSessionsToSearch = getAllSessions(result);
            setAllSessions(allSessionsToSearch);

            if (result.default_sponsor_details)
              set_default_sponsor_details(result.default_sponsor_details);

            updateExpoHallStates(result, expoHallCurrentIndex);
            setMainAudiData({
              boxData: result.main_auditorium_configurations,
              currentIndex: 0,
              count: 1,
            });
            setMainAudiSessions({
              boxData: result.main_auditorium_configurations
                ? result.main_auditorium_configurations.auditorium_sessions
                : [],
              currentIndex: 0,
              count:
                result.main_auditorium_configurations &&
                result.main_auditorium_configurations.auditorium_sessions
                  ? result.main_auditorium_configurations.auditorium_sessions
                      .length
                  : 0,
            });
            if (result.main_auditorium_configurations) {
              let newHeader = {};
              let sponsersTimings = [];
              let sessionsTimings = [];
              if (
                result.main_auditorium_configurations
                  .main_auditorium_hall_brand_sponsor
              ) {
                const res = getSponserTimingsAndHeader(
                  result.main_auditorium_configurations,
                  "main_auditorium_hall_brand_sponsor",
                  liveTime
                );
                sponsersTimings = res.timings;
                newHeader = res.newHeader;
              }

              if (result.main_auditorium_configurations.auditorium_sessions) {
                result.main_auditorium_configurations.auditorium_sessions.forEach(
                  (item) => {
                    if (item && item.start_time && item.end_time)
                      sessionsTimings.push({
                        start_time: item.start_time,
                        end_time: item.end_time,
                        data: item,
                      });
                  }
                );
              }
              sessionsTimings = uniqWith(sessionsTimings, isEqual);
              if (newHeader) setMainAudiSponserHeader(newHeader);
              setMainAudiAvlTimings({
                sessions: sessionsTimings,
                sponsers: sponsersTimings,
              });
            }
            setMainAudiSponsers({
              boxData: result.main_auditorium_configurations
                ? result.main_auditorium_configurations
                    .main_auditorium_hall_brand_sponsor
                : [],
              currentIndex: 0,
              count:
                result.main_auditorium_configurations &&
                result.main_auditorium_configurations
                  .main_auditorium_hall_brand_sponsor
                  ? result.main_auditorium_configurations
                      .main_auditorium_hall_brand_sponsor.length
                  : 0,
            });
            updateSatAudiStates(result, satAudiCurrentIndex);
            updateNetLounges(result, netLoungeCurrentIndex);
            setTickerData({
              boxData: result.ticker_details,
              currentIndex: 0,
              count: 1,
            });
            if (result.ticker_details) {
              const key = "ticker_details";
              const res = getSponserTimingsAndHeader(result, key, null);
              setTickerAvlTimings({ sponsers: res.timings });
            }
            setSocialData({
              boxData: result.social_wall_sponsorships,
              currentIndex: 0,
              count: 1,
            });
            if (result.social_wall_sponsorships) {
              const key = "social_wall_sponsorships";
              const res = getSponserTimingsAndHeader(result, key, null);
              setSocialAvlTimings({ sponsers: res.timings });
            }
            setConcourseData({
              boxData: result.concourse_sponsorship,
              currentIndex: 0,
              count: 1,
            });
            if (result.concourse_sponsorship) {
              const key = "concourse_sponsorship";
              const res = getSponserTimingsAndHeader(result, key, null);
              setConcrsAvlTimings({ sponsers: res.timings });
            }

            if (result.event_settings)
              set_event_settings(result.event_settings);
            setEventCalendarData(result);
            const divElement = document.getElementById("timeline_div");
            if (divElement) {
              // console.log(
              //   "divElement.getAttributeNames() = ",
              //   divElement.getAttributeNames()
              // );
              // divElement.scrollTo({ top: 570 });
              // aditya
              // divElement.setAttribute
              // divElement.scrollIntoView({ behavior: "smooth" });
            }
          }
        }
      })
      .catch((err) => {
        console.error("err fetchEventCalenderData", err);
        setLoader(false);
      });
  };

  const searchSessions = (event) => {
    setFilteredSessions([]);
    let searchTerm = event.target.value.toLowerCase().trim();
    console.log("searchTerm = ", searchTerm);
    let allSearchedData = [];
    if (searchTerm !== "" || !isEmpty(searchTerm))
      allSearchedData = searchSessionsFromData(allSessions, searchTerm);
    if (searchTerm === "" || isEmpty(searchTerm)) setFilteredSessions([]);
    setFilteredSessions(allSearchedData);
  };

  function callExpoUpdate(currentIndex) {
    setLoader(true);
    setTimeout(() => {
      updateExpoHallStates(eventCalendarData, currentIndex);
      setLoader(false);
    }, 1000);
  }

  function callSatAudiUpdate(currentIndex) {
    setLoader(true);
    setTimeout(() => {
      updateSatAudiStates(eventCalendarData, currentIndex);
      setLoader(false);
    }, 1000);
  }

  function callNetLoungesUpdate(currentIndex) {
    setLoader(true);
    setTimeout(() => {
      updateNetLounges(eventCalendarData, currentIndex);
      setLoader(false);
    }, 1000);
  }

  const onShuffleRightLeft = (props, leftOrRight) => {
    const { parentTitle, currentIndex, length } = props;
    console.log("props = ", props);
    if (parentTitle === "Expo Hall") {
      if (leftOrRight === "right" && currentIndex < length - 1) {
        setExpoHallCurrentIndex(currentIndex + 1);
        callExpoUpdate(currentIndex + 1);
      } else if (leftOrRight === "left" && currentIndex !== 0) {
        setExpoHallCurrentIndex(currentIndex - 1);
        callExpoUpdate(currentIndex - 1);
      }
    }
    if (parentTitle === "Satellite Auditorium") {
      if (leftOrRight === "right" && currentIndex < length - 1) {
        setSatAudiCurrentIndex(currentIndex + 1);
        callSatAudiUpdate(currentIndex + 1);
      } else if (leftOrRight === "left" && currentIndex !== 0) {
        setSatAudiCurrentIndex(currentIndex - 1);
        callSatAudiUpdate(currentIndex - 1);
      }
    }
    if (parentTitle === "Networking Lounges") {
      if (leftOrRight === "right" && currentIndex < length - 1) {
        setNetLoungeCurrentIndex(currentIndex + 1);
        callNetLoungesUpdate(currentIndex + 1);
      } else if (leftOrRight === "left" && currentIndex !== 0) {
        setNetLoungeCurrentIndex(currentIndex - 1);
        callNetLoungesUpdate(currentIndex - 1);
      }
    }
  };

  function showTitleUpdateResponse(updateRes) {
    if (updateRes?.payload?.data?.code === 200) {
      toast.success("Title has been updated.");
      callFetchEventCalenderData();
    } else {
      console.log("in error update expo");
      if (updateRes.error && updateRes.error === "Network Error")
        toast.error("Please check the internet connection");
      if (updateRes.payload?.data?.message)
        toast.error("Something went wrong, please try again.");
    }
    setPopOverData({
      titlePopVisible: false,
      parentTitle: "",
      updateTitle: "",
    });
  }

  async function updateTitle(newTitle, props) {
    console.log(" newTitle,props = ", newTitle, props);
    let updateRes;
    if (props.parentTitle === "Expo Hall") {
      updateRes = await dispatch(
        updateExpoHallTitle({
          expo_hall_configuration_id: props.boxData.expo_hall_configuration_id,
          hall_title: newTitle,
        })
      );
    } else if (
      props.parentTitle === "Main Auditorium" ||
      props.parentTitle === "Satellite Auditorium"
    ) {
      updateRes = await dispatch(
        updateAudiTitle({
          auditorium_configuration_id:
            props.boxData.auditorium_configuration_id,
          auditorium_title: newTitle,
          auditorium_type: props.boxData.auditorium_type,
        })
      );
    } else if (props.parentTitle === "Networking Lounges") {
      updateRes = await dispatch(
        updateNetworkTitle({
          networking_configuration_id:
            props.boxData.networking_configuration_id,
          lounge_title: newTitle,
        })
      );
    }
    console.log("update response", updateRes);
    if (updateRes) showTitleUpdateResponse(updateRes);
  }

  function dataToCheckForChatbotAndMessage(
    headerData,
    default_sponsor_details
  ) {
    let messageIcon = { isPresent: false, onClick: null };
    let chatbotResHeader = checkIfChatbotTimeSlot(headerData);
    let chatbotResDefault = checkIfChatbotTimeSlot(default_sponsor_details);
    // console.log("chatbotResDefault = ", chatbotResDefault);
    if (chatbotResHeader && headerData.chat_bot_start_date_time) {
      // console.log("messageIcon 1");
      messageIcon = {
        isPresent: true,
        onClick: () => setIsChatBot(true),
      };
    } else if (
      headerData.is_chat_enable &&
      headerData.is_chat_enable !== 0 &&
      headerData.channel_id
    ) {
      // console.log("messageIcon 2");
      messageIcon = {
        isPresent: true,
        onClick: () =>
          setMessageModal({
            isOpen: true,
            channel_id: headerData.channel_id,
          }),
      };
    } else if (
      chatbotResDefault &&
      default_sponsor_details.chat_bot_start_date_time &&
      default_sponsor_details.zone_id
    ) {
      // console.log("messageIcon 3");
      messageIcon = {
        isPresent: true,
        onClick: async () => {
          let chat_bot_res = await getChatbotJson({
            zone_id: default_sponsor_details.zone_id,
          });
          // console.log("chat_bot_res = ", chat_bot_res);
          if (chat_bot_res && !isEmpty(chat_bot_res)) {
            set_chatbot_json(chat_bot_res);
            setIsChatBot(true);
          } else toast.error("Chatbot details doesn't exist");
        },
      };
    } else if (
      default_sponsor_details.is_chat_enable &&
      default_sponsor_details.is_chat_enable !== 0 &&
      default_sponsor_details.channel_id
    ) {
      // console.log("messageIcon 4");
      messageIcon = {
        isPresent: true,
        onClick: () =>
          setMessageModal({
            isOpen: true,
            channel_id: default_sponsor_details.channel_id,
          }),
      };
    }

    return messageIcon;
  }

  const getTableHeader = (props) => {
    const { parentTitle, title, headerData } = props;
    // const {currentIndex,length,boxData} = props; //additional if required

    let normalLeftRightStyle = { justifyContent: "space-between" };
    if (parentTitle === "Main Auditorium")
      normalLeftRightStyle = { justifyContent: "center" };
    let filename = "";
    if (
      (headerData &&
        headerData.is_asset_enable &&
        headerData.is_asset_enable !== 0) ||
      (default_sponsor_details && default_sponsor_details.asset_file_url)
    ) {
      filename = headerData.asset_file_url
        ? getFileNameFromPath(headerData.asset_file_url)
        : default_sponsor_details
        ? getFileNameFromPath(default_sponsor_details.asset_file_url)
        : "";
    }

    let logoURL =
      headerData && !isEmpty(headerData)
        ? headerData.brand_logo
        : default_sponsor_details
        ? default_sponsor_details.logo_url
        : "";

    let messageIcon = { isPresent: false, onClick: null };

    messageIcon = dataToCheckForChatbotAndMessage(
      headerData,
      default_sponsor_details
    );

    return (
      <div
        className="text-center"
        style={{ textAlign: "center", backgroundColor: "white" }}
      >
        <span className="hall_header_title fs-pink">{parentTitle}</span>
        <div
          className="d-flex h-padding1"
          style={{ paddingTop: "1%", height: "35px", ...normalLeftRightStyle }}
        >
          {parentTitle !== "Main Auditorium" ? (
            <div style={{ width: "5%" }}>
              <LeftOutlined
                className="arrow_righ_left"
                onClick={() => onShuffleRightLeft(props, "left")}
              />
            </div>
          ) : null}

          <div className="box_title">
            <Text ellipsis className="fs-12">
              {title}
            </Text>
            <Popover
              placement="top"
              visible={
                popOverData.titlePopVisible &&
                popOverData.parentTitle === parentTitle
              }
              title={
                <div className="popover_header mt-2">
                  <span>{parentTitle + " Title"}</span>
                </div>
              }
              content={
                <div className="popover_bottom">
                  <Input
                    minLength={1}
                    maxLength={15}
                    value={popOverData.updateTitle}
                    onChange={(e) =>
                      setPopOverData({
                        titlePopVisible: popOverData.titlePopVisible,
                        parentTitle: popOverData.parentTitle,
                        updateTitle: e.target.value,
                      })
                    }
                  ></Input>
                  <div>
                    <Button
                      className="mt-3 rounded"
                      onClick={() =>
                        setPopOverData({
                          titlePopVisible: false,
                          parentTitle: "",
                          updateTitle: "",
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      className="mt-3 mx-2 rounded"
                      onClick={() =>
                        updateTitle(popOverData.updateTitle, props)
                      }
                    >
                      Update
                    </Button>
                  </div>
                </div>
              }
            >
              {userType === "SUPER ADMIN" || userType === "CUSTOMER" ? (
                <div
                  className="edit_icon mx-2"
                  onClick={() =>
                    setPopOverData({
                      titlePopVisible: true,
                      parentTitle,
                      updateTitle: title,
                    })
                  }
                />
              ) : null}
            </Popover>
          </div>
          {parentTitle !== "Main Auditorium" ? (
            <div style={{ width: "5%" }} className="mr-3">
              <RightOutlined
                className="arrow_righ_left"
                onClick={() => onShuffleRightLeft(props, "right")}
              />
            </div>
          ) : null}
        </div>
        <Divider style={{ margin: "2px 0" }} />
        <div style={{ height: "120px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="mt-1"
          >
            <small
              className="fs-11"
              style={{ color: "#00000099", opacity: "60%", fontWeight: "500" }}
            >
              Sponsored by
            </small>
            <img className="box_logo" src={logoURL} />
          </div>
          <div className="d-flex justify-content-center my-3 box_mini_icons">
            {filename && !isEmpty(filename) ? (
              <Tooltip
                color="#ef0855"
                placement="right"
                title={filename}
                className="mx-2"
              >
                <Button
                  href={
                    headerData.asset_file_url
                      ? headerData.asset_file_url
                      : default_sponsor_details.asset_file_url
                  }
                  download={filename}
                  className=" border p-1"
                >
                  <Download />
                </Button>
              </Tooltip>
            ) : null}
            {(headerData &&
              headerData.is_booth_link_enable &&
              headerData.is_booth_link_enable !== 0) ||
            (default_sponsor_details &&
              default_sponsor_details.is_on_booth_leads_enable) ? (
              <Tooltip
                color="#ef0855"
                placement="right"
                title={"Visit Booth"}
                className="mx-2"
              >
                <Button className="border p-1">
                  <Booth />
                </Button>
              </Tooltip>
            ) : null}
            {messageIcon.isPresent ? (
              <Tooltip
                color="#ef0855"
                placement="right"
                title={"Chat"}
                className="mx-2"
              >
                <Button
                  className="border p-1"
                  onClick={() => messageIcon.onClick()}
                >
                  <Chat />
                </Button>
              </Tooltip>
            ) : null}
            {(headerData &&
              headerData.is_demo_room_enable &&
              headerData.is_demo_room_enable !== 0 &&
              headerData.zone_demo_room_link) ||
            (default_sponsor_details &&
              default_sponsor_details.is_video_demo_room_enable &&
              default_sponsor_details.zone_demo_room_link) ? (
              <Tooltip
                color="#ef0855"
                placement="right"
                title={"Join the demo room"}
                className="mx-2"
              >
                <Button
                  onClick={() => {
                    window
                      .open(
                        headerData.zone_demo_room_link
                          ? headerData.zone_demo_room_link
                          : default_sponsor_details.zone_demo_room_link,
                        "_blank"
                      )
                      .focus();
                  }}
                  className="border p-1"
                >
                  <Video />
                </Button>
              </Tooltip>
            ) : null}
            {(headerData &&
              headerData.is_video_call_enable &&
              headerData.is_video_call_enable !== 0 &&
              headerData.channel_id) ||
            (default_sponsor_details &&
              default_sponsor_details.is_video_rep_enable &&
              default_sponsor_details.channel_id) ? (
              <Tooltip
                color="#ef0855"
                placement="right"
                title={"Next Available Agent"}
                className="mx-2"
              >
                <Button
                  className="border p-1"
                  onClick={async () => {
                    let channel_id =
                      headerData && headerData.channel_id
                        ? headerData.channel_id
                        : default_sponsor_details.channel_id;
                    console.log("channel_id video = ", channel_id);
                    const response = await videoRequestAPI(channel_id);
                    if (response.data.code === 200)
                      toast.success(response.data.message);
                    else toast.error(response.data.message);
                  }}
                >
                  <User />
                </Button>
              </Tooltip>
            ) : null}
          </div>
        </div>
        <Button className="rounded mt-2">
          <Booth />
          <Text ellipsis className="fs-12" style={{ marginLeft: "2px" }}>
            {title ? "Go to " + title : "Go to " + parentTitle}
          </Text>
        </Button>
      </div>
    );
  };

  const timeLabel = (start_time, end_time) => {
    return (
      <small className="text-white fs-10">
        {start_time
          ? start_time.format("hh:mm A") + " - " + end_time.format("hh:mm A")
          : ""}
      </small>
    );
  };

  const currentTimeLineDiv = (liveTimeHeightMargin) => {
    return (
      <div className="h-100">
        <div
          className="current_time_line"
          style={{ marginTop: liveTimeHeightMargin }}
        />
      </div>
    );
  };

  const getBlankCell = (props) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        className="w-50 cell_bgColor"
        onClick={props.onClick}
      >
        {props.liveTimeCondition && props.displayTimeCondition
          ? currentTimeLineDiv(props.liveTimeHeightMargin)
          : null}
      </div>
    );
  };

  const getExpoHallSingleCellData = (singleHourItem, rowIndex) => {
    let isHallHourIncluded = false;
    let currentHallStartTime, currentHallEndTime;
    let halfHrStart = moment(singleHourItem.start_time, TIME_FORMAT);
    let halfHrEnd = moment(singleHourItem.end_time, TIME_FORMAT);

    const liveTimeCondition =
      liveTime.time.format("hh:mm A") === halfHrEnd.format("hh:mm A");
    let liveTimeHeightMargin = liveTime.heightMargin;

    if (
      expoHallData &&
      expoHallData.boxData &&
      expoHallData.boxData.start_time &&
      expoHallData.boxData.end_time
    ) {
      currentHallStartTime = moment(
        expoHallData.boxData.start_time,
        TIME_FORMAT
      );
      currentHallEndTime = moment(expoHallData.boxData.end_time, TIME_FORMAT);
      if (
        ifTimeSlotIncluded(
          halfHrStart,
          halfHrEnd,
          currentHallStartTime,
          currentHallEndTime
        )
      )
        isHallHourIncluded = true;
    }

    let isHallSponsorIncluded = false;
    let currentHallSponsorStartTime, currentHallSponsorEndTime;
    let currentSponserData;

    if (expoHallSponsers && expoHallSponsers.boxData) {
      expoHallSponsers.boxData.some(function (ele, index) {
        currentHallSponsorStartTime = moment(ele.start_time, TIME_FORMAT);
        currentHallSponsorEndTime = moment(ele.end_time, TIME_FORMAT);
        if (
          ifTimeSlotIncluded(
            halfHrStart,
            halfHrEnd,
            currentHallSponsorStartTime,
            currentHallSponsorEndTime,
            true
          )
        ) {
          isHallSponsorIncluded = true;
          currentSponserData = ele;
          return true;
        }
      });
    }

    let isFirstCell = isFirstTitleCell(
      halfHrStart,
      halfHrEnd,
      currentHallStartTime
    );
    let isFirstSponserCell = isFirstTitleCell(
      halfHrStart,
      halfHrEnd,
      currentHallSponsorStartTime
    );
    let cellClass = "w-50 d-flex flex-column p-1";

    return (
      <td className="event_calender_td">
        <div className="d-flex w-100 h-55">
          {!isEmpty(expoHallData) && isHallHourIncluded ? (
            <div
              style={{
                cursor: "pointer",
                backgroundColor: "#f35187",
                marginRight: "1px",
              }}
              className={isFirstCell ? cellClass + " rounded-top" : cellClass}
              onClick={() => {
                console.log("expoHallData = ", expoHallData);
                let isOldDate = isPastDate(currentDateForCalender);
                if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                  if (isOldDate === "yes")
                    toast.error(
                      "Please select current date or future date to create/update expo hall open"
                    );
                  else
                    setExpoHallOpenModal({
                      expoHallData: expoHallData.boxData,
                      isOpen: true,
                    });
                }
              }}
            >
              {isFirstCell ? (
                <>
                  <small className="text-white fs-10">Expo Hall Open</small>
                  {timeLabel(currentHallStartTime, currentHallEndTime)}
                </>
              ) : null}
            </div>
          ) : (
            getBlankCell({
              onClick: () => {
                if (!expoHallData || isEmpty(expoHallData)) {
                  if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                    let isOldDate = isPastDate(currentDateForCalender);
                    if (isOldDate === "yes")
                      toast.error(
                        "Please select current date or future date to create/update expo hall open"
                      );
                    else
                      setExpoHallOpenModal({ expoHallData: {}, isOpen: true });
                  }
                }
              },
              liveTimeCondition,
              liveTimeHeightMargin,
              displayTimeCondition: true,
            })
          )}
          {!isEmpty(expoHallSponsers) && isHallSponsorIncluded ? (
            <div
              className={
                isFirstSponserCell ? cellClass + " rounded-top" : cellClass
              }
              style={{ cursor: "pointer", backgroundColor: "#5967bb" }}
              onClick={() => {
                let clonedAvlTimings = cloneDeep(expoAvlTimings.sponsers);
                const finalAvlTimings =
                  clonedAvlTimings &&
                  clonedAvlTimings.filter(
                    (item) =>
                      item.data &&
                      item.data.brand_configuration_id !==
                        currentSponserData.brand_configuration_id
                  );
                if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                  let isOldDate = isPastDate(currentDateForCalender);
                  if (isOldDate === "yes")
                    toast.error(
                      "Please select current date or future date to create/update sponser"
                    );
                  else
                    setSponsorModal({
                      isOpen: true,
                      sponserData: currentSponserData,
                      title: "Expo Hall Sponsorship",
                      existingTimings: finalAvlTimings,
                    });
                }
              }}
            >
              {isFirstSponserCell ? (
                <>
                  <small className="text-white fs-10">Sponsor</small>
                  {timeLabel(
                    currentHallSponsorStartTime,
                    currentHallSponsorEndTime
                  )}
                </>
              ) : null}
            </div>
          ) : (
            getBlankCell({
              onClick: () => {
                let clonedAvlTimings = cloneDeep(expoAvlTimings.sponsers);
                const finalAvlTimings =
                  clonedAvlTimings &&
                  clonedAvlTimings.filter(
                    (item) =>
                      item.data && item.data.brand_configuration_id !== ""
                  );
                if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                  let isOldDate = isPastDate(currentDateForCalender);
                  if (isOldDate === "yes")
                    toast.error(
                      "Please select current date or future date to create/update sponser"
                    );
                  else
                    setSponsorModal({
                      isOpen: true,
                      sponserData: {
                        expo_hall_configuration_id: expoHallData.boxData
                          ? expoHallData.boxData.expo_hall_configuration_id
                          : "",
                      },
                      title: "Expo Hall Sponsorship",
                      existingTimings: finalAvlTimings,
                    });
                }
              },
              liveTimeCondition,
              liveTimeHeightMargin,
              displayTimeCondition: true,
            })
          )}
        </div>
      </td>
    );
  };

  const renderCell = (props) => {
    const {
      mainData,
      cellName,
      singleHourItem,
      leftData,
      rightData,
      leftCellTitle,
      rightCellTitle,
      leftModalTitle,
      rightModalTitle,
    } = props;
    let leftStartTime, leftEndTime, rightStartTime, rightEndTime;
    let halfHrStart = moment(singleHourItem.start_time, TIME_FORMAT);
    let halfHrEnd = moment(singleHourItem.end_time, TIME_FORMAT);
    let isLeftIncluded = false;
    let isRightIncluded = false;

    let currentLeftData;
    let currentRightData;
    if (leftData && leftData.boxData) {
      leftData.boxData.some(function (ele, index) {
        leftStartTime = moment(ele.start_time, TIME_FORMAT);
        leftEndTime = moment(ele.end_time, TIME_FORMAT);
        if (
          ifTimeSlotIncluded(halfHrStart, halfHrEnd, leftStartTime, leftEndTime)
        ) {
          isLeftIncluded = true;
          currentLeftData = ele;
          return true;
        }
      });
    }

    if (rightData && rightData.boxData) {
      rightData.boxData.some(function (ele, index) {
        rightStartTime = moment(ele.start_time, TIME_FORMAT);
        rightEndTime = moment(ele.end_time, TIME_FORMAT);
        if (
          ifTimeSlotIncluded(
            halfHrStart,
            halfHrEnd,
            rightStartTime,
            rightEndTime
          )
        ) {
          isRightIncluded = true;
          currentRightData = ele;
          return true;
        }
      });
    }

    let isFirstLeftCell = isFirstTitleCell(
      halfHrStart,
      halfHrEnd,
      leftStartTime
    );
    let isFirstRightCell = isFirstTitleCell(
      halfHrStart,
      halfHrEnd,
      rightStartTime
    );
    let cellClass = "w-50 d-flex flex-column p-1";

    let leftCellColor =
      currentLeftData && currentLeftData.color
        ? currentLeftData.color
        : eventThemeColor;
    const liveTimeCondition =
      liveTime.time.format("hh:mm A") === halfHrEnd.format("hh:mm A");
    let liveTimeHeightMargin = liveTime.heightMargin;

    return (
      <td className="event_calender_td">
        <div className="d-flex w-100 h-55">
          {!isEmpty(mainData) && isLeftIncluded ? (
            <div
              style={{
                cursor: "pointer",
                backgroundColor: leftCellColor, //eventThemeColor,
                marginRight: "1px",
              }}
              className={
                isFirstLeftCell ? cellClass + " rounded-top" : cellClass
              }
              onClick={() => {
                let clonedAvlTimings;
                if (cellName === "mainAudi")
                  clonedAvlTimings = cloneDeep(mainAudiAvlTimings.sessions);
                if (cellName === "satAudi")
                  clonedAvlTimings = cloneDeep(satAudiAvlTimings.sessions);
                if (cellName === "netLounge")
                  clonedAvlTimings = cloneDeep(netLoungeAvlTimings.sessions);

                const finalAvlTimings =
                  clonedAvlTimings &&
                  clonedAvlTimings.filter(
                    (item) =>
                      item &&
                      item.data &&
                      currentLeftData &&
                      item.data.auditorium_session_id !==
                        currentLeftData.auditorium_session_id
                  );
                let objForModal = {
                  title: leftModalTitle,
                  isOpen: true,
                  existingTimings: finalAvlTimings,
                };

                let isOldDate = isPastDate(currentDateForCalender);

                if (cellName === "mainAudi" || cellName === "satAudi") {
                  let audiSessionModalData = {
                    audiSessionData: {
                      ...currentLeftData,
                      auditorium_configuration_id: mainData.boxData
                        ? mainData.boxData.auditorium_configuration_id
                        : "",
                    },
                    ...objForModal,
                  };

                  if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                    if (isOldDate === "yes")
                      toast.error(
                        "Please select current date or future date to create/update session"
                      );
                    else setAudiSessionModal(audiSessionModalData);
                  } else if (
                    userType === "BRAND ADMIN" ||
                    userType === "AGENT" ||
                    userType === "DELEGATE"
                  )
                    setAudiSessionView(audiSessionModalData);
                } else if (cellName === "netLounge") {
                  let netLoungeModalData = {
                    networkSessionData: {
                      ...currentLeftData,
                      networking_configuration_id: mainData.boxData
                        ? mainData.boxData.networking_configuration_id
                        : "",
                    },
                    ...objForModal,
                  };
                  if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                    if (isOldDate === "yes")
                      toast.error(
                        "Please select current date or future date to create/update session"
                      );
                    else setNetwrkSessionModal(netLoungeModalData);
                  } else if (
                    userType === "BRAND ADMIN" ||
                    userType === "AGENT" ||
                    userType === "DELEGATE"
                  )
                    setNetworkModalForView(netLoungeModalData);
                }
              }}
            >
              {isFirstLeftCell ? (
                <>
                  <small className="text-white fs-10">{leftCellTitle}</small>
                  {timeLabel(leftStartTime, leftEndTime)}
                </>
              ) : null}
            </div>
          ) : (
            getBlankCell({
              onClick: () => {
                if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                  let clonedAvlTimings;
                  if (cellName === "mainAudi")
                    clonedAvlTimings = cloneDeep(mainAudiAvlTimings.sessions);
                  if (cellName === "satAudi")
                    clonedAvlTimings = cloneDeep(satAudiAvlTimings.sessions);
                  if (cellName === "netLounge")
                    clonedAvlTimings = cloneDeep(netLoungeAvlTimings.sessions);

                  const finalAvlTimings =
                    clonedAvlTimings &&
                    clonedAvlTimings.filter(
                      (item) =>
                        item &&
                        item.data &&
                        item.data.auditorium_session_id !== ""
                    );
                  let objForModal = {
                    title: leftModalTitle,
                    isOpen: true,
                    existingTimings: finalAvlTimings,
                  };
                  let isOldDate = isPastDate(currentDateForCalender);
                  if (cellName === "mainAudi" || cellName === "satAudi") {
                    if (isOldDate === "yes")
                      toast.error(
                        "Please select current date or future date to create/update session"
                      );
                    else
                      setAudiSessionModal({
                        audiSessionData: {
                          auditorium_configuration_id: mainData.boxData
                            ? mainData.boxData.auditorium_configuration_id
                            : "",
                        },
                        ...objForModal,
                      });
                  } else if (cellName === "netLounge") {
                    if (isOldDate === "yes")
                      toast.error(
                        "Please select current date or future date to create/update session"
                      );
                    else
                      setNetwrkSessionModal({
                        networkSessionData: {
                          networking_configuration_id: mainData.boxData
                            ? mainData.boxData.networking_configuration_id
                            : "",
                        },
                        ...objForModal,
                      });
                  }
                }
              },
              liveTimeCondition,
              liveTimeHeightMargin,
              displayTimeCondition:
                cellName === "netLounge" || cellName === "satAudi"
                  ? false
                  : true,
            })
          )}

          {!isEmpty(mainData) && isRightIncluded ? (
            <div
              className={
                isFirstRightCell ? cellClass + " rounded-top" : cellClass
              }
              style={{ cursor: "pointer", backgroundColor: "#5967bb" }}
              onClick={() => {
                if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                  let clonedAvlTimings;
                  if (cellName === "mainAudi")
                    clonedAvlTimings = cloneDeep(mainAudiAvlTimings.sponsers);
                  if (cellName === "satAudi")
                    clonedAvlTimings = cloneDeep(satAudiAvlTimings.sponsers);
                  if (cellName === "netLounge")
                    clonedAvlTimings = cloneDeep(netLoungeAvlTimings.sponsers);

                  const finalAvlTimings =
                    clonedAvlTimings &&
                    clonedAvlTimings.filter(
                      (item) =>
                        item &&
                        item.data &&
                        currentRightData &&
                        item.data.brand_configuration_id !==
                          currentRightData.brand_configuration_id
                    );
                  console.log("clonedAvlTimings = ", clonedAvlTimings);
                  console.log("currentRightData = ", currentRightData);
                  console.log("finalAvlTimings pp= ", finalAvlTimings);
                  let objForModal = {
                    title: rightModalTitle,
                    isOpen: true,
                    existingTimings: finalAvlTimings,
                  };
                  if (
                    cellName === "mainAudi" ||
                    cellName === "satAudi" ||
                    cellName === "netLounge"
                  ) {
                    let isOldDate = isPastDate(currentDateForCalender);
                    if (isOldDate === "yes")
                      toast.error(
                        "Please select current date or future date to create/update sponser"
                      );
                    else
                      setSponsorModal({
                        sponserData: currentRightData,
                        ...objForModal,
                      });
                  }
                }
              }}
            >
              {isFirstRightCell ? (
                <>
                  <small className="text-white fs-10">{rightCellTitle}</small>
                  {timeLabel(rightStartTime, rightEndTime)}
                </>
              ) : null}
            </div>
          ) : (
            getBlankCell({
              onClick: () => {
                if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                  let clonedAvlTimings;
                  if (cellName === "mainAudi")
                    clonedAvlTimings = cloneDeep(mainAudiAvlTimings.sponsers);
                  if (cellName === "satAudi")
                    clonedAvlTimings = cloneDeep(satAudiAvlTimings.sponsers);
                  if (cellName === "netLounge")
                    clonedAvlTimings = cloneDeep(netLoungeAvlTimings.sponsers);

                  console.log("mainData.boxData = ", mainData.boxData);

                  const finalAvlTimings =
                    clonedAvlTimings &&
                    clonedAvlTimings.filter(
                      (item) =>
                        item.data && item.data.brand_configuration_id !== ""
                    );
                  let objForModal = {
                    title: rightModalTitle,
                    isOpen: true,
                    existingTimings: finalAvlTimings,
                  };
                  let isOldDate = isPastDate(currentDateForCalender);

                  if (cellName === "mainAudi" || cellName === "satAudi") {
                    if (isOldDate === "yes")
                      toast.error(
                        "Please select current date or future date to create/update sponser"
                      );
                    else
                      setSponsorModal({
                        sponserData: {
                          auditorium_configuration_id: mainData.boxData
                            ? mainData.boxData.auditorium_configuration_id
                            : "",
                        },
                        ...objForModal,
                      });
                  }
                  if (cellName === "netLounge") {
                    if (isOldDate === "yes")
                      toast.error(
                        "Please select current date or future date to create/update sponser"
                      );
                    else
                      setSponsorModal({
                        sponserData: {
                          networking_configuration_id: mainData.boxData
                            ? mainData.boxData.networking_configuration_id
                            : "",
                        },
                        ...objForModal,
                      });
                  }
                }
              },
              liveTimeCondition,
              liveTimeHeightMargin,
              displayTimeCondition:
                cellName === "netLounge" || cellName === "satAudi"
                  ? false
                  : true,
            })
          )}
        </div>
      </td>
    );
  };

  const getTickerCellData = (singleHourItem, rowIndex) => {
    let tickerStartTime, tickerEndTime;
    let isTickerIncluded = false;
    let halfHrStart = moment(singleHourItem.start_time, TIME_FORMAT);
    let halfHrEnd = moment(singleHourItem.end_time, TIME_FORMAT);
    let tickerCellData;
    if (tickerData && tickerData.boxData) {
      tickerData.boxData.some(function (ele, index) {
        tickerStartTime = moment(ele.start_time, TIME_FORMAT);
        tickerEndTime = moment(ele.end_time, TIME_FORMAT);
        if (
          ifTimeSlotIncluded(
            halfHrStart,
            halfHrEnd,
            tickerStartTime,
            tickerEndTime
          )
        ) {
          isTickerIncluded = true;
          tickerCellData = ele;
          return true;
        }
      });
    }

    let isFirstCell = isFirstTitleCell(halfHrStart, halfHrEnd, tickerStartTime);
    let cellClass = "w-100 d-flex flex-column";

    // console.log("tickerData = ", tickerData);
    // console.log("tickerCellData = ", tickerCellData);

    return (
      <td className="event_calender_td">
        <div className="d-flex w-100 h-55">
          {!isEmpty(tickerData) && isTickerIncluded ? (
            <div
              className={
                isFirstCell
                  ? cellClass + " p-1 rounded-top"
                  : cellClass + " p-1"
              }
              style={{ cursor: "pointer", backgroundColor: "#f35187" }}
              onClick={() => {
                if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                  let clonedAvlTimings = cloneDeep(tickerAvlTimings.sponsers);
                  console.log("clonedAvlTimings = ", clonedAvlTimings);
                  const finalAvlTimings =
                    clonedAvlTimings &&
                    clonedAvlTimings.filter(
                      (item) =>
                        item.data &&
                        item.data.brand_configuration_id !==
                          tickerCellData.brand_configuration_id
                    );
                  let isOldDate = isPastDate(currentDateForCalender);
                  if (isOldDate === "yes")
                    toast.error(
                      "Please select current date or future date to create/update ticker"
                    );
                  else
                    setTickerConfigModal({
                      isOpen: true,
                      tickerData: tickerCellData,
                      existingTimings: finalAvlTimings,
                    });
                }
              }}
            >
              {isFirstCell ? (
                <>
                  <small className="text-white fs-10">
                    {tickerCellData.ticker_name}
                  </small>
                  {timeLabel(tickerStartTime, tickerEndTime)}
                </>
              ) : null}
            </div>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              className={cellClass + " cell_bgColor"}
              onClick={() => {
                if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                  let clonedAvlTimings = cloneDeep(tickerAvlTimings.sponsers);
                  const finalAvlTimings =
                    clonedAvlTimings &&
                    clonedAvlTimings.filter(
                      (item) =>
                        item.data && item.data.brand_configuration_id !== ""
                    );
                  let isOldDate = isPastDate(currentDateForCalender);
                  if (isOldDate === "yes")
                    toast.error(
                      "Please select current date or future date to create/update ticker"
                    );
                  else
                    setTickerConfigModal({
                      isOpen: true,
                      tickerData: null,
                      existingTimings: finalAvlTimings,
                    });
                }
              }}
            />
          )}
        </div>
      </td>
    );
  };

  const getSocialCellData = (singleHourItem, rowIndex) => {
    let socialStartTime, socialEndTime;
    let isSocialIncluded = false;
    let halfHrStart = moment(singleHourItem.start_time, TIME_FORMAT);
    let halfHrEnd = moment(singleHourItem.end_time, TIME_FORMAT);
    let sponserColorObject = {};
    let socialCellData;
    if (socialData && socialData.boxData) {
      socialData.boxData.some(function (ele, index) {
        socialStartTime = moment(ele.start_time, TIME_FORMAT);
        socialEndTime = moment(ele.end_time, TIME_FORMAT);
        if (
          ifTimeSlotIncluded(
            halfHrStart,
            halfHrEnd,
            socialStartTime,
            socialEndTime
          )
        ) {
          isSocialIncluded = true;
          socialCellData = ele;
          sponserColorObject = { color: ele.color, rowIndex };
          return true;
        }
      });
    }

    let isFirstCell = isFirstTitleCell(halfHrStart, halfHrEnd, socialStartTime);
    let cellClass = "w-100 d-flex flex-column";
    return (
      <td className="event_calender_td">
        <div className="d-flex w-100 h-55">
          {!isEmpty(socialData) && isSocialIncluded ? (
            <div
              className={
                isFirstCell
                  ? cellClass + " p-1 rounded-top"
                  : cellClass + " p-1"
              }
              style={{
                cursor: "pointer",
                backgroundColor: sponserColorObject.color
                  ? sponserColorObject.color
                  : "#fe954b",
              }}
              onClick={() => {
                if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                  let clonedAvlTimings = cloneDeep(socialAvlTimings.sponsers);
                  const finalAvlTimings =
                    clonedAvlTimings &&
                    clonedAvlTimings.filter(
                      (item) =>
                        item.data &&
                        item.data.brand_configuration_id !==
                          socialCellData.brand_configuration_id
                    );
                  let isOldDate = isPastDate(currentDateForCalender);
                  if (isOldDate === "yes")
                    toast.error(
                      "Please select current date or future date to create/update social wall sponsership"
                    );
                  else
                    setSponsorModal({
                      isOpen: true,
                      sponserData: socialCellData,
                      title: "Social Wall Sponsorship",
                      existingTimings: finalAvlTimings,
                    });
                }
              }}
            >
              {isFirstCell ? (
                <>
                  <small className="text-white fs-10">Sponsor</small>
                  {timeLabel(socialStartTime, socialEndTime)}
                </>
              ) : null}
            </div>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              className={cellClass + " cell_bgColor"}
              onClick={() => {
                if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                  let clonedAvlTimings = cloneDeep(socialAvlTimings.sponsers);
                  const finalAvlTimings =
                    clonedAvlTimings &&
                    clonedAvlTimings.filter(
                      (item) =>
                        item.data && item.data.brand_configuration_id !== ""
                    );
                  let isOldDate = isPastDate(currentDateForCalender);
                  if (isOldDate === "yes")
                    toast.error(
                      "Please select current date or future date to create/update social wall sponsership"
                    );
                  else
                    setSponsorModal({
                      isOpen: true,
                      sponserData: null,
                      title: "Social Wall Sponsorship",
                      existingTimings: finalAvlTimings,
                    });
                }
              }}
            />
          )}
        </div>
      </td>
    );
  };

  const getConcourseCellData = (singleHourItem, rowIndex) => {
    let conStartTime, conEndTime;
    let isConIncluded = false;
    let halfHrStart = moment(singleHourItem.start_time, TIME_FORMAT);
    let halfHrEnd = moment(singleHourItem.end_time, TIME_FORMAT);
    let concourseCellData;
    if (concourseData && concourseData.boxData) {
      concourseData.boxData.some(function (ele, index) {
        conStartTime = moment(ele.start_time, TIME_FORMAT);
        conEndTime = moment(ele.end_time, TIME_FORMAT);
        if (
          ifTimeSlotIncluded(halfHrStart, halfHrEnd, conStartTime, conEndTime)
        ) {
          isConIncluded = true;
          concourseCellData = ele;
          return true;
        }
      });
    }

    let isFirstCell = isFirstTitleCell(halfHrStart, halfHrEnd, conStartTime);
    let cellClass = "w-100 d-flex flex-column";
    return (
      <td>
        <div className="d-flex w-100 h-55">
          {!isEmpty(concourseData) && isConIncluded ? (
            <div
              className={
                isFirstCell
                  ? cellClass + " p-1 rounded-top"
                  : cellClass + " p-1"
              }
              style={{ cursor: "pointer", backgroundColor: "#5967bb" }}
              onClick={() => {
                if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                  let clonedAvlTimings = cloneDeep(concrsAvlTimings.sponsers);
                  const finalAvlTimings =
                    clonedAvlTimings &&
                    clonedAvlTimings.filter(
                      (item) =>
                        item.data &&
                        item.data.brand_configuration_id !==
                          concourseCellData.brand_configuration_id
                    );
                  let isOldDate = isPastDate(currentDateForCalender);
                  if (isOldDate === "yes")
                    toast.error(
                      "Please select current date or future date to create/update concourse sponser"
                    );
                  else
                    setSponsorModal({
                      isOpen: true,
                      sponserData: concourseCellData,
                      title: "Concourse Sponsors",
                      existingTimings: finalAvlTimings,
                    });
                }
              }}
            >
              {isFirstCell ? (
                <>
                  <small className="text-white fs-10">Sponsor</small>
                  {timeLabel(conStartTime, conEndTime)}
                </>
              ) : null}
            </div>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              className={cellClass + " cell_bgColor"}
              onClick={() => {
                if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
                  let clonedAvlTimings = cloneDeep(concrsAvlTimings.sponsers);
                  const finalAvlTimings =
                    clonedAvlTimings &&
                    clonedAvlTimings.filter(
                      (item) =>
                        item.data && item.data.brand_configuration_id !== ""
                    );
                  let isOldDate = isPastDate(currentDateForCalender);
                  if (isOldDate === "yes")
                    toast.error(
                      "Please select current date or future date to create/update concourse sponser"
                    );
                  else
                    setSponsorModal({
                      isOpen: true,
                      sponserData: null,
                      title: "Concourse Sponsors",
                      existingTimings: finalAvlTimings,
                    });
                }
              }}
            />
          )}
        </div>
      </td>
    );
  };

  function onDateNavigate(leftOrRight) {
    let newDate;
    let newIndexForNavigate;
    if (
      leftOrRight === "right" &&
      current_date_to_navigate.currentIndex <
        current_date_to_navigate.length - 1
    ) {
      let indexNew = event_dates_to_navigate.findIndex(
        (ele) => ele.currentIndex === current_date_to_navigate.currentIndex
      );
      newIndexForNavigate = indexNew + 1;
      newDate = moment(event_dates_to_navigate[indexNew + 1].dateString);
    } else if (
      leftOrRight === "left" &&
      current_date_to_navigate.currentIndex !== 0
    ) {
      let indexNew = event_dates_to_navigate.findIndex(
        (ele) => ele.currentIndex === current_date_to_navigate.currentIndex
      );
      newIndexForNavigate = indexNew - 1;
      newDate = moment(event_dates_to_navigate[indexNew - 1].dateString);
    }

    if (newDate) {
      set_current_date_to_navigate({
        currentIndex: newIndexForNavigate,
        dateString: event_dates_to_navigate[newIndexForNavigate].dateString,
        length: event_dates_to_navigate.length,
      });

      console.log("newDate = ", newDate.format("YYYY-MM-DD"));
      setCurrentDateForCalender(newDate.format("YYYY-MM-DD"));
      setDateToDisplay(newDate.format("MMMM DD, yyyy"));
      setDateForDatePicker(newDate);
      setExpoHallCurrentIndex(0);
      setSatAudiCurrentIndex(0);
      setNetLoungeCurrentIndex(0);
      callFetchEventCalenderData(true, newDate.format("YYYY-MM-DD"));
    }
  }

  const getRows = () => {
    return halfHours.map((singleHourItem, rowIndex) => {
      return (
        <tr
          id={
            singleHourItem.start_time ===
            liveTime.timeSlotOfCurrentTime.start_time
              ? "timeline_div"
              : "half_hr_div"
          }
          key={"half_hr_" + singleHourItem.start_time}
          className="border-left border-right h-55"
          style={{ width: "100%" }}
        >
          <td
            className="border-right timeslots sticky-col timeslot_sticky"
            style={{ height: "inherit", width: "100px" }}
          >
            <div
              className="d-flex flex-column justify-content-between text-center"
              style={{ height: "100%", marginTop: "-10px" }}
            >
              <small className="align-top align-items-start">
                {singleHourItem.start_time}
              </small>
            </div>
          </td>
          {/* expo hall */}
          {event_settings.is_expo_hall_active
            ? getExpoHallSingleCellData(singleHourItem, rowIndex)
            : null}
          {/* main audi */}
          {event_settings.is_main_hall_active
            ? renderCell({
                mainData: mainAudiData,
                cellName: "mainAudi",
                singleHourItem,
                leftData: mainAudiSessions,
                rightData: mainAudiSponsers,
                leftCellTitle: "Session",
                rightCellTitle: "Sponser",
                leftModalTitle: "Main Auditorium Session Editor",
                rightModalTitle: "Main Auditorium Sponsorship",
              })
            : null}

          {/* sat audi */}
          {event_settings.is_sat_auditorium_active
            ? renderCell({
                mainData: satAudiData,
                cellName: "satAudi",
                singleHourItem,
                leftData: satAudiSessions,
                rightData: satAudiSponsers,
                leftCellTitle: "Session",
                rightCellTitle: "Sponser",
                leftModalTitle: "Satellite Auditorium Session Editor",
                rightModalTitle: "Satellite Auditorium Sponsorship",
              })
            : null}

          {/* netwrk launges */}
          {event_settings.is_networking_active
            ? renderCell({
                mainData: netLoungeData,
                cellName: "netLounge",
                singleHourItem,
                leftData: netLoungeSessions,
                rightData: netLoungeSponsers,
                leftCellTitle: "Session",
                rightCellTitle: "Sponser",
                leftModalTitle: "Admin Networking Session Editor",
                rightModalTitle: "Network Lounge Sponsorship",
              })
            : null}
          {/* ticker type sponsers */}
          {event_settings.is_ticker_tape_active &&
          eventCalendarData &&
          eventCalendarData.show_ticker
            ? getTickerCellData(singleHourItem, rowIndex)
            : null}
          {/* social wall sponsers */}
          {event_settings.is_social_wall_active
            ? getSocialCellData(singleHourItem, rowIndex)
            : null}
          {/* Concourse wall sponsers */}
          {event_settings.is_concourse_active
            ? getConcourseCellData(singleHourItem, rowIndex)
            : null}
        </tr>
      );
    });
  };

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      console.log("cometChatUser= ", cometChatUser);
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  if (loader) return <CommonLoader />;
  else
    return (
      <div
        onClick={() => {
          if (datePickerModal) setDatePickerModal(false);
        }}
      >
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="fs-24 p-0 m-0 my-2">Event Calendar</h4>
          </div>

          {(userType && userType === "SUPER ADMIN") ||
          userType === "CUSTOMER" ? (
            <div style={{ width: "20%" }}>
              <div
                className={Tablet ? "col-4" : "col-9"}
                style={{ display: "flex" }}
              >
                <div className={Tablet ? "col-4" : "col-6"}>
                  <span>Event Name</span>
                </div>
                <div className={Tablet ? "col-4" : "col-9"}>
                  <Select
                    showSearch
                    placeholder="Select Event"
                    className="w-100 fs-bold-14"
                    value={currentEventID}
                    disabled={disableEvent}
                    onChange={(val) => setCurrentEventID(val)}
                    optionFilterProp="children"
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {!isEmpty(eventList) &&
                      eventList.map((data, index) => {
                        return (
                          <Option key={"event_" + index} value={data.event_id}>
                            {data.event_name}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {!currentEventID ? (
          <Result
            icon={<SmileOutlined />}
            title="Select an event to display event calender"
          />
        ) : isNoEventDataFound ? (
          <Result
            icon={<SmileOutlined />}
            title="Data not found, please try again later"
          />
        ) : (
          <Layout>
            {isChatBot && chatbot_json ? (
              <CustomChatbot
                onClose={async (conversation_details) => {
                  let saveRes = await saveChatbotHistory({
                    event_id: currentEventID,
                    conversation_details,
                  });
                  console.log("saveRes chat bot = ", saveRes);
                  setIsChatBot(false);
                }}
                responseJson={chatbot_json}
                chatbot_history={chatbot_history}
                onSetHistory={(newHistory) => set_chatbot_history(newHistory)}
                extraProps={{ event_id: currentEventID }}
              />
            ) : null}
            {expoHallOpenModal.isOpen ? (
              <ExpoHallOpen
                onSuccess={(response) => {
                  console.log(
                    "response from success of expo hall = ",
                    response
                  );
                  if (response) {
                    setExpoHallOpenModal({ isOpen: false, expoHallData: null });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setExpoHallOpenModal({ isOpen: false, expoHallData: null })
                }
                expoHallData={{
                  ...expoHallOpenModal.expoHallData,
                  hall_title: expoHallData.boxData.hall_title,
                  event_id: currentEventID,
                  currentDateForCalender,
                }}
              />
            ) : null}
            {sponsorModal.isOpen ? (
              <Sponser
                onSuccess={(response) => {
                  console.log(
                    "response from success of expo hall = ",
                    response
                  );
                  if (response) {
                    setSponsorModal({
                      isOpen: false,
                      sponserData: null,
                      title: "",
                      existingTimings: [],
                    });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setSponsorModal({
                    isOpen: false,
                    sponserData: null,
                    title: "",
                    existingTimings: [],
                  })
                }
                sponserData={{
                  ...sponsorModal.sponserData,
                  event_id: currentEventID,
                  currentDateForCalender,
                  title: sponsorModal.title,
                  existingTimings: sponsorModal.existingTimings,
                }}
              />
            ) : null}
            {audiSessionModal.isOpen ? (
              <AudiSession
                onSuccess={(response) => {
                  console.log("response from success AudiSession = ", response);
                  if (response) {
                    setAudiSessionModal({
                      isOpen: false,
                      audiSessionData: null,
                      title: "",
                      existingTimings: [],
                    });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setAudiSessionModal({
                    isOpen: false,
                    audiSessionData: null,
                    title: "",
                    existingTimings: [],
                  })
                }
                sessionData={{
                  ...audiSessionModal.audiSessionData,
                  event_id: currentEventID,
                  currentDateForCalender,
                  existingTimings: audiSessionModal.existingTimings,
                  brandsOfEvent,
                  currentEventData,
                }}
                title={audiSessionModal.title}
              />
            ) : null}

            {audiSessionView.isOpen ? (
              <AudiView
                onSuccess={(response) => {
                  console.log("response from success AudiSession = ", response);
                  if (response) {
                    setAudiSessionView({
                      isOpen: false,
                      audiSessionData: null,
                      title: "",
                      existingTimings: [],
                    });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setAudiSessionView({
                    isOpen: false,
                    audiSessionData: null,
                    title: "",
                    existingTimings: [],
                  })
                }
                sessionData={{
                  ...audiSessionView.audiSessionData,
                  event_id: currentEventID,
                  currentDateForCalender,
                  existingTimings: audiSessionView.existingTimings,
                  brandsOfEvent,
                }}
                title={audiSessionView.title}
              />
            ) : null}

            {netwrkSessionModal.isOpen ? (
              <NetworkingSession
                onSuccess={(response) => {
                  console.log("response from success AudiSession = ", response);
                  if (response) {
                    setNetwrkSessionModal({
                      isOpen: false,
                      networkSessionData: null,
                      title: "",
                      existingTimings: [],
                    });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setNetwrkSessionModal({
                    isOpen: false,
                    networkSessionData: null,
                    title: "",
                    existingTimings: [],
                  })
                }
                sessionData={{
                  ...netwrkSessionModal.networkSessionData,
                  event_id: currentEventID,
                  currentDateForCalender,
                  existingTimings: netwrkSessionModal.existingTimings,
                  currentEventData,
                }}
                title={netwrkSessionModal.title}
              />
            ) : null}

            {networkModalForView.isOpen ? (
              <NetworkModalView
                onSuccess={(response) => {
                  console.log("response from success AudiSession = ", response);
                  if (response) {
                    setNetworkModalForView({
                      isOpen: false,
                      networkSessionData: null,
                      title: "",
                      existingTimings: [],
                    });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setNetworkModalForView({
                    isOpen: false,
                    networkSessionData: null,
                    title: "",
                    existingTimings: [],
                  })
                }
                sessionData={{
                  ...networkModalForView.networkSessionData,
                  event_id: currentEventID,
                  currentDateForCalender,
                  existingTimings: netwrkSessionModal.existingTimings,
                }}
                title={networkModalForView.title}
              />
            ) : null}

            {tickerConfigModal.isOpen ? (
              <TickerConfiguration
                onSuccess={(response) => {
                  console.log("response from success of Ticker = ", response);
                  if (response) {
                    setTickerConfigModal({
                      isOpen: false,
                      tickerData: null,
                      existingTimings: [],
                    });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setTickerConfigModal({
                    isOpen: false,
                    tickerData: null,
                    existingTimings: [],
                  })
                }
                tickerConfigData={{
                  ...tickerConfigModal.tickerData,
                  event_id: currentEventID,
                  currentDateForCalender,
                  existingTimings: tickerConfigModal.existingTimings,
                }}
                title={"Ticker Configuration"}
              />
            ) : null}
            {messageModal.isOpen ? (
              <Modal
                title={<b>Send Message</b>}
                visible={messageModal.isOpen}
                onOk={() => clearMessageAndModal()}
                onCancel={() => clearMessageAndModal()}
                footer={[
                  <Button
                    className="px-3 button-pink rounded"
                    key="cancel"
                    onClick={() => clearMessageAndModal()}
                  >
                    Cancel
                  </Button>,
                  <Button
                    className="px-3 rounded"
                    key="save"
                    type="primary"
                    onClick={() => callSendMessageAPI(messageModal.channel_id)}
                  >
                    Send
                  </Button>,
                ]}
                className="message_modal"
              >
                <Row className="ticker_configuration_row mt-2">
                  <Col span={6} className="ticker_configuration_label">
                    <p>
                      Message
                      <RedAsterick />
                    </p>
                  </Col>
                  <Col span={12}>
                    <TextArea
                      name="message_text"
                      id="message_text"
                      className="ticker_configuration_inputDescription"
                      placeholder="Enter a message"
                      rows={4}
                      value={message_text}
                      onChange={(e) => set_message_text(e.target.value)}
                      onBlur={() =>
                        messageValidator.current.showMessageFor("message_text")
                      }
                    />
                    {messageValidator.current.message(
                      "Message",
                      message_text,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                </Row>
              </Modal>
            ) : null}
            <Sider className="border-right bg-white" width={215}>
              <div className="p-3">
                <Input
                  size="medium"
                  className="w-100 rounded fs-bold-14"
                  onChange={(e) => searchSessions(e)}
                  allowClear
                  placeholder="Search Session"
                  style={{ width: "70%" }}
                  prefix={<SearchOutlined />}
                />
                <div className="row mt-1 p-2">
                  <span className="text-secondary p-0 m-0 px-1">
                    {filteredSessions.length} Results
                  </span>
                  {filteredSessions?.map((data) => {
                    return (
                      <div className="side_searchbar_div my-1 event_calender_searchbar">
                        <Text strong ellipsis className="my-1 fs-10">
                          {data.session_title}
                        </Text>
                        <Text ellipsis className="mb-1 fs-10">
                          {data.session_description}
                        </Text>
                        <div className="row mb-1">
                          <span className="col-6 fs-10 fs-pink border-end">
                            <b>Go to Session</b>
                          </span>
                          <span className="col-6 fs-10 fs-pink">
                            <b>Go to Booth</b>
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Sider>
            <Layout>
              <Header className="border-bottom bg-white pl-2">
                <div className="d-flex align-middle align-items-center">
                  <Button
                    className="px-3 button-pink rounded"
                    style={{ height: "43px" }}
                    onClick={() => {
                      let todayString = moment().format("YYYY-MM-DD");
                      let indexNew = event_dates_to_navigate.findIndex(
                        (ele) => ele.dateString === todayString
                      );
                      if (indexNew === -1)
                        toast.error(
                          `Today's date is not included in event shows`
                        );
                      else if (indexNew >= 0) {
                        set_current_date_to_navigate({
                          currentIndex: indexNew,
                          dateString:
                            event_dates_to_navigate[indexNew].dateString,
                          length: event_dates_to_navigate.length,
                        });
                        setCurrentDateForCalender(todayString);
                        setDateToDisplay(today);
                        setDateForDatePicker(moment());
                        setDatePickerModal(false);
                        setExpoHallCurrentIndex(0);
                        setSatAudiCurrentIndex(0);
                        setNetLoungeCurrentIndex(0);
                        callFetchEventCalenderData(true, todayString);
                      }
                    }}
                  >
                    TODAY
                  </Button>
                  {datePickerModal && (
                    <div
                      className="px-2 align-items-center"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <DatePicker
                        open={true}
                        defaultOpen={true}
                        name="adminSatellite_prev_pending_date_time1"
                        format={"D/M/YYYY HH:mm"}
                        className="adminSatellite_date_time"
                        disabledDate={(current) => {
                          // console.log("current = ", current);
                          let fo = current.format("YYYY-MM-DD");
                          if (event_dates.includes(fo)) return false;
                          else return true;
                        }}
                        value={dateForDatePicker}
                        onChange={(value) => {
                          console.log("date on change = ", value);
                          setCurrentDateForCalender(value.format("YYYY-MM-DD"));
                          setDateToDisplay(value.format("MMMM DD, yyyy"));
                          setDateForDatePicker(value);
                          setDatePickerModal(false);
                          setExpoHallCurrentIndex(0);
                          setSatAudiCurrentIndex(0);
                          setNetLoungeCurrentIndex(0);
                          callFetchEventCalenderData(
                            true,
                            value.format("YYYY-MM-DD")
                          );
                        }}
                        style={{ width: "80%" }}
                      />
                    </div>
                  )}
                  {!datePickerModal && (
                    <div className="px-2 d-flex align-items-center cursor-pointer">
                      <LeftOutlined
                        className="px-2 arrow_righ_left"
                        onClick={() => onDateNavigate("left")}
                      />
                      <RightOutlined
                        className="px-2 arrow_righ_left"
                        onClick={() => onDateNavigate("right")}
                      />
                      <span
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        onClick={() => setDatePickerModal(true)}
                      >
                        {dateToDisplay}
                      </span>
                    </div>
                  )}
                </div>
              </Header>
              {isNoDataForTheDay ? (
                <Result
                  icon={<SmileOutlined />}
                  title="No event for the day!"
                />
              ) : (
                <div className="event_table_wrapper">
                  <table className="event_table table-responsive">
                    <thead className="event_table_header">
                      <tr className="event_table_header_row">
                        <th className="border_except_top sticky-col event_header_left timeslot_sticky" />
                        {/* expo hall header */}
                        {event_settings.is_expo_hall_active ? (
                          <th className="border_except_top justify-content-center event_header_double">
                            {!isEmpty(expoHallData) ? (
                              getTableHeader({
                                parentTitle: "Expo Hall",
                                currentIndex: expoHallData.currentIndex,
                                title:
                                  expoHallData.boxData &&
                                  expoHallData.boxData.hall_title
                                    ? expoHallData.boxData.hall_title
                                    : "",
                                boxData: expoHallData.boxData,
                                headerData: expoSponserHeader,
                                length: expoHallData.count,
                              })
                            ) : (
                              <Empty description="No Hall Found" />
                            )}
                          </th>
                        ) : null}

                        {/* main audi header */}
                        {event_settings.is_main_hall_active ? (
                          <th className="border_except_top justify-content-center event_header_double">
                            {!isEmpty(mainAudiData) ? (
                              <div className="py-3">
                                {getTableHeader({
                                  parentTitle: "Main Auditorium",
                                  currentIndex: mainAudiData.currentIndex,
                                  title: mainAudiData.boxData
                                    ? mainAudiData.boxData.auditorium_title
                                    : "",
                                  boxData: mainAudiData.boxData,
                                  headerData: mainAudiSponserHeader,
                                  length: mainAudiData.count,
                                })}
                              </div>
                            ) : (
                              <Empty description="No Audi Found" />
                            )}
                          </th>
                        ) : null}

                        {/* sat audi header */}
                        {event_settings.is_sat_auditorium_active ? (
                          <th className="border_except_top justify-content-center event_header_double">
                            {!isEmpty(satAudiData) ? (
                              <div className="py-3">
                                {getTableHeader({
                                  parentTitle: "Satellite Auditorium",
                                  currentIndex: satAudiData.currentIndex,
                                  title: satAudiData.boxData
                                    ? satAudiData.boxData.auditorium_title
                                    : "",
                                  boxData: satAudiData.boxData,
                                  headerData: satAudiSponserHeader,
                                  length: satAudiData.count,
                                })}
                              </div>
                            ) : (
                              <Empty description="No Audi Found" />
                            )}
                          </th>
                        ) : null}

                        {/* net lounges header */}
                        {event_settings.is_networking_active ? (
                          <th className="border_except_top justify-content-center event_header_double">
                            {!isEmpty(netLoungeData) ? (
                              <div className="py-3">
                                {getTableHeader({
                                  parentTitle: "Networking Lounges",
                                  currentIndex: netLoungeData.currentIndex,
                                  title: netLoungeData.boxData
                                    ? netLoungeData.boxData.lounge_title
                                    : "",
                                  boxData: netLoungeData.boxData,
                                  headerData: netSponserHeader,
                                  length: netLoungeData.count,
                                })}
                              </div>
                            ) : (
                              <Empty description="No Audi Found" />
                            )}
                          </th>
                        ) : null}
                        {event_settings.is_ticker_tape_active &&
                        eventCalendarData &&
                        eventCalendarData.show_ticker ? (
                          <th className="border_except_top border-left align-bottom event_header_single">
                            <div width="10%" className="text-center">
                              <span className="text-wrap fs-14">
                                Ticker Tape Sponsers
                              </span>
                            </div>
                          </th>
                        ) : null}

                        {event_settings.is_social_wall_active ? (
                          <th className="border_except_top align-bottom event_header_single">
                            <div width="10%" className="text-center">
                              <span className="text-wrap fs-14">
                                Social Wall Sponsers
                              </span>
                            </div>
                          </th>
                        ) : null}

                        {event_settings.is_concourse_active ? (
                          <th className="border-bottom border-right align-bottom event_header_single">
                            <div width="10%" className="text-center">
                              <span className="text-wrap fs-14">
                                Concourse Sponsers
                              </span>
                            </div>
                          </th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody className="event_table_body">
                      <tr
                        className="border-left border-right cell_bgColor h-10"
                        style={{ width: "100%" }}
                      ></tr>
                      {getRows()}
                    </tbody>
                  </table>
                </div>
              )}
            </Layout>
          </Layout>
        )}
      </div>
    );
};

export default EventCalendar;
