import React, { Fragment, useState, useEffect } from "react";
import { Table, Button, Popover, Breadcrumb, Input, message } from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "./index.css";
import { logInLogo } from "../../components/Images/Images";
import { onShowSizeChange } from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import { getCustomer } from "../../redux/slice/customerManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const CustomerManagement = () => {
  const [searchData, setsearchData] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCustomer());
  }, []);

  useEffect(() => {
    const searchObj = {
      search: searchData,
    };
    dispatch(getCustomer(searchObj));
  }, [searchData]);

  const { rows } = useSelector((state) => state.customerManagement.data);

  const tableData =
    rows &&
    rows.map((elem) => {
      return {
        customer_id: elem.customer_id,
        created_at: elem.created_at,
        customername: elem.customer_name,
        maincontentname: elem.main_contact_person,
        emailaddress: elem.main_contact_email,
        event_count: elem.event_count,
        is_active: elem.is_active,
        start: elem.license_period_start_date,
        end: elem.license_period_end_date,
        event_count: elem?.customer_customer_settings_id?.total_events_count + "/" + elem?.customer_customer_settings_id?.max_no_of_events,
        brands_count: elem?.customer_customer_settings_id?.total_brands_count + "/" + elem?.customer_customer_settings_id?.max_no_of_exhibitors,
        users_count: elem?.customer_customer_settings_id?.total_users_count + "/" + elem?.customer_customer_settings_id?.max_no_of_users,
        created_by: elem?.created_user_name

      };
    });

  const handleTableChange = (pagination, filters, sorter) => {
    dispatch(
      getCustomer({
        sort_type: sorter.columnKey,
        sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
      })
    );
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "customer_id",
      key: "customer_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 70,
    },
    {
      title: "Name",
      dataIndex: "customername",
      key: "customername",
      width: 150,
    },
    {
      title: "Start",
      dataIndex: "start",
      key: "start",
      width: 100,

    },
    {
      title: "End",
      dataIndex: "end",
      key: "end",
      width: 100,

    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 100,
      render: (text, record) => (
        <Button
          className={
            record.is_active ? "bg-button-green active_btns" : "bg-button-red"
          }
          shape="round"
        >
          {record.is_active ? "Active" : "Inactive"}
        </Button>
      ),
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
    },
    {
      title: "#Events",
      dataIndex: "event_count",
      key: "event_count",
      width: 100,

      // sorter: true,
      // sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "#Brands",
      dataIndex: "brands_count",
      key: "brands_count",
      width: 100,
      // sorter: true,
      // sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "#Users",
      dataIndex: "users_count",
      key: "users_count",
      width: 100,
      // sorter: true,


      // sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
      width: 100,

    },
    {
      title: "Streaming",
      dataIndex: "streaming",
      key: "storage",
      width: 100,

    },
    {
      title: "Video Hours",
      dataIndex: "video_hours",
      key: "video_hours",
      width: 100,

    },
    {
      title: "Contact",
      dataIndex: "maincontentname",
      key: "maincontentname",
      width: 150,

    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      width: 150,

    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 150,

    },

    {
      title: "",
      dataIndex: "operation",
      fixed: "right",
      width: 60,

      render: (_, record) => (

        <Popover
          placement="bottomLeft"
          content={
            <div className="">
              <p className="options">
                <Link
                  className="options"
                  to={`viewcustomerdetails/${record.customer_id}`}
                >
                  <EyeOutlined className=" px-1" />{" "}
                  <span className="">View Details</span>
                </Link>
              </p>
              <Link to={`editcustomer/${record.customer_id}`}>
                <p className="options">
                  <EditOutlined className=" px-1" />{" "}
                  <span className="">Edit Profile</span>
                </p>
              </Link>
              <p className="options">
                <img src={logInLogo} alt="login logo" className="px-1" /> User
                Log-In
              </p>
            </div>
          }
          trigger="click"
        >
          <div style={{ textAlign: "center" }}>
            <MoreOutlined />

          </div>
        </Popover>


      ),
    },

  ];

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="fs-24">Customers</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
            <Breadcrumb.Item>Customers</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>
          <Link to="createnewaccount">
            <Button className=" rounded px-3 py-1 mt-2">
              <span className="fw-normal"> + Create New Account </span>
            </Button>
          </Link>
        </div>
      </div>

      <div className="bg-white p-4 w-100">
        <div className="d-flex justify-content-between mb-4">
          <div className="w-100">
            <Input
              size="medium"
              placeholder="Search Account Name, Customer email"
              value={searchData}
              onChange={handleInputSearch}
              style={{ width: "80%" }}
              prefix={<SearchOutlined />}
            />
          </div>

          <div className="d-flex"></div>
        </div>
        <Table
          className="listing_table"
          columns={columns}
          dataSource={tableData}
          pagination={{
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
          }}
          scroll={{
            x: 1300,
          }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </Fragment>
  );
};

export default CustomerManagement;
