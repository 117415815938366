import React, { Fragment, useEffect, useState } from "react";
import { Table, Button, Popover, Breadcrumb, Input } from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SelectOutlined,
  SearchOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "./index.css";
import { onShowSizeChange } from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEventList, changeStatus } from "../../redux/slice/eventManagement";
import FilterContent from "./FilterContent";
import moment from "moment";
import { clearEventData } from "../../redux/slice/editEvent.js";
import { clearNewEventData } from "../../redux/slice/createEventNew.js";
import { Filter } from "../../components/svgJS/Filter";
import { isEmpty } from "lodash";

const EventManagement = () => {
  var CryptoJS = require("crypto-js");
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("");
  const [searchData, setSearchData] = useState();
  let eventSelector = useSelector((state) => state.eventManagement.eventList);

  useEffect(() => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    window.scrollTo(0, 0);
    dispatch(getEventList());
    dispatch(clearEventData({}));
    dispatch(clearNewEventData({}));
  }, []);

  useEffect(() => {
    if (searchData) {
      const searchObj = {
        search: searchData,
      };
      dispatch(getEventList(searchObj));
    }
  }, [searchData]);

  const handleStatus = (id, bool) => {
    dispatch(
      changeStatus({
        event_id: id,
        is_active: !bool,
      })
    );
  };

  const tableData =
    eventSelector &&
    !isEmpty(eventSelector) &&
    eventSelector.rows &&
    eventSelector.rows.map((elem, i) => {
      return {
        key: "event_table_row_" + i.toString(),
        event_id: elem.event_id,
        created_at: moment(elem?.created_at).format("DD-MM-YYYY HH:mm"),
        created_by: elem?.created_user_name,
        eventdomainname: elem?.sub_domain,
        customername: elem?.customer.customer_name,
        eventduration: elem?.event_duration + " Days",
        // tierstype:
        //   elem?.events_tiers_settings[0]?.eventsTiersSettings_tier_id?.tier,
        brand_title: elem?.events_organizer_brand_id?.brand_title,
        is_active: elem?.is_active === true ? true : false,
      };
    });

  const columns = [
    {
      title: "Event ID",
      dataIndex: "event_id",
      key: "event_id",
      //   render: text => <a href="#3">{text}</a>,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Event Domain Name",
      dataIndex: "eventdomainname",
      key: "eventdomainname",
    },
    {
      title: "Event Duration",
      dataIndex: "eventduration",
      key: "eventduration",
    },
    // {
    //   title: "Tiers Type",
    //   dataIndex: "tierstype",
    //   key: "tier",
    //   sorter: true,
    //   sortDirections: ["ascend", "descend", "ascend"],
    // },
    {
      title: "Brands",
      dataIndex: "brand_title",
      key: "brand_title",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Customer Name",
      dataIndex: "customername",
      key: "customername",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => {
        return (
          <Button
            className={
              record.is_active === true
                ? "bg-button-green active_btns"
                : "bg-button-red"
            }
            onClick={() => handleStatus(record.event_id, record.is_active)}
            shape="round"
          >
            {record.is_active === true ? "Active" : "Inactive"}
          </Button>
        );
      },
      sorter: true,
    },
    {
      title: "",
      dataIndex: "operation",
      render: (text, record, index) => (
        <Popover
          content={
            <div>
              <Link
                to={{
                  pathname: "viewnewevent",
                  // pathname: `viewnewevent/${record.event_id}`,
                  eventId: record,
                }}
              >
                <p className="options_color">
                  <EyeOutlined className="mx-1" />
                  <span className="">View Details</span>
                </p>
              </Link>
              <Link to={`editevent/${record.event_id}`}>
                <p className="options_color">
                  <EditOutlined className="mx-1" />
                  <span className="">Edit Event</span>
                </p>
              </Link>
              {userType && userType === "CUSTOMER" ? (
                <p></p>
              ) : (
                <p className="options_color">
                  <SelectOutlined className="mx-1" />
                  <span className=""> View Domain </span>
                </p>
              )}
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      render: (text, record, index) => (
        <div className="px-1">
          <Link to={`editevent/${record.event_id}`}>
            <p className="options_color m-0">
              <EditOutlined className="" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      eventid: "#041254121",
      createdat: "12-12-21 10:25",
      createdby: "Super Admin",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "2",
      eventid: "#041254215",
      createdat: "10-10-21 10:25",
      createdby: "Super Admin",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 2",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Inactive",
    },
    {
      key: "3",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 3",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "4",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 4",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Inactive",
    },
    {
      key: "5",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 5",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "6",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "7",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "8",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Inactive",
    },
    {
      key: "9",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "10",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "11",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Inactive",
    },
    {
      key: "12",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "13",
      eventid: "#041254121",
      createdat: "12-12-21 10:25",
      createdby: "Super Admin",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "14",
      eventid: "#041254215",
      createdat: "10-10-21 10:25",
      createdby: "Super Admin",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "15",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "16",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "17",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "18",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "19",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "20",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "21",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "22",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "23",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
    {
      key: "24",
      eventid: "#041254125",
      createdat: "11-11-21 10:25",
      createdby: "Customer Name",
      eventdomainname: "peakweek.gravit.io",
      customername: "customer 1",
      eventduration: "365 Days",
      tierstype: "Diamond",
      brands: "10",
      status: "Active",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const sortObj = {
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    };

    dispatch(getEventList(sortObj));
  };

  const handleInputSearch = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="fs-24">Event Management</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
            <Breadcrumb.Item>Event Management</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <Link to="createevent">
            <Button className="button-pink rounded px-3 py-1 mt-2">
              <span className="fw-bold"> + Create New Event </span>
            </Button>
          </Link>
        </div>
      </div>

      <div className="bg-white p-4">
        <div className="d-flex justify-content-between mb-4">
          <div className="w-50">
            <Input
              size="medium"
              placeholder="Search Name, ID , Customer Name"
              style={{ width: "70%" }}
              prefix={<SearchOutlined />}
              value={searchData}
              onChange={handleInputSearch}
            />
          </div>

          <div className="d-flex">
            <Popover
              placement="bottomRight"
              title="Activity Filters"
              overlayStyle={{ width: "82%", zIndex: 1000 }}
              content={FilterContent}
              trigger="click"
            >
              <Button className=" rounded">
                <Filter />
                <span className="ms-2"> FILTER</span> <DownOutlined />
              </Button>
            </Popover>

            {/* <Popover placement="bottomRight" title={text} content={content} trigger="click">
            <Button>BR</Button>
            </> */}
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            total: data.length,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
          }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </Fragment>
  );
};

export default EventManagement;
