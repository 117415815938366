import React, { useEffect, useState, useRef } from "react";
import "./Auditorium.css";
import {
	Row,
	Col,
	Carousel,
	Button,
	Select,
	DatePicker,
	Divider,
	Avatar,
	Empty,
	Input,
	Tooltip,
	Modal,
} from "antd";
import moment from "moment";
import { cloneDeep, isEmpty, isEqual, uniqWith } from "lodash";
import Text from "antd/lib/typography/Text";

import { SearchOutlined } from "@ant-design/icons";

import { Booth } from "../../components/svgJS/Booth";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { Download } from "../../components/svgJS/Download";
import { Calendar6 } from "../../components/svgJS/Calendar6";
import CommonLoader from "../../components/Widgets/CommonLoader";

import { getEvents } from "../../utils/commonApis";
import { toast } from "react-toastify";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
	getEventAudiDetails,
	getAudiSessionDetails,
} from "../../redux/slice/auditorium";

import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { sendMessageAPI, videoRequestAPI } from "../../utils/commonApis";

import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "../../components/constsnts/common.constants";
import { ApiCall } from "../../utils/api";

const Auditorium = (props) => {
	const { Option } = Select;
	let CryptoJS = require("crypto-js");

	const dateFormat = "LL";
	const { TextArea } = Input;
	const localData_event_id = parseInt(localStorage.getItem("event_id"));
	const [disableEvent, setDisableEvent] = useState(false);
	const [userType, setUserType] = useState("");

	// console.log("local", localData_event_id);
	const dispatch = useDispatch();
	const eventAudiDetails = useSelector(
		(state) => state?.auditorium?.eventAudiData,
	);
	const audiSessionDetails = useSelector(
		(state) => state.auditorium.audiSessionData,
	);
	const [eventList, setEventList] = useState([]);
	const [loading, setLoading] = useState(false);

	const [date, setDate] = useState(moment());
	const [sponsorData, setSponsorData] = useState();
	const [filteredSessions, setFilteredSessions] = useState([]);
	const [eventid, setEventID] = useState();
	const [eventAudiData, setEventAudiData] = useState([]);
	const [allSessions, setAllSessions] = useState([]);
	const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
	const [message_text, set_message_text] = useState("");
	const [cometChatUser, setCometChatUser] = useState({
		isValidUser: false,
		data: {},
		uid: "",
	});
	const [audiSessAvailable, setAudiSessAvailable] = useState([]);
	const [forceUpdate, setForceUpdate] = useState(false);

	const messageValidator = useRef(new SimpleReactValidator());

	// let user_type;

	useEffect(async () => {
		const eventsData = await getEvents();
		const ciphertext = localStorage.getItem("user_type");
		let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
		let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		setUserType(user_type);
		if (
			user_type === "DELEGATE" ||
			user_type === "SPEAKER" ||
			user_type === "BRAND ADMIN"
		) {
			const events = eventsData.filter((item) => {
				return item.event_id === localData_event_id;
			});

			setEventList(events);
			let id = events[0].event_id;
			setEventID(id);
			dispatch(
				getEventAudiDetails({
					event_id: id,
				}),
			);
			setDisableEvent(true);
		} else {
			setEventList(eventsData);
		}

		let chat_uid = localStorage.getItem("chat_uid");
		if (chat_uid) {
			CometChat.login(chat_uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
				(response) => {
					if (response) {
						console.log("CometChat Login Successful:", response);
						setCometChatUser({
							isValidUser: true,
							data: response,
							uid: response.getUid(),
						});
					}
				},
				(error) => {
					console.log("CometChat Login failed with exception:", { error });
				},
			);
		}
	}, []);

	useEffect(() => {
		if (date && eventid) {
			dispatch(
				getEventAudiDetails({
					event_id: eventid,
				}),
			);
		}
	}, [date]);

	useEffect(() => {
		if (eventAudiDetails && eventid) {
			initializeEventAudiData();
		}
	}, [eventAudiDetails]);

	useEffect(() => {
		if (eventAudiData && eventid) {
			handleFilterChange(0);
		}
	}, [eventAudiData]);

	useEffect(() => {
		if (audiSessionDetails && eventid) {
			checkSponsorAndSessionDateTime();
			checkAudiSessionAvailable();
		}
	}, [audiSessionDetails]);

	const checkAudiSessionAvailable = () => {
		const arr = audiSessionDetails?.session_details
			?.filter((data) => {
				let date1 = moment(date).format("YYYY-MM-DD");
				let date2 = moment
					.utc(data?.start_date_time)
					.format("YYYY-MM-DD");

				let getNowTime = moment().format("HH:mm");

				let afterTime = moment
					.utc(data?.end_date_time)
					.format("HH:mm");
				if (date1 === date2) {
					if (getNowTime > afterTime) {
						return;
					} else {
						return data;
					}
				}

			});
		setAudiSessAvailable(arr);
	}

	function clearMessageAndModal() {
		set_message_text("");
		setMessageModal({ isOpen: false, data: {} });
	}

	async function callSendMessageAPI(channel_id) {
		if (messageValidator.current.allValid()) {
			if (cometChatUser.uid) {
				const response = await sendMessageAPI(channel_id, message_text);
				if (response.data.code === 200) {
					toast.success(response.data.message);
					clearMessageAndModal();
				} else {
					toast.error(response.data.message);
				}
			} else setForceUpdate(!forceUpdate);
		} else {
			messageValidator.current.showMessages();
			setForceUpdate(!forceUpdate);
		}
	}

	const checkSponsorAndSessionDateTime = () => {
		let arr = [];
		audiSessionDetails?.sponsor_details?.forEach((data) => {
			let currentDate = moment(date).format("YYYY-MM-DD");
			let sessDate = moment.utc(data?.start_date_time).format("YYYY-MM-DD");

			let nowTime = moment(date).format("HH:mm:ss");
			let startTime = moment.utc(data?.start_date_time).format("HH:mm:ss");
			let endTime = moment.utc(data?.end_date_time).format("HH:mm:ss");
			if (currentDate === sessDate) {
				if (nowTime >= startTime && nowTime <= endTime) {
					arr.push(data);
				}
			}
		});

		if (arr.length !== 0) {
			setSponsorData(arr[0]);
		} else {
			setSponsorData(audiSessionDetails?.default_sponsor_details);
		}
		// console.log("final Arr", arr);
	};

	const initializeEventAudiData = () => {
		let date1 = moment(date).format("YYYY-MM-DD");

		const arr = eventAudiDetails?.filter((item) => {
			let date2 = moment.utc(item?.start_date_time).format("YYYY-MM-DD");
			// console.log("Event Audi data");
			// console.log("todays date ->", date1, "Event Audi-date ->", date2);
			if (date1 === date2) {
				return item;
			}
		});

		setEventAudiData(arr);
	};

	const NextArrow = (props) => {
		const { onClick } = props;
		return (
			<>
				<div className="audi_next_arrow" onClick={onClick}>
					<i class="arrow right"></i>
				</div>
			</>
		);
	};

	const PrevArrow = (props) => {
		const { className, style, onClick } = props;
		return (
			<>
				<div className="audi_prev_arrow" onClick={onClick}>
					<i class="arrow left"></i>
				</div>
			</>
		);
	};

	const settings = {
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	};

	const [audiConfigId, setAuiConfigId] = useState(null);
	const [audiType, setAudiType] = useState("");
	const handleFilterChange = (index) => {
		//API Call for filter Auditorium will come here

		if (eventAudiData) {
			const item = eventAudiData.find((elem, i) => {
				return i === index;
			});

			if (item) {
				setLoading(true);

				// console.log(item);

				setAuiConfigId(item.auditorium_configuration_id);
				setAudiType(item.auditorium_type);
				dispatch(
					getAudiSessionDetails({
						auditorium_configuration_id: item?.auditorium_configuration_id,
						auditorium_type: item?.auditorium_type,
						event_id: eventid,
					}),
				);
			}
			setTimeout(() => {
				setLoading(false);
			}, 500);
		}
	};

	const searchSessions = (e) => {
		// setFilteredSessions([]);
		let searchTerm = e.target.value.toLowerCase().trim();
		console.log("searchTerm = ", searchTerm);
		let allSearchedData = [];
		if (searchTerm !== "" || !isEmpty(searchTerm)) {
			audiSessionDetails &&
				audiSessionDetails?.session_details?.forEach((item) => {
					if (item?.session_title?.toLowerCase().includes(searchTerm)) {
						allSearchedData.push(item);
					}
				});
		}
		console.log(allSearchedData);
		setFilteredSessions(allSearchedData);
	};

	const handleAddtoCalendar = async (sessId) => {
		let body = {
			event_id: eventid,
			hall_type: audiType,
			hall_id: audiConfigId,
			session_id: sessId,
		};

		console.log(body);
		const response = await ApiCall(
			"POST",
			body,
			"/admin/mycalendar/create",
			"",
		);
		console.log("Add Calendar Response", response);
		if (response?.data?.code === 200) {
			toast.success(response?.data?.message);
			props.history.push("/mycalendar");
		}
	};

	console.log("usertytpe in audi", userType);

	return (
		<>
			<div className={props.isFromVista ? "bg-80vh bg-white p-4" : "bg-80vh"}>
				<div className="d-flex justify-content-between flex-wrap">
					{console.log("eventAudiDetails", eventAudiDetails)}
					{console.log("audiSessionDetails", audiSessionDetails)}

					<div className="d-flex ">
						{props.isFromVista ? (
							""
						) : (
							<div className="mb-2 ">
								<h4 className="mb-1">Auditorium</h4>
								<span className="opacity-50 ">Auditorium</span>
							</div>
						)}
						{
							eventAudiData && eventAudiData.length !== 0 ? (
								<Carousel
									afterChange={handleFilterChange}
									className="audi_carousel "
									arrows
									{...settings}
								>
									{console.log("Event Audi Data", eventAudiData)}
									{eventAudiData &&
										eventAudiData?.map((data, i) => {
											return (
												<div className="d-flex justify-content-center align-items-center mt-2">
													<h6>{data?.auditorium_title}</h6>
												</div>
											);
										})}
								</Carousel>
							) : ""
						}

					</div>

					{props.isFromVista &&
						(userType !== "SUPER ADMIN" || userType !== "CUSTOMER") ? (
						""
					) : userType === "SUPER ADMIN" || userType === "CUSTOMER" ?
						<div className="mb-3">
							<span className="mx-2">Event Name</span>
							{console.log("Event ID", eventid)}
							<Select
								showSearch
								className="audi_event_search"
								placeholder="Select Events"
								optionFilterProp="children"
								disabled={disableEvent}
								value={eventid}
								bordered={false}
								listHeight={120}
								listItemHeight={4}
								onChange={(val) => {
									setEventID(val);
									dispatch(
										getEventAudiDetails({
											event_id: val,
										}),
									);
								}}
							>
								{eventList &&
									eventList.map((data, index) => {
										return (
											<Option key={"event_" + index} value={data.event_id}>
												{data.event_name}
											</Option>
										);
									})}
							</Select>
						</div>
						: ""
					}
				</div>

				{eventid ? (
					loading ? (
						<>
							<CommonLoader />
						</>
					) : (
						// eventAudiDetails.length !== 0 &&
						//   audiSessionDetails &&
						//   audiSessionDetails?.length !== 0 &&
						//   audiSessionDetails?.session_details?.length !== 0
						<div className="audi_container w-100 d-flex">
							{true ? (
								<>
									<div className="audi_left_container d-flex flex-column  p-3">
										<h5 className="mb-3 mx-auto">Sponsored by</h5>

										<img
											className="auditorium_img mb-3 mx-auto"
											src={sponsorData && sponsorData?.logo_url}
										/>

										<div className="d-flex justify-content-center w-100 mb-3 mx-auto">
											{sponsorData?.is_asset_enable &&
												sponsorData?.is_asset_enable !== "NA" &&
												sponsorData?.asset_file_url ? (
												<Tooltip placement="right" title="Download">
													<Button
														onClick={() =>
														(window.location.href =
															sponsorData?.asset_file_url)
														}
														className="ms-2 border "
													>
														<Download />
													</Button>
												</Tooltip>
											) : (
												""
											)}

											{sponsorData?.is_booth_link_enable &&
												sponsorData?.is_booth_link_enable !== "NA" ? (
												<Tooltip placement="right" title="Visit Booth">
													<Button className="border ms-2">
														<Booth />
													</Button>
												</Tooltip>
											) : (
												""
											)}

											{sponsorData?.is_chat_enable &&
												sponsorData?.is_chat_enable !== "NA" ? (
												<Tooltip

													placement="right"
													title={"Chat"}
												>
													<Button
														className="border ms-2 "
														onClick={() => {
															setMessageModal({
																isOpen: true,
																channel_id: sponsorData?.channel_id,
															});
														}}
													>
														<Chat />
													</Button>
												</Tooltip>
											) : (
												""
											)}

											{messageModal.isOpen ? (
												<Modal
													title={<b>Send Message</b>}
													visible={messageModal.isOpen}
													onOk={() => clearMessageAndModal()}
													onCancel={() => clearMessageAndModal()}
													footer={[
														<Button
															className="px-3 button-pink rounded"
															key="cancel"
															onClick={() => clearMessageAndModal()}
														>
															Cancel
														</Button>,
														<Button
															className="px-3 rounded"
															key="save"
															type="primary"
															onClick={() =>
																callSendMessageAPI(messageModal.channel_id)
															}
														>
															Send
														</Button>,
													]}
													className="message_modal"
												>
													<Row className="ticker_configuration_row mt-2">
														<Col
															span={6}
															className="ticker_configuration_label"
														>
															<p>
																Message
																<RedAsterick />
															</p>
														</Col>
														<Col span={12}>
															<TextArea
																name="message_text"
																id="message_text"
																className="ticker_configuration_inputDescription"
																placeholder="Enter a message"
																rows={4}
																value={message_text}
																onChange={(e) =>
																	set_message_text(e.target.value)
																}
																onBlur={() =>
																	messageValidator.current.showMessageFor(
																		"message_text",
																	)
																}
															/>
															{messageValidator.current.message(
																"Message",
																message_text,
																"required",
																{ className: "text-danger py-1" },
															)}
														</Col>
													</Row>
												</Modal>
											) : null}

											{sponsorData?.is_demo_room_enable &&
												sponsorData?.is_demo_room_enable !== "NA" &&
												sponsorData?.zone_demo_room_link ? (
												<Tooltip placement="right" title="Demo Room">
													<Button
														onClick={() =>
															window
																.open(
																	sponsorData?.zone_demo_room_link,
																	"_blank",
																)
																.focus()
														}
														className="ms-2 border "
													>
														<Video />
													</Button>
												</Tooltip>
											) : (
												""
											)}

											{sponsorData?.is_video_call_enable &&
												sponsorData?.is_video_call_enable !== "NA" ? (
												<Tooltip placement="right" title="Video Call">
													<Button
														onClick={async () => {
															let channel_id = sponsorData?.channel_id;
															console.log("channel_id video = ", channel_id);
															const response = await videoRequestAPI(
																channel_id,
															);
															if (response.data.code === 200)
																toast.success(response.data.message);
															else toast.error(response.data.message);
														}}
														className="border ms-2"
													>
														<Video />
													</Button>
												</Tooltip>
											) : (
												""
											)}
										</div>
										<h6 className="mb-3 mx-auto">Agenda</h6>

										<DatePicker
											className="audi_date mb-3"
											defaultValue={date}
											onChange={(val) => {
												setDate(val);
											}}
											format={dateFormat}
										/>
										<Input
											placeholder="Search by Session"
											className="audi_search_input"
											onChange={(e) => searchSessions(e)}
											prefix={<SearchOutlined />}
										/>

										<div className="audi_search_container mt-2 w-100">
											{filteredSessions?.map((item) => {
												return (
													<div className="audi_options mt-2 custom_border">
														<span>{item?.session_title}</span>
													</div>
												);
											})}
										</div>

										<Divider />
										<div className="audi_calendar_container">
											{audiSessAvailable && audiSessAvailable.map((data) => {
												return (
													<>
														<div className="d-flex position-relative overflow-auto">
															<div className="w-25 h-25">
																<p className="position-absolute top-0 start-0 m-0">
																	{moment
																		.utc(data?.start_date_time)
																		.format("HH:mm")}{" "}
																</p>
																<p className="position-absolute bottom-0 start-0 m-0">
																	{moment
																		.utc(data?.end_date_time)
																		.format("HH:mm")}{" "}
																</p>
															</div>
															<div className="audi_session d-flex flex-column p-2 ms-2">
																<span className="text-white">
																	{" "}
																	{data?.session_title
																		? data?.session_title
																		: "NA"}
																</span>
																<span className="text-white audi_calendar_text">
																	{data?.session_description}
																</span>
																<span className="text-white">
																	{moment
																		.utc(data?.start_date_time)
																		.format("HH:mm")}{" "}
																	-{" "}
																	{moment
																		.utc(data?.end_date_time)
																		.format("HH:mm")}{" "}
																</span>
															</div>
														</div>

													</>
												);
											})}
										</div>
									</div>
									<div className="audi_right_container w-100 h-100 d-flex  ms-4">
										<Row className="w-100 h-100">
											{audiSessAvailable && audiSessAvailable.map((data) => {

												let getNowTime = moment(date).format("HH:mm:ss");
												let beforeTime = moment
													.utc(data?.start_date_time)
													.format("HH:mm:ss");
												let afterTime = moment
													.utc(data?.end_date_time)
													.format("HH:mm:ss");



												return (
													<>
														<Col
															className="audi_col"
															xs={16}
															lg={11}
															span={11}
															offset={1}
														>
															<div>
																<h5>
																	{getNowTime >= beforeTime &&
																		getNowTime <= afterTime
																		? "Up Now"
																		: "Up Next"}
																</h5>
																{/* Card 1 */}

																<div className="bg-white p-3">
																	<span>Title :</span>
																	<h5>
																		{data?.session_title
																			? data?.session_title
																			: "NA"}
																	</h5>
																	<span>Time :</span>
																	<h6>
																		{moment
																			.utc(data?.start_date_time)
																			.format("HH:mm")}{" "}
																		-
																		{moment
																			.utc(data?.end_date_time)
																			.format("HH:mm")}
																	</h6>
																	<span>Description :</span>
																	<p>{data?.session_description}</p>
																	{getNowTime >= beforeTime &&
																		getNowTime <= afterTime ? (
																		<Button
																			className="audi_join_sessionBtn"
																			onClick={() => {
																				window
																					.open(
																						data?.join_session_url,
																						"_blank",
																					)
																					.focus();
																			}}
																		>
																			<span className="ms-3">
																				<Booth />
																				<span className="ms-2">
																					Join Session Now
																				</span>
																			</span>
																		</Button>
																	) : (
																		<>
																			{data?.is_iCal_enabled ? (
																				<Button className="audi_downloadBtn">
																					<span className="">
																						Download iCal{" "}
																					</span>
																				</Button>
																			) : (
																				""
																			)}
																			{data?.added_to_my_calendar ? (
																				<Button
																					onClick={() =>
																						handleAddtoCalendar(
																							data?.auditorium_session_id,
																						)
																					}
																					className="audi_calendarBtn mx-3"
																				>
																					<Calendar6 />
																					<span className="ms-4">
																						Add to My Calendar{" "}
																					</span>
																				</Button>
																			) : (
																				""
																			)}
																		</>
																	)}
																</div>

																{/* Card 2 */}

																<Carousel
																	className="audi_card_carousel w-100  bg-white mt-3"
																	arrows
																	{...settings}
																>
																	{data?.auditorium_hosting_brands &&
																		data?.auditorium_hosting_brands?.map(
																			(data) => {
																				return (
																					<div className="d-flex  align-items-center mt-2">
																						<div className="mx-2">
																							<img
																								width={150}
																								height={150}
																								className="img-responsive"
																								src={
																									data
																										?.auditorium_hosting_speakers[0]
																										?.user?.image_url
																								}
																							/>
																						</div>
																						<div>
																							<span>Speaker :</span>
																							<h5>
																								{data
																									?.auditorium_hosting_speakers[0]
																									?.user?.first_name ||
																									data
																										?.auditorium_hosting_speakers[0]
																										?.user?.last_name
																									? data
																										?.auditorium_hosting_speakers[0]
																										?.user?.first_name +
																									" " +
																									data
																										?.auditorium_hosting_speakers[0]
																										?.user?.last_name
																									: "NA"}
																							</h5>
																							<span>Bio :</span>
																							<p>
																								{data
																									?.auditorium_hosting_speakers[0]
																									?.user?.bio
																									? data
																										?.auditorium_hosting_speakers[0]
																										?.user?.bio
																									: "NA"}
																							</p>
																						</div>
																					</div>
																				);
																			},
																		)}
																</Carousel>

																{/* Card 3 */}

																<Carousel
																	className="audi_card_carousel w-100  bg-white mt-3"
																	arrows
																	{...settings}
																>
																	{data?.auditorium_hosting_brands &&
																		data?.auditorium_hosting_brands?.map(
																			(data) => {
																				return (
																					<div className="w-100 d-flex  align-items-center mt-2">
																						<div className="mx-2">
																							<img
																								width={150}
																								height={150}
																								className="img-responsive"
																								src={data?.logo_url}
																							/>
																						</div>
																						<div>
																							{data?.is_assets_enabled &&
																								data?.zone_assets?.[0] &&
																								data?.zone_assets?.[0]
																									?.asset_file_url ? (
																								<Button
																									className="audi_pdfBtn mb-3"
																									onClick={() =>
																										window
																											.open(
																												data
																													?.zone_assets?.[0]
																													?.asset_file_url,
																												"_blank",
																											)
																											.focus()
																									}
																								>
																									<Download />
																									<span className="ms-2 fs-16">
																										sponsorasset.pdf
																									</span>
																								</Button>
																							) : (
																								""
																							)}

																							<div className="d-flex  w-100 mb-3">
																								{data?.is_booth_link_enabled ? (
																									<Button className="border p-1">
																										<Booth />
																									</Button>
																								) : (
																									""
																								)}

																								{data?.is_chat_enabled ? (
																									<Button
																										onClick={() => {
																											setMessageModal({
																												isOpen: true,
																												channel_id: 4,
																											});
																										}}
																										className="border p-1 ms-2"
																									>
																										<Chat />
																									</Button>
																								) : (
																									""
																								)}

																								{data?.is_demo_room_enabled &&
																									data?.zone_demo_room_link ? (
																									<Button className="border p-1 ms-2">
																										<Video />
																									</Button>
																								) : (
																									""
																								)}
																								{data?.is_video_call_enabled ? (
																									<Button
																										onClick={async () => {
																											let channel_id = 4;
																											console.log(
																												"channel_id video = ",
																												channel_id,
																											);
																											const response =
																												await videoRequestAPI(
																													channel_id,
																												);
																											if (
																												response.data
																													.code === 200
																											)
																												toast.success(
																													response.data
																														.message,
																												);
																											else
																												toast.error(
																													response.data
																														.message,
																												);
																										}}
																										className="border p-1 ms-2"
																									>
																										<Video />
																									</Button>
																								) : (
																									""
																								)}
																							</div>
																						</div>
																					</div>
																				);
																			},
																		)}
																</Carousel>
															</div>
														</Col>
													</>
												);

											})}
										</Row>
									</div>
								</>
							) : (
								<div className="w-100 h-100">
									<Empty description={<span>No Sessions </span>} />
								</div>
							)}
						</div>
					)
				) : (
					<>
						<div className="">
							<h4 className="fs-pink text-center mt-5">
								Please Select an Event to display Sessions
							</h4>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default Auditorium;
