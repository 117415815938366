import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Popconfirm,
  Popover,
  Breadcrumb,
  Input,
  Menu,
  Select,
  message,
} from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SelectOutlined,
  SearchOutlined,
  DownOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { filter } from "../../components/Images/Images";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import { data } from "./temp-data";
import { getZone, changeStatus } from "../../redux/slice/zoneManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../utils/api";
import moment from "moment";

const ZoneManagement = () => {
  const [searchData, setsearchData] = useState("");
  const [brandList, setbrandList] = useState(null);
  // console.log(brandList);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getZone());
    getBrandList();
  }, []);

  useEffect(() => {
    const searchObj = {
      search: searchData,
    };
    dispatch(getZone(searchObj));
  }, [searchData]);

  const { rows } = useSelector((state) => state.zoneManagement.data);

  const getBrandList = () => {
    ApiCall("get", null, "/admin/brand/get", null)
      .then((response) => {
        // console.log(response);
        setbrandList(response?.data?.data?.rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const tableData =
    rows &&
    rows.map((elem) => {
      return {
        // key: "1",
        zone_id: elem.zone_id,
        created_at: moment(elem.created_at).format("DD-MM-YYYY HH:mm"),
        zone_title: elem.zone_title,
        brands: elem.zone_brand_title,
        locationonwall: elem.booth_location.booth_location,
        // assets: elem.asset_description,
        assets_count: {
          upper: elem?.no_of_assets_per_zone,
          lower: elem?.total_assets_count,
        },
        is_active: elem.is_active,
      };
    });

  const { Option } = Select;

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  function handleButtonClick(e) {
    message.info("Click on left button.");
    console.log("click left button", e);
  }

  function handleMenuClick(e) {
    message.info("Click on menu item.");
    console.log("clicks", e);
  }
  const handleStatus = (id, bool) => {
    dispatch(
      changeStatus({
        zone_id: id,
        is_active: !bool,
      })
    );
  };

  const columns = [
    {
      title: "Zone ID",
      dataIndex: "zone_id",
      key: "zone_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      //   render: text => <a href="#3">{text}</a>,
    },
    {
      title: "Brand",
      dataIndex: "brands",
      key: "brands",
    },
    {
      title: "Zone Title",
      dataIndex: "zone_title",
      key: "zone_title",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <Button
          className={
            record.is_active === true
              ? "bg-button-green active_btns"
              : "bg-button-red  inactive_btns"
          }
          onClick={() => handleStatus(record.zone_id, record.is_active)}
          shape="round"
        >
          {record.is_active === true ? "Active" : "Inactive"}
        </Button>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      // width: "10%",
    },
    {
      title: "Location",
      dataIndex: "locationonwall",
      key: "locationonwall",
      // width: "10%",
    },
    // {
    //   title: "Hero Image",
    //   dataIndex: "heroimage",
    //   key: "heroimage",
    // },
    {
      title: "Assets",
      dataIndex: "assets_count",
      key: "assets_count",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (record) => (
        <>
          <span style={{ color: "#ef0855" }}>{record?.upper}</span>/
          <span>{record?.lower}</span>
        </>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Created By",
      dataIndex: "createdby",
      key: "createdby",
    },
    {
      title: "Created D/T",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      // width: "20%",
    },
    {
      title: "Customer Name",
      dataIndex: "customername",
      key: "customername",
    },
    {
      title: "Event Domain",
      dataIndex: "eventdomain",
      key: "eventdomain",
    },

    {
      title: "",
      dataIndex: "operation",
      fixed: "right",
      render: (text, record, index) => (
        <Popover
          content={
            <div>
              <Link to={`viewzone/${record.zone_id}`}>
                <p className="options">
                  <EyeOutlined /> <span className="">View Details</span>
                </p>
              </Link>
              <Link to={`editzone/${record.zone_id}`}>
                <p className="options">
                  <EditOutlined /> <span className="">Edit Zone</span>
                </p>
              </Link>
              <p className="options">
                <DeleteOutlined /> <span className=""> Delete Zone</span>
              </p>
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      fixed: "right",
      render: (text, record, index) => (
        <div className="px-1">
          <Link to={`editzone/${record.zone_id}`}>
            <p className="options m-0">
              <EditOutlined className="" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    console.log("sorter", sorter);

    const sortObj = {
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    };

    dispatch(getZone(sortObj));
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="fs-24">Zone Management</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
            <Breadcrumb.Item>Zone Management</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>
          <Link to="createnewzone">
            <Button className="rounded px-3 py-1 m-2">
              <span className="fw-bold"> + Create New Zone </span>
            </Button>
          </Link>
        </div>
      </div>

      <div className="bg-white p-4">
        <div className="d-flex justify-content-between mb-4">
          <div className="w-50">
            <Input
              size="medium"
              placeholder="Search Title, Zone ID, Event Domain Name"
              style={{ width: "70%" }}
              prefix={<SearchOutlined />}
              value={searchData}
              onChange={handleInputSearch}
            />
          </div>

          <div className="d-flex">
            <Select
              showSearch
              className="fs-bold-14"
              style={{ width: 200, margin: "3px", fontWeight: "bold" }}
              placeholder="Select Brand"
              optionFilterProp="children"
              onChange={(brandID) => {
                console.log(brandID);
                dispatch(getZone({ brand_id: brandID }));
              }}
            >
              {brandList &&
                brandList.map((data) => (
                  <Option value={data.brand_id}>{data.brand_title}</Option>
                ))}
            </Select>
            <Select
              showSearch
              style={{ width: 200, margin: "3px", fontWeight: "bold" }}
              placeholder="Location on Wall"
              optionFilterProp="children"
              onChange={(wallID) => {
                // console.log(brandID);
                dispatch(getZone({ booth_location_id: wallID }));
              }}
            >
              {/* <Option value="1" Selected>
                Not Identified
              </Option> */}
              <Option value="1">Wall A</Option>
              <Option value="2">Wall B</Option>
              <Option value="3">Wall C</Option>
            </Select>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            total: data.length,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
          }}
          scroll={{
            x: 1300,
          }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </Fragment>
  );
};

export default ZoneManagement;
