import React, { Fragment, useEffect, useState } from "react";
import "./People.css";
import { Table, Button, Input, Select, Row, Col } from "antd";
import { SearchOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { filter, plusSquare } from "../../components/Images/Images";
import {
  itemRender,
  onShowSizeChange
} from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import {
  getZones,
  getBrands,
  getCountryList,
  getJobTitles
} from "../../utils/commonApis";
import { getUser, changeStatus } from "../../redux/slice/userManagementSlice";
import { getPeople } from "../../redux/slice/people";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ApiCall } from "../../utils/api";

const People = () => {
  const [localData, setLocalData] = useState({
    brand_id: null,
    country_id: null,
    zone_id: null,
    user_type: "",
    job_title_id: null
  });

  const [brands, setBrands] = useState([]);
  const [zones, setZones] = useState([]);
  const [countries, setCountries] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [searchData, setSearchData] = useState();

  const { Option } = Select;

  //Redux
  const dispatch = useDispatch();
  const { rows } = useSelector(state => state.people.data);

  //Table related Data
  const columns = [
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"]
    },
    {
      title: "Created D/T",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"]
    },
    {
      title: "Profile",
      dataIndex: "profile",
      // key: "profile",
      render: record =>
        record ? (
          <img src={record} alt="pro pic" className="people_profile_picture" />
        ) : (
          "NA"
        )
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username"
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type"
    },
    {
      title: "Email Address",
      dataIndex: "emailaddress",
      key: "emailaddress"
    },
    {
      title: "Event Domain Name",
      dataIndex: "eventdomainname",
      key: "eventdomainname"
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country"
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <Button
          className={
            record.is_active === true ? "bg-button-green" : "bg-button-red"
          }
          // onClick={() => handleStatus(record.user_id, record.is_active)}
          shape="round"
        >
          {record.is_active === true ? "Active" : "Inactive"}
        </Button>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"]
    }
  ];

  const tableData =
    rows &&
    rows.map(elem => {
      return {
        key: elem?.user_id,
        company: elem?.company,
        user_id: elem?.user_id,
        created_at: moment(elem?.created_at).format("DD-MM-YYYY HH:mm"),
        username: elem?.user_name,
        eventdomainname: elem?.event_domain_name,
        emailaddress: elem?.email,
        profile: elem?.image_url,
        user_type: elem?.user_type,
        tier_name: elem?.tier,
        bio: elem?.bio,
        job_title: elem?.job_title,
        country: elem?.country,
        is_active: elem.is_active
      };
    });

  useEffect(() => {
    const searchObj = {
      search: searchData
    };
    dispatch(getPeople(searchObj));
  }, [searchData]);

  //Constructors
  useEffect(async () => {
    const brandsHere = await getBrands();
    setBrands(brandsHere);

    const zonesHere = await getZones();
    setZones(zonesHere);

    const countriesHere = await getCountryList();
    setCountries(countriesHere);

    const jobTitlesHere = await getJobTitles();
    setJobTitles(jobTitlesHere);
  }, []);

  const handleInputSearch = e => {
    setSearchData(e.target.value);
  };

  const handleFieldsChange = (val, name) => {
    console.log({ ...localData, [name]: val });
    setLocalData({ ...localData, [name]: val });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortObj = {
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC"
    };
    dispatch(getPeople(sortObj));
  };

  const handleApply = () => {
    console.log("finalData", localData);
    dispatch(
      getPeople({
        brand_id: localData.brand_id,
        country_id: localData.country_id,
        zone_id: localData.zone_id,
        job_title_id: localData.job_title_id,
        user_type: localData.user_type
      })
    );
  };

  const handleClear = () => {
    dispatch(getPeople());
    setLocalData({
      brand_id: null,
      country_id: null,
      zone_id: null,
      user_type: "",
      job_title_id: null
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        {console.log("rows", rows)}
        {console.log("localdata", localData)}
        <div>
          <h4 className="mb-1">People</h4>
          <span className="opacity-50">People</span>
        </div>
        <div>
          <Button className="people_inviteBtn">Invite to Attend</Button>
        </div>
      </div>
      <div className="people_container w-100 ">
        <div className="search_container">
          <Row className="mt-4">
            <Col
              span={2}
              className="d-flex justify-content-center align-items-center"
            >
              <span className="opacity-75"></span>
            </Col>
            <Col span={16}>
              <Input
                size="medium"
                value={searchData}
                onChange={handleInputSearch}
                placeholder="Search User name, User ID"
                className="w-100 h-100"
                prefix={<SearchOutlined />}
              />
            </Col>
            <Col span={3} className="d-flex justify-content-center">
              <Button
                onClick={handleApply}
                className="people_applyBtn fs-16"
                type="primary"
              >
                Apply
              </Button>
            </Col>
            <Col span={3}>
              <Button onClick={handleClear} className="people_clearBtn ">
                Clear
              </Button>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col
              span={2}
              className="d-flex justify-content-center align-items-center"
            >
              <span className="opacity-75">Brand</span>
            </Col>
            <Col span={5}>
              <Select
                showSearch
                name="brand"
                bordered={false}
                className="people_selectInput w-100"
                placeholder="Select if required"
                value={localData?.brand_id}
                onChange={val => handleFieldsChange(val, "brand_id")}
              >
                {brands &&
                  brands.map((item, i) => {
                    return (
                      <Option
                        key={item.brand_id + "_brand"}
                        value={item.brand_id}
                      >
                        {item.brand_title}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col
              span={3}
              className="d-flex justify-content-center align-items-center"
            >
              <span className="opacity-75">Country</span>
            </Col>
            <Col span={5}>
              <Select
                showSearch
                name="country"
                bordered={false}
                className="people_selectInput w-100"
                placeholder="Select if required"
                value={localData?.country_id}
                onChange={val => handleFieldsChange(val, "country_id")}
              >
                {countries &&
                  countries.map((item, i) => {
                    return (
                      <Option
                        key={item.brand_id + "_country"}
                        value={item.country_id}
                      >
                        {item.country}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col
              span={3}
              className="d-flex justify-content-center align-items-center"
            >
              <span className="opacity-75">Brand Sessions(s)</span>
            </Col>
            <Col span={5}>
              <Select
                showSearch
                name="brand_session"
                bordered={false}
                className="people_selectInput w-100"
                placeholder="Select if required"
                //   onChange={(val) =>
                //     handleTickerConfigFieldsChange(
                //       val,
                //       "tickerConfig_ticker_picker"
                //     )
                //   }
              >
                <Option value="Microsoft">Microsoft</Option>
              </Select>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col
              span={2}
              className="d-flex justify-content-center align-items-center"
            >
              <span className="opacity-75">Zone</span>
            </Col>
            <Col span={5}>
              <Select
                showSearch
                name="zone"
                bordered={false}
                className="people_selectInput w-100"
                placeholder="Select if required"
                value={localData?.country_id}
                onChange={val => handleFieldsChange(val, "zone_id")}
              >
                {zones &&
                  zones.map((item, i) => {
                    return (
                      <Option
                        key={item.brand_id + "_zone"}
                        value={item.zone_id}
                      >
                        {item.zone_title}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col
              span={3}
              className="d-flex justify-content-center align-items-center"
            >
              <span className="opacity-75">Job Title</span>
            </Col>
            <Col span={5}>
              <Select
                showSearch
                name="job_title"
                bordered={false}
                className="people_selectInput w-100"
                placeholder="Select if required"
                value={localData?.job_title_id}
                onChange={val => handleFieldsChange(val, "job_title_id")}
              >
                {jobTitles &&
                  jobTitles.map((item, i) => {
                    return (
                      <Option
                        key={item.job_title_id + "_jobtitle"}
                        value={item.job_title_id}
                      >
                        {item.job_title}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col
              span={3}
              className="d-flex justify-content-center align-items-center"
            >
              <span className="opacity-75">Booth Zone Visited</span>
            </Col>
            <Col span={5}>
              <Select
                showSearch
                name="brand_zone"
                bordered={false}
                className="people_selectInput w-100"
                placeholder="Select if required"
                //   onChange={(val) =>
                //     handleTickerConfigFieldsChange(
                //       val,
                //       "tickerConfig_ticker_picker"
                //     )
                //   }
              >
                <Option value="Microsoft">Microsoft</Option>
              </Select>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col
              span={2}
              className="d-flex justify-content-center align-items-center"
            >
              <span className="opacity-75">User Type</span>
            </Col>
            <Col span={5}>
              <Select
                showSearch
                name="user_type"
                bordered={false}
                className="people_selectInput w-100"
                placeholder="Select if required"
                value={
                  localData?.user_type
                    ? localData?.user_type
                    : "Select if required"
                }
                onChange={val => handleFieldsChange(val, "user_type")}
              >
                <Option value="CUSTOMER">CUSTOMER</Option>
                <Option value="DELEGATE">DELEGATE</Option>
                <Option value="SUPER ADMIN">SUPER ADMIN</Option>
                <Option value="AGENT">AGENT</Option>
              </Select>
            </Col>
            <Col
              span={3}
              className="d-flex justify-content-center align-items-center"
            >
              <span className="opacity-75">Brand Booth Visited</span>
            </Col>
            <Col span={5}>
              <Select
                showSearch
                name="brand_booth"
                bordered={false}
                className="people_selectInput w-100"
                placeholder="Select if required"
                //   onChange={(val) =>
                //     handleTickerConfigFieldsChange(
                //       val,
                //       "tickerConfig_ticker_picker"
                //     )
                //   }
              >
                <Option value="Microsoft">Microsoft</Option>
              </Select>
            </Col>
            <Col
              span={3}
              className="d-flex justify-content-center align-items-center"
            >
              <span className="opacity-75">Zone Session</span>
            </Col>
            <Col span={5}>
              <Select
                showSearch
                name="zone_session"
                bordered={false}
                className="people_selectInput w-100"
                placeholder="Select if required"
                //   onChange={(val) =>
                //     handleTickerConfigFieldsChange(
                //       val,
                //       "tickerConfig_ticker_picker"
                //     )
                //   }
              >
                <Option value="Microsoft">Microsoft</Option>
              </Select>
            </Col>
          </Row>
          <div className="bg-white">
            <Table
              className="people_table"
              columns={columns}
              dataSource={tableData}
              className="px-4 mt-4"
              rowkey={record => record.key}
              pagination={{
                // total: data.length,
                showSizeChanger: true,
                onShowSizeChange: onShowSizeChange
              }}
              bordered={false}
              // showSizeChange={true}
              expandIconColumnIndex={11}
              expandable={{
                expandedRowRender: record => (
                  <div className="w-100 d-flex">
                    <img
                      class="img-responsive  mx-3"
                      src={record.profile}
                      alt="Chania"
                      width="249"
                      height="248"
                    />
                    <div className="w-100">
                      <div className="people_right_container w-100 d-flex flex-column ">
                        <div className="w-100 d-flex justify-content-between">
                          <div className="people_block d-flex flex-column w-100">
                            <span className="opacity-75">User Type</span>
                            <span className="">{record.user_type}</span>
                          </div>
                          <div className="people_block d-flex flex-column w-100">
                            <span className="opacity-75">Tier</span>
                            <span>{record.tier_name}</span>
                          </div>
                          <div className="people_block d-flex flex-column w-100">
                            <span className="opacity-75">Company</span>
                            <span>{record.company}</span>
                          </div>
                          <div className="people_block d-flex flex-column w-100">
                            <span className="opacity-75">Job Role</span>
                            <span>{record.job_title}</span>
                          </div>
                          <div className="people_block d-flex flex-column w-100">
                            <span className="opacity-75">Country</span>
                            <span>{record.country}</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex justify-content-start mt-2">
                          <p className="opacity-75">Bio</p>
                          <p className="mx-3 people_bio">{record?.bio}</p>
                        </div>
                        <div className="mx-4">
                          <Button className="people_chatBtn mx-2">Chat</Button>
                          <Button className="people_videoBtn mx-4">
                            Video Call
                          </Button>
                          <Button className="people_bookMeetingBtn">
                            Book a Meeting
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }}
              expandRowByClick
              expandIcon={({ expanded, onExpand, record }) =>
                expanded ? (
                  <Button
                    className="people_dropdown_upBtn border-0"
                    onClick={e => onExpand(record, e)}
                  >
                    <UpOutlined />
                  </Button>
                ) : (
                  <Button
                    className="people_dropdown_downBtn border-0"
                    onClick={e => onExpand(record, e)}
                  >
                    <DownOutlined />
                  </Button>
                )
              }
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default People;
