import React, { useState, useEffect } from "react";
import { Button, Steps, Breadcrumb, Spin, Result } from "antd";
import "./event.css";
import { ApiCall } from "../../utils/api";
import EventEditor from "./EditEventDetails/EventEditor";
import RegistrationAndCommunication from "./EditEventDetails/RegistrationAndCommunication";
import Tiers from "./EditEventDetails/Tiers";
import NavigationAndReception from "./EditEventDetails/NavigationAndReception";
import SocialWall from "./EditEventDetails/SocialWall";
import {
  updateEvent,
  updateNavigationAndReceptionEvent,
  updateRegisterEvent,
  updateSocialWallEvent,
  updateTierEvent,
} from "../../redux/slice/updateEvent";
import {
  createEvent,
  navigationAndReceptionEvent,
  registerEvent,
  socialWallEvent,
  tierEvent,
} from "../../redux/slice/createEvent";
import { useDispatch, useSelector } from "react-redux";
import _, { cloneDeep, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { clearEventData, storeEventData } from "../../redux/slice/editEvent.js";
import {
  mapEventEditorSchema,
  mapRegisterationSchema,
  mapRelationsToRegistrationSchema,
  mapDefaultTiersToSchema,
  mapTiersSchema,
  mapNavigationReceptionSchema,
  mapSocialWallSchema,
} from "./Event.utils";
import {
  getNavigationSettingsType,
  getSocialMediaLinks,
  getTiers,
} from "../../utils/commonApis";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { dialogs } from "../../components/constsnts/string.constants";

function EditEvent(props) {
  const { match } = props;
  const { Step } = Steps;
  var CryptoJS = require("crypto-js");
  const dispatch = useDispatch();
  let history = useHistory();
  const [eventIdInState, setEventIdInState] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [eventEditorData, setEventEditorData] = useState({});
  const [eventEditorValid, setEventEditorValid] = useState(false);
  const [registerationData, setRegisterationData] = useState({});
  const [registerValid, setRegisterValid] = useState(false);
  const [tiersData, setTiersData] = useState({});
  const [validTiers, setValidTiers] = useState(false);
  const [navReceptionData, setNavReceptionData] = useState({});
  const [navValid, setNavValid] = useState(false);
  const [socialWallData, setSocialWallData] = useState();
  const [socialValid, setSocialValid] = useState(false);
  const { loading } = useSelector((state) => state.updateEvent);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const editEventDataRes = useSelector((state) => state.editEventData);
  const [ckeditor1Data, setEditor1Data] = useState("<p></p>");
  const [ckeditor2Data, setEditor2Data] = useState("<p></p>");
  const [ckeditor3Data, setEditor3Data] = useState("<p></p>");
  const [loader, setLoader] = useState(true);
  const [isNoEventDataFound, setIsNoEventDataFound] = useState(false);

  useEffect(() => {
    if (match?.params?.event_id) getEventData(match?.params?.event_id, false);
    else getDefaultSchemas();

    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach(element => {
      if (element.route == window.location.pathname) {
        is_route_present = true
      }
    });

    if (is_route_present === false && decryptedData === "AGENT" || decryptedData === "DELEGATE" || decryptedData === "BRAND ADMIN") {
      window.location.href = '/pagenotfound'
    }
  }, []);

  function setLoaderOnDemand(value) {
    setLoader(value);
  }

  const getDefaultSchemas = async () => {
    //event editor
    const newEventEditorData = mapEventEditorSchema({});
    setEventEditorData(newEventEditorData);

    //registration
    const newSetRegisterationData = mapRegisterationSchema({});
    setEditor1Data(newSetRegisterationData.term_description_1);
    setEditor2Data(newSetRegisterationData.term_description_2);
    setEditor3Data(newSetRegisterationData.term_description_3);
    setRegisterationData(newSetRegisterationData);

    //tiers data
    const tiersDefaultAPIData = await getTiers();
    const newSetTiersData = mapDefaultTiersToSchema(tiersDefaultAPIData);
    setTiersData({ ...newSetTiersData, event_id: "" });

    //navigation & reception
    const settingTypesCollectionData = await getNavigationSettingsType();
    const mediaLinks = await getSocialMediaLinks();
    const newNavReceptionData = await mapNavigationReceptionSchema(
      {},
      settingTypesCollectionData,
      mediaLinks
    );

    console.log("newNavReceptionData in create = ", newNavReceptionData);
    setNavReceptionData({ ...newNavReceptionData, event_id: "" });
    const newSocialWallData = await mapSocialWallSchema({}, mediaLinks);
    setSocialWallData({ ...newSocialWallData, event_id: "" });
    setEventIdInState("");
    setLoader(false);
  };

  const getEventData = async (event_id, isAfterCreateNewEvent = false) => {
    console.log("event_id in get event id = ", event_id);
    const response = await ApiCall(
      "GET",
      "",
      `/admin/events/details/get?event_id=${event_id ?? ""}`,
      ""
    );

    if (response?.data?.code === 200) {
      if (response?.data?.data) {
        console.log("result in view event", response.data.data);
        await dispatch(
          storeEventData({
            data: response.data.data,
            keyToUpdate: "main",
          })
        );

        //event editor
        const newEventEditorData = mapEventEditorSchema(
          response.data.data.editor_details
        );
        setEventEditorData(newEventEditorData);

        //registration
        const newSetRegisterationData = mapRegisterationSchema(
          response.data.data.registration_and_communication_details
        );

        setEditor1Data(newSetRegisterationData.term_description_1);
        setEditor2Data(newSetRegisterationData.term_description_2);
        setEditor3Data(newSetRegisterationData.term_description_3);
        setRegisterationData(newSetRegisterationData);

        //tiers data
        const tiersDefaultAPIData = await getTiers();

        if (isEmpty(response.data.data.tier_details)) {
          const newSetTiersData = mapDefaultTiersToSchema(tiersDefaultAPIData);
          setTiersData({
            ...newSetTiersData,
            event_id: response.data.data.event_id,
          });
        } else {
          // console.log("tiers data present hai");
          const newSetTiersData = mapTiersSchema(
            tiersDefaultAPIData,
            response.data.data.tier_details
          );

          setTiersData({
            ...newSetTiersData,
            event_id: response.data.data.event_id,
          });
        }

        //navigation & reception
        const settingTypesCollectionData = await getNavigationSettingsType();
        const mediaLinks = await getSocialMediaLinks();
        const newNavReceptionData = await mapNavigationReceptionSchema(
          response.data.data.navigation_plus_reception_details,
          settingTypesCollectionData,
          mediaLinks
        );

        setNavReceptionData({
          ...newNavReceptionData,
          event_id: response.data.data.event_id,
        });

        const newSocialWallData = await mapSocialWallSchema(
          response.data.data.social_wall_details,
          mediaLinks
        );
        setSocialWallData({
          ...newSocialWallData,
          event_id: response.data.data.event_id,
        });

        setEventIdInState(response.data.data.event_id);
        if (isAfterCreateNewEvent) {
          setCurrentStep(currentStep + 1);
        }
        setLoader(false);
      } else setLoader(false);
    } else {
      console.log("in error getEventData,response = ", response);
      if (response.error && response.error === "Network Error")
        toast.error("Please check the internet connection");
      if (response?.data?.message) {
        if (response.data.message === "Something went wrong")
          setIsNoEventDataFound(true);
        toast.error(response.data.message);
      }
      setLoader(false);
    }
  };

  const validEventEditor = (val) => {
    console.log("validate k pehle validEventEditor.....", val);
    setEventEditorValid(false);
    if (val) {
      setLoader(true);
      let clonedEditorData = cloneDeep(eventEditorData);
      clonedEditorData.events_organizer_brand_id = undefined;
      clonedEditorData.customer = undefined;
      clonedEditorData.survey = undefined;
      clonedEditorData = JSON.parse(JSON.stringify(clonedEditorData));
      updateEventEditor(clonedEditorData);
    }
  };

  const updateEventEditor = async (dataToUpdate) => {
    let formData = new FormData();
    console.log("json data to update", JSON.stringify(dataToUpdate));
    formData.append("event_logo_file", eventEditorData?.event_logo_file);
    formData.append("data", JSON.stringify(dataToUpdate));
    formData.append(
      "pre_event_message_media_file",
      eventEditorData?.pre_event_message_media_file
    );
    formData.append(
      "welcome_message_media_file",
      eventEditorData?.welcome_message_media_file
    );
    formData.append(
      "controls_help_media_file",
      eventEditorData?.controls_help_media_file
    );

    formData.append("expo_hall_url", eventEditorData?.expo_hall_url);
    formData.append("main_audi_url", eventEditorData?.main_audi_url);
    formData.append("sat_audi_url", eventEditorData?.sat_audi_url);
    formData.append("net_lounge_url", eventEditorData?.net_lounge_url);
    formData.append(
      "concourse_lounge_url",
      eventEditorData?.concourse_lounge_url
    );

    console.log("eventIdInState first step  = ", eventIdInState);

    if (eventIdInState) {
      const updateEventResponse = await dispatch(updateEvent(formData));
      console.log("event component response update event editor");
      if (updateEventResponse?.payload?.data?.code === 200) {
        console.log("in 200 update event editor", updateEventResponse);
        toast.success(updateEventResponse?.payload?.data?.message);

        console.log("data to update event editor ", dataToUpdate);

        await dispatch(
          storeEventData({
            ...{
              data: dataToUpdate,
              keyToUpdate: "editor_details",
            },
            ...{
              data: updateEventResponse.payload?.data?.data?.event_id,
              keyToUpdate: "event_id",
            },
          })
        );

        setCurrentStep(currentStep + 1);
        setLoader(false);
        setEventIdInState(updateEventResponse.payload?.data?.data?.event_id);
      } else {
        console.log("in error");
        setLoader(false);
        if (updateEventResponse?.payload?.data?.message)
          toast.error(updateEventResponse.payload.data.message);
      }
    } else {
      const createEventResponse = await dispatch(createEvent(formData));
      console.log("event component response create event editor");
      if (createEventResponse?.payload?.data?.code === 200) {
        console.log("in 200 create event editor", createEventResponse);
        toast.success(createEventResponse?.payload?.data?.message);
        if (createEventResponse?.payload?.data?.data?.event_id) {
          await dispatch(
            storeEventData({
              ...{
                data: dataToUpdate,
                keyToUpdate: "editor_details",
              },
              ...{
                data: createEventResponse.payload?.data?.data?.event_id,
                keyToUpdate: "event_id",
              },
            })
          );
          setEventIdInState(createEventResponse?.payload?.data?.data?.event_id);
          setLoader(true);
          getEventData(
            createEventResponse?.payload?.data?.data?.event_id,
            true
          );
        }
      } else {
        setLoader(false);
        toast.error(createEventResponse?.payload?.data?.message);
      }
    }
  };

  const registerValidation = (val) => {
    console.log("validate k pehle registerValidation.....", val);
    setRegisterValid(false);
    if (val) {
      console.log("valid me hai registerationData ------>", registerationData);
      let dataToUpdate = cloneDeep(registerationData);
      let finalDataToUpdate = mapRegisterationSchema(
        dataToUpdate,
        editEventDataRes
      );
      finalDataToUpdate = mapRelationsToRegistrationSchema(finalDataToUpdate);
      // finalDataToUpdate = mapCkEditorDataToSchema(finalDataToUpdate);
      console.log("valid me hai finalDataToUpdate........", finalDataToUpdate);
      setLoader(true);
      updateRegisterForm({
        ...finalDataToUpdate,
        ...{
          term_description_1: ckeditor1Data,
          term_description_2: ckeditor2Data,
          term_description_3: ckeditor3Data,
          event_id: eventIdInState,
        },
      });
    } else console.log("not valid me hai registerValidation......", val);
  };

  const updateRegisterForm = async (dataToUpdate) => {
    console.log("dataToUpdate = ", JSON.stringify(dataToUpdate));
    if (dataToUpdate.registration_settings_id) {
      const updateRegisterEventResponse = await dispatch(
        updateRegisterEvent(dataToUpdate)
      );
      console.log("update register response", updateRegisterEventResponse);
      if (updateRegisterEventResponse?.payload?.data?.code === 200) {
        await dispatch(
          storeEventData({
            data: dataToUpdate,
            keyToUpdate: "registration_and_communication_details",
          })
        );

        toast.success(updateRegisterEventResponse.payload.data.message);
        setCurrentStep(currentStep + 1);
        setLoader(false);
      } else {
        console.log("in error");
        setLoader(false);
        if (updateRegisterEventResponse.payload?.data?.message)
          toast.error(updateRegisterEventResponse.payload.data.message);
      }
    } else {
      const registerEventResponse = await dispatch(registerEvent(dataToUpdate));
      console.log("create register response 1111", registerEventResponse);

      if (registerEventResponse?.payload?.data?.code === 200) {
        console.log("in 200 create register", registerEventResponse);
        const allData = {
          ...registerEventResponse?.payload?.data?.data,
          ...dataToUpdate,
        };
        await dispatch(
          storeEventData({
            data: allData,
            keyToUpdate: "registration_and_communication_details",
          })
        );

        toast.success(registerEventResponse.payload.data.message);
        setCurrentStep(currentStep + 1);
        setLoader(false);
      } else {
        setLoader(false);
        if (registerEventResponse?.payload?.data?.message)
          toast.error(registerEventResponse.payload.data.message);
      }
    }
  };

  const tiersValid = (val) => {
    console.log("validate k pehle in tiersValid .....", val);
    setValidTiers(false);
    if (val) {
      console.log("valid me hai........ tiersValid tiersData", tiersData);
      setLoader(true);
      const tiersDataSelected = cloneDeep(tiersData);
      updateTierForm(tiersDataSelected);
    }
  };

  const updateTierForm = async (dataToUpdate) => {
    console.log("dataToUpdate updateTierForm", dataToUpdate);
    console.log(
      "json dataToUpdate updateTierForm = ",
      JSON.stringify(dataToUpdate)
    );

    let tier_settings_id_present = dataToUpdate.event_tier_details.filter(
      (item) => item.tier_settings_id
    );

    console.log("tier_settings_id_present = ", tier_settings_id_present);

    if (!isEmpty(tier_settings_id_present)) {
      console.log("event tiers update");
      const updateTierEventResponse = await dispatch(
        updateTierEvent(dataToUpdate)
      );
      console.log("event component response", updateTierEventResponse);
      if (updateTierEventResponse?.payload?.data?.code === 200) {
        console.log("in 200", updateTierEventResponse);
        await dispatch(
          storeEventData({
            data: dataToUpdate,
            keyToUpdate: "tier_details",
          })
        );
        toast.success(updateTierEventResponse.payload.data.message);
        setCurrentStep(currentStep + 1);
        setLoader(false);
      } else {
        setLoader(false);
        if (updateTierEventResponse?.payload?.data?.message)
          toast.error(updateTierEventResponse.payload.data.message);
      }
    } else {
      console.log("event tiers create");
      const tierEventResponse = await dispatch(tierEvent(dataToUpdate));
      console.log("event component response tier", tierEventResponse);
      if (tierEventResponse?.payload?.data?.code === 200) {
        console.log("in 200 tier create", tierEventResponse);
        const allData = {
          ...tierEventResponse?.payload?.data?.data,
          ...dataToUpdate,
        };
        await dispatch(
          storeEventData({
            data: allData,
            keyToUpdate: "tier_details",
          })
        );
        toast.success(tierEventResponse?.payload?.data?.message);
        setCurrentStep(currentStep + 1);
        setLoader(false);
      } else {
        setLoader(false);
        if (tierEventResponse?.payload?.data?.message)
          toast.error(tierEventResponse?.payload.data.message);
      }
    }
  };

  const navReceptionValidation = (val) => {
    setNavValid(false);
    if (val) {
      console.log(
        "valid me hai........ navReceptionValidation navReceptionData",
        navReceptionData
      );
      let clonedNavReceptionData = cloneDeep(navReceptionData);
      clonedNavReceptionData.event_assets_settings = undefined;
      clonedNavReceptionData = JSON.parse(
        JSON.stringify(clonedNavReceptionData)
      );
      console.log("clonedNavReceptionData..11", clonedNavReceptionData);
      setLoader(true);
      updateNavigationReceptionForm(clonedNavReceptionData);
    } else {
      console.log("not valid me hai navReceptionValidation......", val);
    }
  };

  const updateNavigationReceptionForm = async (dataToUpdate) => {
    console.log("navigation evnet data", dataToUpdate);
    console.log("json data", JSON.stringify(dataToUpdate));
    let formData = new FormData();
    formData.append("data", JSON.stringify(dataToUpdate));

    if (dataToUpdate.navigation_main_settings[0].navigation_main_setting_id) {
      const navigationReceptionResponse = await dispatch(
        updateNavigationAndReceptionEvent(formData)
      );
      console.log(
        "event component response navi 11",
        navigationReceptionResponse
      );
      if (navigationReceptionResponse?.payload?.data?.code === 200) {
        console.log("in 200 update reception", navigationReceptionResponse);
        await dispatch(
          storeEventData({
            data: dataToUpdate,
            keyToUpdate: "navigation_plus_reception_details",
          })
        );

        toast.success(navigationReceptionResponse.payload.data.message);
        setCurrentStep(currentStep + 1);
        setLoader(false);
      } else {
        setLoader(false);
        if (navigationReceptionResponse?.payload?.data?.message)
          toast.error(navigationReceptionResponse.payload.data.message);
      }
    } else {
      const navigationReceptionResponse = await dispatch(
        navigationAndReceptionEvent(formData)
      );
      console.log(
        "event component response navi 222 ",
        navigationReceptionResponse
      );
      if (navigationReceptionResponse?.payload?.data?.code === 200) {
        console.log("in 200 create reception", navigationReceptionResponse);

        const allData = {
          ...navigationReceptionResponse?.payload?.data?.data,
          ...dataToUpdate,
        };

        await dispatch(
          storeEventData({
            data: allData,
            keyToUpdate: "navigation_plus_reception_details",
          })
        );

        toast.success(navigationReceptionResponse?.payload?.data?.message);
        setCurrentStep(currentStep + 1);
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(navigationReceptionResponse?.payload?.data?.message);
      }
    }
  };

  const socialWallValid = (val) => {
    setSocialValid(false);
    if (val) {
      console.log("social valid me hai........", val);
      setLoader(true);
      updateSocialWall(socialWallData);
    } else console.log("not valid me hai ......", val);
  };

  const updateSocialWall = async (dataToUpdate) => {
    console.log("updateSocialWall updateSocialWall data", dataToUpdate);
    console.log(
      "dataToUpdate updateSocialWall json",
      JSON.stringify(dataToUpdate)
    );

    if (dataToUpdate.social_settings_id) {
      const updateSocialWallResponse = await dispatch(
        updateSocialWallEvent(dataToUpdate)
      );
      console.log("update social response", updateSocialWallResponse);
      if (updateSocialWallResponse?.payload?.data?.code === 200) {
        console.log("in 200 update social", updateSocialWallResponse);
        await dispatch(clearEventData());
        history.push("/eventmanagement");
        setLoader(false);
        toast.success(updateSocialWallResponse.payload.data.message);
      } else {
        setLoader(false);
        if (updateSocialWallResponse?.payload?.data?.message)
          toast.error(updateSocialWallResponse.payload.data.message);
      }
    } else {
      const createResponse = await dispatch(socialWallEvent(dataToUpdate));
      console.log("create social response", createResponse);
      if (createResponse?.payload?.data?.code === 200) {
        console.log("in 200 create social", createResponse);
        await dispatch(clearEventData());
        history.push("/eventmanagement");
        setLoader(false);
        toast.success(dialogs.event.eventUpdateSuccess);
      } else {
        setLoader(false);
        if (createResponse?.payload?.data?.message)
          toast.error(createResponse.payload.data.message);
      }
    }
  };

  const steps = [
    {
      title: "Event Editor",
      content: !_.isEmpty(eventEditorData) ? (
        <EventEditor
          data={{
            ...eventEditorData,
            ...{ event_id: editEventDataRes.editEventData.event_id },
          }}
          onChangeData={(dataHere, parentKey) => {
            let clonedData = cloneDeep(eventEditorData);
            if (!isEmpty(parentKey)) {
              clonedData[parentKey] = { ...clonedData[parentKey], ...dataHere };
              setEventEditorData(clonedData);
            } else setEventEditorData({ ...clonedData, ...dataHere });
          }}
          onNavigateToRegistration={() => setCurrentStep(currentStep + 1)}
          onNavigateToReception={() => setCurrentStep(currentStep + 3)}
          onNavigateToTier={() => setCurrentStep(currentStep + 2)}
          onNavigateToSocialWall={() => setCurrentStep(currentStep + 4)}
          onValid={(val) => validEventEditor(val)}
          initiateValidations={eventEditorValid}
          setLoaderOnDemand={(val) => setLoaderOnDemand(val)}
        />
      ) : null,
    },
    {
      title: "Registration and Communication",
      content: !_.isEmpty(registerationData) ? (
        <RegistrationAndCommunication
          registerData={{
            ...registerationData,
            ...{ event_id: editEventDataRes.editEventData.event_id },
          }}
          ckeditor1Data={ckeditor1Data}
          ckeditor2Data={ckeditor2Data}
          ckeditor3Data={ckeditor3Data}
          onChangeCKEditorData={(key, value) => {
            if (key === "term_description_1") setEditor1Data(value);
            if (key === "term_description_2") setEditor2Data(value);
            if (key === "term_description_3") setEditor3Data(value);
          }}
          onChangeData={(dataHere) => {
            let oldData = cloneDeep(registerationData);
            setRegisterationData({ ...oldData, ...dataHere });
          }}
          onValid={(val) => registerValidation(val)}
          initiateValidations={registerValid}
        />
      ) : null,
    },
    {
      title: "Tiers",
      content: (
        <Tiers
          tiersData={{
            ...tiersData,
            ...{ event_id: editEventDataRes.editEventData.event_id },
          }}
          onChangeData={(data) => {
            console.log("data1 tiers received =  ", data);
            let clonedTiers = cloneDeep(tiersData);
            clonedTiers.event_tier_details = data;
            setTiersData(clonedTiers);
          }}
          onValid={(val) => tiersValid(val)}
          initiateValidations={validTiers}
        />
      ),
    },
    {
      title: "Navigation + Reception",
      content: (
        <NavigationAndReception
          navReceptionData={{
            ...navReceptionData,
            ...{ event_id: editEventDataRes.editEventData.event_id },
          }}
          onChangeData={(dataHere, parentKey) => {
            let clonedData = cloneDeep(navReceptionData);
            if (!isEmpty(parentKey)) {
              clonedData[parentKey] = { ...clonedData[parentKey], ...dataHere };
              setNavReceptionData(clonedData);
            } else setNavReceptionData({ ...clonedData, ...dataHere });
          }}
          onValid={(val) => {
            console.log("valin nav--->", val);
            navReceptionValidation(val);
          }}
          initiateValidations={navValid}
        />
      ),
    },
    {
      title: "Social Wall",
      content: (
        <SocialWall
          socialWallData={{
            ...socialWallData,
            ...{ event_id: editEventDataRes.editEventData.event_id },
          }}
          onChangeData={(dataHere, parentKey) => {
            let clonedData = cloneDeep(socialWallData);
            if (!isEmpty(parentKey)) {
              clonedData[parentKey] = { ...clonedData[parentKey], ...dataHere };
              setSocialWallData(clonedData);
            } else setSocialWallData({ ...clonedData, ...dataHere });
          }}
          onValid={(val) => socialWallValid(val)}
          initiateValidations={socialValid}
        />
      ),
    },
  ];

  const next = () => {
    if (currentStep === 0) {
      setEventEditorValid(true);
    } else if (currentStep === 1) {
      setRegisterValid(true);
    } else if (currentStep === 2) {
      setValidTiers(true);
    } else if (currentStep === 3) {
      setNavValid(true);
    }
  };

  const onChange = (current) => {
    if (current === 0) {
      console.log("step on change 0");
      if (match?.params?.event_id) getEventData(match?.params?.event_id, false);
      setCurrentStep(current);
    } else {
      if (eventIdInState) setCurrentStep(current);
      else toast.error("Please complete the first step");
    }
  };

  if (loader) return <CommonLoader />;
  else if (isNoEventDataFound)
    return (
      <Result
        icon={<SmileOutlined />}
        title="Event Data not found, please try again later"
      />
    );
  else {
    let title =
      match && match.params && match.params.event_id
        ? "Edit Event"
        : "Create Event";
    return (
      <div className="site-layout-background">
        <div className="customer-management-form">
          <div className="background-color-gray">
            <h4 className="fs-24">{title}</h4>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/eventmanagement">Admin Console</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/eventmanagement">Event Management</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{title}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Steps
            type="navigation"
            current={currentStep}
            onChange={onChange}
            className="site-navigation-steps"
          >
            {steps.map((item, stepIndex) => (
              <Step
                key={item.title}
                disabled={loader}
                status={"process"}
                className="event_steps"
                title={
                  // item.title
                  <span
                    className={
                      currentStep === stepIndex
                        ? "custom_step_text_selected"
                        : "custom_step_text"
                    }
                  >
                    {item.title}
                  </span>
                }
                stepNumber={stepIndex + 1}
              // icon={
              //   currentStep === stepIndex ?
              //   <div
              //     style={{
              //       backgroundColor: "#EF0855",
              //       color: "white",
              //       width: 30,
              //       height: 30,
              //       margin: "auto",
              //       borderRadius: 15
              //     }}
              //   >
              //     {stepIndex+1}
              //   </div>
              //   :
              //   <span style={{color:'black'}}>{stepIndex+1}</span>
              // }
              />
            ))}
          </Steps>
          <div className="px-4 bg-white">
            <div className="steps-content">{steps[currentStep].content}</div>
            <div
              className="steps-action py-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "stretch",
                marginTop: 30,
              }}
            >
              <div className="d-flex">
                {currentStep > 0 && (
                  <>
                    <Button
                      style={{ margin: "0 8px" }}
                      onClick={() => setCurrentStep(currentStep - 1)}
                    >
                      Previous
                    </Button>
                  </>
                )}
                <Link
                  to="/eventmanagement"
                  className="d-flex"
                  style={{ margin: "0 8px" }}
                >
                  <Button className="px-3 d-block button-pink">Cancel</Button>
                </Link>
              </div>
              {currentStep < steps.length - 1 && (
                <Button
                  onClick={() => next()}
                  type="primary"
                  disabled={loading}
                >
                  {!loading ? (
                    "Save & Next" //aditya populate  yes no handle for brand
                  ) : (
                    <div style={{ width: "70px" }}>
                      <Spin
                        style={{ color: "white" }}
                        indicator={loadingIcon}
                      />
                    </div>
                  )}
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => {
                    if (currentStep === 4) {
                      setSocialValid(true);
                    }
                  }}
                >
                  Save Event
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditEvent;
