import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./ProductZoneSession.css";
import { Row, Col, Divider } from "antd";
import { TimePicker, DatePicker } from "antd";
import moment from "moment";
import { Modal, Button, Form, Input, Switch, InputNumber, Empty } from "antd";
import {
  DownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  blackbin,
  close,
  eyeViewWhite,
  eyeView,
  cloudComputing,
} from "../../../components/Images/Images";
import { Select } from "antd";
import FileUpload from "../../common/FileUpload";
import SimpleReactValidator from "simple-react-validator";
import {
  getZoneSessionDetails,
  updateZoneSessionDetails,
  createZoneSession,
} from "./../../../redux/slice/productZoneSession";
import {
  getJobTitles,
  getAgents,
  getBrands,
  getCountryList,
  getUsers,
  getZones,
} from "./../../../utils/commonApis";
import _, { cloneDeep } from "lodash";
import { AddGroup } from "../../../components/svgJS/AddGroup";
import { EyeView } from "../../../components/svgJS/EyeView";
import { Formik } from "formik";
import * as Yup from "yup";
import { CloseOutlined } from "@ant-design/icons";
import { ApiCall } from "../../../utils/api";
import {
  CalenderGrey,
  TimeCalendar,
} from "../../../components/svgJS/CalenderRed";
import { toast } from "react-toastify";

const ProductZoneSessionCreate = (appointmentData) => {
  console.log("appointmentData Zone Session", appointmentData);
  const dispatch = useDispatch();
  const [productZoneSession, setProductZoneSessionVisible] = useState(true);
  const format = "HH:mm";
  const { TextArea } = Input;
  const { Option } = Select;

  const [modal, setModalInfo] = useState(null);

  //Validations
  const productzonesession_validator = useRef(new SimpleReactValidator());
  const [productZoneSessionForceUpdate, setProductZoneSessionForceUpdate] =
    useState(false);

  const [agentsList, setAgentsList] = useState([]);
  // console.log("agentsList", agentsList);
  const [taggedAgents, setTaggedAgents] = useState([]);
  // console.log('taggedAgents', taggedAgents)

  const [usersList, setUsersList] = useState([]);
  const [selectedusersList, setselecetedUsersList] = useState([]);

  const [whiteListUsers, setWhiteListUsers] = useState([]);
  const [blackListUsers, setBlackListUsers] = useState([]);

  const [brandsList, setBrandsList] = useState([]);
  const [selectedbrandsList, setselectedBrandsList] = useState([]);
  const [whiteListBrands, setWhiteListBrands] = useState([]);
  const [blackListBrands, setBlackListBrands] = useState([]);

  const [countriesList, setCountriesList] = useState([]);
  const [selectedcountriesList, setselectedCountriesList] = useState([]);
  const [whiteListCountries, setWhiteListCountries] = useState([]);
  const [blackListCountries, setBlackListCountries] = useState([]);

  const [jobTitlesList, setJobTitlesList] = useState([]);
  const [selectedjobTitlesList, setselectedJobTitlesList] = useState([]);
  const [whiteListJobTitles, setWhiteListJobTitles] = useState([]);
  const [blackListJobTitles, setBlackListJobTitles] = useState([]);

  const [zoneList, setzoneList] = useState([]);

  const [productZoneSessionFields, setProductZoneSessionFields] = useState({
    productZoneSession_start_date_time: "",
    productZoneSession_end_date_time: "",
    productZoneSession_title: "",
    productZoneSession_description: "",
    is_ical_download_enabled: false,

    productZoneSession_specific_users: "",
    productZoneSession_brand_list: "",
    productZoneSession_country_list: "",
    productZoneSession_job_titles_list: "",

    productZoneSession_invitees_brand: "",
    productZoneSession_invitees_zone: "",
    productZoneSession_invitees_user_type: "",
    productZoneSession_invitees_country: "",
    productZoneSession_invitees_job_title: "",

    productZoneSession_prev_pending_date_time_1: "",
    productZoneSession_prev_pending_date_time_2: "",
    productZoneSession_invitation_title: "",
    productZoneSession_invitation_description: "",
    productZoneSession_invitation_is_ical_download_enabled: false,
    productZoneSession_invitation_re_invite: false,
    productZoneSession_schedule_invitation: false,
    productZoneSession_when_to_send_date_time: "",
  });

  const [initial_state, setinitial_state] = useState({
    brand_id: null,
    zone_id: null,
    start_time: moment(),
    end_time: moment(),
    session_title: "",
    session_description: "",
    is_ical_download_enabled: false,

    assign_staff_ids: [1, 2],
    restricted_users: [1, 2],
    restricted_brands: [1, 2],
    restricted_countries: [1, 2],
    restricted_job_titles: [1, 2],

    invitation_title: "",
    invitation_description: "",
    is_iCal_download_enabled__invite: false,
    is_reinvite_enabled: false,
    send_invitation_now: false,
    date_time_to_invite: moment(),

    invited_brands: [],
    invited_zones: [],
    invited_user_types: [],
    invited_countries: [],
    invited_job_titles: [],
  });

  useEffect(async () => {
    let jobTitlesList = await getJobTitles({
      event_id: appointmentData.extraProps.eventId,
    });
    let countriesList = await getCountryList({
      event_id: appointmentData.extraProps.eventId,
    });
    let agentsList = await getAgents({
      event_id: appointmentData.extraProps.eventId,
    });
    let brandsList = await getBrands({
      event_id: appointmentData.extraProps.eventId,
    });
    let usersList = await getUsers({
      event_id: appointmentData.extraProps.eventId,
    });
    let zoneList = await getZones({
      event_id: appointmentData.extraProps.eventId,
    });

    setAgentsList(agentsList);
    setUsersList(usersList);
    setBrandsList(brandsList);
    setCountriesList(countriesList);
    setJobTitlesList(jobTitlesList);
    setzoneList(zoneList);
  }, []);

  const [productZoneSessionFileUpload, setProductZoneSessionFileUpload] =
    useState("");

  const [productZoneSessionStartDateTime, setProductZoneSessionStartDateTime] =
    useState(productZoneSessionFields.productZoneSession_start_date_time);

  const [productZoneSessionCsvUpload, setProductZoneSessionCsvUpload] =
    useState("");

  const mapListData = (list, key, valuesList) => {
    let blackList = [],
      whiteList = [];
    list.map((x) => {
      if (!blackList.includes(x[key] || !whiteList.includes(x[key]))) {
        if (valuesList.includes(x[key])) blackList.push(x);
        else whiteList.push(x);
      }
    });
    return { blackList, whiteList };
  };

  // useEffect(async () => {
  //   setModalInfo(Modal.info());
  //   let fieldsData = {
  //     productZoneSession_start_date_time: "",
  //     productZoneSession_end_date_time: "",
  //     productZoneSession_title: "",
  //     productZoneSession_description: "",
  //     is_ical_download_enabled: false,

  //     productZoneSession_specific_users: "",
  //     productZoneSession_brand_list: "",
  //     productZoneSession_country_list: "",
  //     productZoneSession_job_titles_list: "",

  //     productZoneSession_invitees_brand: "",
  //     productZoneSession_invitees_zone: "",
  //     productZoneSession_invitees_user_type: "",
  //     productZoneSession_invitees_country: "",
  //     productZoneSession_invitees_job_title: "",

  //     productZoneSession_prev_pending_date_time_1: "",
  //     productZoneSession_prev_pending_date_time_2: "",
  //     productZoneSession_invitation_title: "",
  //     productZoneSession_invitation_description: "",
  //     productZoneSession_invitation_is_ical_download_enabled: false,
  //     productZoneSession_invitation_re_invite: false,
  //     productZoneSession_schedule_invitation: false,
  //     productZoneSession_when_to_send_date_time: "",
  //   };
  //   if (appointmentData.data?.configuration_id)
  //     await getProductZoneSessionData(appointmentData.data.configuration_id);
  //   else {
  //     let modifiedResponse = fieldsData;
  //     await populateListsData();
  //     modifiedResponse.productZoneSession_start_date_time = moment(
  //       appointmentData.data.startTime
  //     );
  //     modifiedResponse.productZoneSession_end_date_time = moment(
  //       appointmentData.data.endTime
  //     );
  //     setProductZoneSessionFields({ ...modifiedResponse });
  //   }
  // }, [appointmentData]);

  function handleAgentsChange(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let agents_list = agentsList;
    let selectedAgents_list = taggedAgents;

    let index = agents_list.findIndex((agent) => agent.user_id === value);
    if (selectedAgents_list) {
      selectedAgents_list.push(agents_list[index]);
      agents_list.splice(index, 1);

      setTaggedAgents([...selectedAgents_list]);
      setAgentsList([...agents_list]);
      console.log("taggedAgents list12", taggedAgents);
    }
  }

  function handleSelectedAgentsChange(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let agents_list = agentsList;
    let selectedAgents_list = taggedAgents;

    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );

    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);

    setAgentsList([...agents_list]);
    setTaggedAgents([...selectedAgents_list]);

    // console.log("taggedAgents list34", taggedAgents);
  }

  function handleAgentsChangeUser(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let user_list = usersList;
    let selecteduser_list = selectedusersList;

    let index = user_list.findIndex((agent) => agent.user_id === value);
    if (selecteduser_list) {
      selecteduser_list.push(user_list[index]);
      user_list.splice(index, 1);

      setselecetedUsersList([...selecteduser_list]);
      setUsersList([...user_list]);
      // console.log("selectedusersList list12", selectedusersList);
    }
  }

  function handleSelectedAgentsChangeUser(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let user_list = usersList;
    let selecteduser_list = selectedusersList;

    let index = selecteduser_list.findIndex((agent) => agent.user_id === value);

    user_list.push(selecteduser_list[index]);
    selecteduser_list.splice(index, 1);

    setUsersList([...user_list]);
    setselecetedUsersList([...selecteduser_list]);

    // console.log("taggedAgents list34", taggedAgents);
  }

  function handleAgentsChangebrand(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let brand_list = brandsList;
    let selectedbrand_list = selectedbrandsList;

    let index = brand_list.findIndex((agent) => agent.brand_id === value);
    if (selectedbrand_list) {
      selectedbrand_list.push(brand_list[index]);
      brand_list.splice(index, 1);

      setselectedBrandsList([...selectedbrand_list]);
      setBrandsList([...brand_list]);
      console.log("selectedusersList list12", selectedusersList);
    }
  }

  function handleSelectedAgentsChangebrand(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let brand_list = brandsList;
    let selectedbrand_list = selectedbrandsList;

    let index = selectedbrand_list.findIndex(
      (agent) => agent.brand_id === value
    );

    brand_list.push(selectedbrand_list[index]);
    selectedbrand_list.splice(index, 1);

    setBrandsList([...brand_list]);
    setselectedBrandsList([...selectedbrand_list]);

    // console.log("taggedAgents list34", taggedAgents);
  }

  function handleAgentsChangecountry(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let country_list = countriesList;
    let selectedcountry_list = selectedcountriesList;

    let index = country_list.findIndex((agent) => agent.country_id === value);
    if (selectedcountry_list) {
      selectedcountry_list.push(country_list[index]);
      country_list.splice(index, 1);

      setselectedCountriesList([...selectedcountry_list]);
      setCountriesList([...country_list]);
      // console.log("selectedusersList list12", selectedusersList);
    }
  }

  function handleSelectedAgentsChangecountry(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let country_list = countriesList;
    let selectedcountry_list = selectedcountriesList;

    let index = selectedcountry_list.findIndex(
      (agent) => agent.country_id === value
    );

    country_list.push(selectedcountry_list[index]);
    selectedcountry_list.splice(index, 1);

    setCountriesList([...country_list]);
    setselectedCountriesList([...selectedcountry_list]);

    // console.log("taggedAgents list34", taggedAgents);
  }

  function handleAgentsChangejob(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 117 ~ handleAgentsChange ~ value", value)

    let jobtitle_list = jobTitlesList;
    let selectedjobtitle_list = selectedjobTitlesList;

    let index = jobtitle_list.findIndex(
      (agent) => agent.job_title_id === value
    );
    if (selectedjobtitle_list) {
      selectedjobtitle_list.push(jobtitle_list[index]);
      jobtitle_list.splice(index, 1);

      setselectedJobTitlesList([...selectedjobtitle_list]);
      setJobTitlesList([...jobtitle_list]);
      // console.log("selectedusersList list12", selectedusersList);
    }
  }

  function handleSelectedAgentsChangejob(value) {
    // console.log("🚀 ~ file: CreateNewBrand.js ~ line 131 ~ handleSelectedAgentsChange ~ value", value)

    let jobtitle_list = jobTitlesList;
    let selectedjobtitle_list = selectedjobTitlesList;

    let index = selectedjobtitle_list.findIndex(
      (agent) => agent.job_title_id === value
    );

    jobtitle_list.push(selectedjobtitle_list[index]);
    selectedjobtitle_list.splice(index, 1);

    setJobTitlesList([...jobtitle_list]);
    setselectedJobTitlesList([...selectedjobtitle_list]);

    // console.log("taggedAgents list34", taggedAgents);
  }

  const handleAddProductSession = async (data) => {
    const resAgent =
      taggedAgents &&
      taggedAgents.map((elm) => {
        return elm.user_id;
      });
    const resUser =
      selectedusersList &&
      selectedusersList.map((elm) => {
        return elm.user_id;
      });
    const resBrand =
      selectedbrandsList &&
      selectedbrandsList.map((elm) => {
        return elm.brand_id;
      });
    const resCountry =
      selectedcountriesList &&
      selectedcountriesList.map((elm) => {
        return elm.country_id;
      });
    const resjobTitle =
      selectedjobTitlesList &&
      selectedjobTitlesList.map((elm) => {
        return elm.job_title_id;
      });

    console.log(data);

    let bodyData = {
      session_details: {
        brand_id: appointmentData.extraProps.brandId,
        zone_id: appointmentData.extraProps.zoneId,
        start_date_time:
          moment(appointmentData.data.startDate).format("YYYY-MM-DD") +
          " " +
          moment(data.start_time).format("hh:mm"),
        end_date_time:
          moment(appointmentData.data.endDate).format("YYYY-MM-DD") +
          " " +
          moment(data.end_time).format("hh:mm"),
        session_title: data.session_title,
        session_description: data.session_description,
        is_ical_download_enabled: data.is_ical_download_enabled,
      },
      assign_staff_ids: resAgent,
      restricted_users: resUser,
      restricted_brands: resBrand,
      restricted_countries: resCountry,
      restricted_job_titles: resjobTitle,
      invitation_setup: {
        invitation_title: data.invitation_title,
        invitation_description: data.invitation_description,
        is_iCal_download_enabled: data.is_iCal_download_enabled__invite,
        is_reinvite_enabled: data.is_reinvite_enabled,
        send_invitation_now: data.send_invitation_now,
        date_time_to_invite: data.date_time_to_invite,
      },
      invited_brands: data.invited_brands,
      invited_zones: data.invited_zones,
      invited_user_types: data.invited_user_types,
      invited_countries: data.invited_countries,
      invited_job_titles: data.invited_job_titles,
    };
    console.log("bodydata ccreate", bodyData);

    let formData = new FormData();
    formData.append("data", JSON.stringify(bodyData));
    const response = await ApiCall(
      "POST",
      formData,
      "/admin/productzonesession/create"
    );
    console.log("response", response);
    if (response.data.code === 200) {
      toast.success(response?.data?.message);
      appointmentData.extraProps.getAppointmentDetails();
      appointmentData.extraProps.visibleChange();
    } else {
      toast.error(response?.data?.message);
    }

    console.log("response add session fiinaalll", response);
  };

  const ValidationSchema = Yup.object().shape({
    session_title: Yup.string().required("Required").nullable(),
    session_description: Yup.string().required("Required").nullable(),
    invitation_title: Yup.string()
      .required("Required")
      .matches(/^[a-z0-9]+$/i, "Must be Alphanumeric")
      .max(100, "Must be exactly 100 characters"),
    invitation_description: Yup.string()
      .required("Required")
      .matches(/^[a-z0-9]+$/i, "Must be Alphanumeric")
      .max(200, "Must be exactly 200 characters"),

    // zone_agents: Yup.array().length(1, "Required"),
  });

  return (
    // <Modal
    //   footer={null}
    //   visible={appointmentData.extraProps.visible}
    //   width={950}
    // >
    <Formik
      initialValues={initial_state}
      enableReinitialize={true}
      isInitialValid={false}
      validationSchema={ValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleAddProductSession(values);
        // handleCreateZone(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        isValidating,
        isValid,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div
            className="my-3"
            style={{ height: "60px", backgroundColor: "rgb(244 244 244)" }}
          >
            <h4 className="text-center fs-pink pt-2">Product Zone - Session</h4>
          </div>
          <Row className="adminSatellite_row">
            <Col span={8} className="productZoneSession_label mb-3">
              <p>
                Start Time <span style={{ color: "#EF0855" }}>*</span>
              </p>
            </Col>
            <Col span={14}>
              <TimePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                // disabled
                name="start_time"
                format={"HH:mm"}
                placeholder="Select Time"
                className="adminSatellite_date_time w-100"
                value={values.start_time}
                onSelect={(val) => {
                  setFieldValue("start_time", val);
                }}
              />
              {productzonesession_validator.current.message(
                "",
                productZoneSessionFields.productZoneSession_start_date_time,
                "required",
                {
                  className: "text-danger py-1",
                }
              )}
            </Col>
          </Row>
          <Row className="adminSatellite_row">
            <Col span={8} className="productZoneSession_label mb-3">
              <p className="text-end">
                End Time <span style={{ color: "#EF0855" }}>*</span>
              </p>
            </Col>
            <Col span={14}>
              <TimePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                // disabled
                name="end_time"
                // disabledDate={(d) =>
                //   !d || d.isSameOrBefore(productZoneSessionStartDateTime)
                // }
                format={"HH:mm"}
                placeholder="Select Time"
                className="adminSatellite_date_time w-100"
                value={values.end_time}
                onSelect={(val) => {
                  setFieldValue("end_time", val);
                }}
                // onBlur={() =>
                //   productzonesession_validator.current.showMessageFor(
                //     "productZoneSession_end_date_time"
                //   )
                // }
              />
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={8} className="productZoneSession_label">
              <p>Title</p>
            </Col>
            <Col span={14}>
              <Input
                name="session_title"
                className="productZoneSession_inputTitle"
                placeholder="Enter a Title here"
                // value={productZoneSessionFields.productZoneSession_title}
                onChange={handleChange}
                // onBlur={() =>
                //   productzonesession_validator.current.showMessageFor(
                //     "productZoneSession_title"
                //   )
                // }
              />
              {errors.session_title && touched.session_title ? (
                <div className="text-danger">{errors.session_title}</div>
              ) : null}
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={8} className="productZoneSession_label">
              <p style={{ marginTop: "9px" }}>Description</p>
            </Col>
            <Col span={14}>
              <TextArea
                name="session_description"
                // value={
                //   productZoneSessionFields.productZoneSession_description
                // }
                className="productZoneSession_inputDescription"
                placeholder="Enter a description here"
                rows={4}
                onChange={handleChange}
                // onBlur={() =>
                //   productzonesession_validator.current.showMessageFor(
                //     "productZoneSession_description"
                //   )
                // }
              />
              {errors.session_description && touched.session_description ? (
                <div className="text-danger">{errors.session_description}</div>
              ) : null}
              {/* {productzonesession_validator.current.message(
                  "Description",
                  productZoneSessionFields.productZoneSession_description,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p>Download iCal</p>
            </Col>

            <Col span={6} style={{ alignSelf: "center" }}>
              <Switch
                checked={values.is_ical_download_enabled}
                onChange={() =>
                  setFieldValue(
                    "is_ical_download_enabled",
                    !values.is_ical_download_enabled
                  )
                }
              />
            </Col>
          </Row>
          <Divider />
          <Row className="mb-3">
            <Col span={6}>
              <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                Assign Staff
              </h5>
            </Col>
            <Col span={9}>
              <h6 className="productZoneSession_assignstaffs_heading">
                Assigned
              </h6>
            </Col>
            <Col span={9}>
              <h6 className="productZoneSession_assignstaffs_heading">
                Tagged Agents
              </h6>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6}></Col>
            <Col span={8}>
              <div
                id="area"
                style={{ position: "relative" }}
                className="agentDropdown-calendar"
              >
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  showSearch
                  dropdownClassName="agent-dropdown"
                  style={{ width: 190 }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  value="All Agents"
                  onChange={handleAgentsChange}
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                >
                  {agentsList?.map((data) => {
                    // console.log('data', data)

                    return (
                      <Option value={data.user_id}>
                        {/* {data.first_name} {data.last_name} */}
                        {data.user_name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col span={8} offset={1}>
              <div
                className="agentDropdown-calendar"
                id="area1"
                style={{ position: "absolute" }}
              >
                <Select
                  getPopupContainer={() => document.getElementById("area1")}
                  showSearch
                  dropdownClassName="agent-dropdown"
                  style={{ width: 190 }}
                  placeholder="Search Agents"
                  optionFilterProp="children"
                  value="Tagged Agents"
                  // onChange={handleAgentsChange}
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                >
                  {taggedAgents?.map((data) => (
                    <Option value={data.user_id}>
                      {data.user_name}
                      <span className="float-end">
                        <CloseOutlined
                          className="float-right text-danger mr-0"
                          onClick={() =>
                            handleSelectedAgentsChange(data?.user_id)
                          }
                        />
                      </span>
                    </Option>
                  ))}
                  {/* {agentsList.map((val, idx) => {
                                     return  val.brands_agents.map((data,idx) => {
                                            // console.log("dattttaaaa",data.brand_id)
                                           return <Option value={idx} className="w-100"> {data.brandsAgents_user_id.first_name} </Option>
                                        })
                                        })} */}
                </Select>
              </div>
            </Col>
          </Row>
          {/* <Row>
              <Col span={6}></Col>
              <Col span={18}>
                <Button
                  className="productZoneSession_inviteAgentsBtn"
                  size={"large"}
                >
                  <AddGroup />
                  <span className="ms-2">Invite / Create</span>
                </Button>
              </Col>
            </Row> */}
          <Divider />
          <Row className="mx-5 my-3">
            <Col span={24}>
              <h5 className="fs-pink productZoneSession_media_assets_title">
                Media Assets
              </h5>
            </Col>
          </Row>
          <Row className="mx-5 my-3">
            <Col span={18}>
              <p className="productZoneSession_media_assets_info ml-2">
                NB: By default, all assets for this zone will already be
                available in your demo room playlist.
                <br /> You can either pre-upload assets here or add assets in
                the meeting room itself.
              </p>
            </Col>
          </Row>
          <Row className="mx-5 my-3">
            <Col span={6}>
              <p
                style={{ opacity: 1 }}
                className="productZoneSession_label fs-16"
              >
                Upload Individual Assets
              </p>
            </Col>
            <Col span={4}>
              <FileUpload
                labelClassName="rounded border-pink btn-svg  px-4 py-2"
                labelTitle="File Upload"
                name="productZoneSessionFileUpload"
                inputType="file"
                inputOnChange={(e) => {
                  setProductZoneSessionFileUpload(e.target.files[0]);
                }}
                inputId="productZoneSessionFileUpload"
                inputName="productZoneSessionFileUpload"
                onBlur={() =>
                  productzonesession_validator.current.showMessageFor(
                    "productZoneSessionFileUpload"
                  )
                }
              />
              {productzonesession_validator.current.message(
                "File upload",
                productZoneSessionFileUpload,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
            <Col span={9}>
              {productZoneSessionFileUpload ? (
                <>
                  <span className="mx-3">
                    {" "}
                    {productZoneSessionFileUpload.name}{" "}
                  </span>
                  <span>
                    {" "}
                    <DeleteOutlined />{" "}
                  </span>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Divider />
          <Row className="mx-5 my-3">
            <Col span={18}>
              <h5 className="fs-pink productZoneSession_restrict_access_title p-0">
                Restrict Access
              </h5>
            </Col>
          </Row>
          <Row className="mx-5 my-3">
            <Col span={24}>
              <p className="productZoneSession_restrict_access_info">
                NB: Configure who can view / access your event.
                <br /> Attendees with any black list attributes will not be able
                to to see the event on any calendars or access the event.
                <br /> If an attendee/attribute is on the White List they will
                be able to see/attaned the event regardless of any black list
                attributes.
              </p>
            </Col>
          </Row>
          {/* <Divider /> */}
          <Row className="mb-3">
            <Col span={6}>
              <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                Assign Staff
              </h5>
            </Col>
            <Col span={9}>
              <h6 className="productZoneSession_assignstaffs_heading">
                WhiteList Users
              </h6>
            </Col>
            <Col span={9}>
              <h6 className="productZoneSession_assignstaffs_heading">
                BlackList Users
              </h6>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6}></Col>
            <Col span={8}>
              <div
                id="areauser"
                style={{ position: "relative" }}
                className="agentDropdown-calendar"
              >
                <Select
                  getPopupContainer={() => document.getElementById("areauser")}
                  showSearch
                  dropdownClassName="agent-dropdown"
                  style={{ width: 190 }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  value="All Agents"
                  onChange={handleAgentsChangeUser}
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                >
                  {usersList?.map((data) => {
                    // console.log('data', data)

                    return (
                      <Option value={data.user_id}>
                        {/* {data.first_name} {data.last_name} */}
                        {data.user_name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col span={8} offset={1}>
              <div
                className="agentDropdown-calendar"
                id="areauserb"
                style={{ position: "absolute" }}
              >
                <Select
                  getPopupContainer={() => document.getElementById("areauserb")}
                  showSearch
                  dropdownClassName="agent-dropdown"
                  style={{ width: 190 }}
                  placeholder="Search Agents"
                  optionFilterProp="children"
                  value="Tagged Agents"
                  // onChange={handleAgentsChange}
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                >
                  {selectedusersList?.map((data) => (
                    <Option value={data.user_id}>
                      {data.user_name}
                      <span className="float-end">
                        <CloseOutlined
                          className="float-right text-danger mr-0"
                          onClick={() =>
                            handleSelectedAgentsChangeUser(data?.user_id)
                          }
                        />
                      </span>
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col span={6}>
              <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                {/* Assign Staff */}
              </h5>
            </Col>
            <Col span={9}>
              <h6 className="productZoneSession_assignstaffs_heading">
                WhiteList Brand
              </h6>
            </Col>
            <Col span={9}>
              <h6 className="productZoneSession_assignstaffs_heading">
                BlackList Brand
              </h6>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6}></Col>
            <Col span={8}>
              <div
                id="areabrandw"
                style={{ position: "relative" }}
                className="agentDropdown-calendar"
              >
                <Select
                  getPopupContainer={() =>
                    document.getElementById("areabrandw")
                  }
                  showSearch
                  dropdownClassName="agent-dropdown"
                  style={{ width: 190 }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  value="All Agents"
                  onChange={handleAgentsChangebrand}
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                >
                  {brandsList?.map((data) => {
                    // console.log('databand', data)

                    return (
                      <Option value={data.brand_id}>
                        {/* {data.first_name} {data.last_name} */}
                        {data.brand_title}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col span={8} offset={1}>
              <div
                className="agentDropdown-calendar"
                id="areabrandb"
                style={{ position: "absolute" }}
              >
                <Select
                  getPopupContainer={() =>
                    document.getElementById("areabrandb")
                  }
                  showSearch
                  dropdownClassName="agent-dropdown"
                  style={{ width: 190 }}
                  placeholder="Search Agents"
                  optionFilterProp="children"
                  value="Tagged Agents"
                  // onChange={handleAgentsChange}
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                >
                  {selectedbrandsList?.map((data) => (
                    <Option value={data.brand_id}>
                      {data.brand_title}
                      <span className="float-end">
                        <CloseOutlined
                          className="float-right text-danger mr-0"
                          onClick={() =>
                            handleSelectedAgentsChangebrand(data?.brand_id)
                          }
                        />
                      </span>
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col span={6}>
              <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                {/* Assign Staff */}
              </h5>
            </Col>
            <Col span={9}>
              <h6 className="productZoneSession_assignstaffs_heading">
                WhiteList Country
              </h6>
            </Col>
            <Col span={9}>
              <h6 className="productZoneSession_assignstaffs_heading">
                BlackList Country
              </h6>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6}></Col>
            <Col span={8}>
              <div
                id="areacountryw"
                style={{ position: "relative" }}
                className="agentDropdown-calendar"
              >
                <Select
                  getPopupContainer={() =>
                    document.getElementById("areacountryw")
                  }
                  showSearch
                  dropdownClassName="agent-dropdown"
                  style={{ width: 190 }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  value="All Agents"
                  onChange={handleAgentsChangecountry}
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                >
                  {countriesList?.map((data) => {
                    // console.log('datacontry', data)

                    return (
                      <Option value={data.country_id}>
                        {/* {data.first_name} {data.last_name} */}
                        {data.country}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col span={8} offset={1}>
              <div
                className="agentDropdown-calendar"
                id="areacountryb"
                style={{ position: "absolute" }}
              >
                <Select
                  getPopupContainer={() =>
                    document.getElementById("areacountryb")
                  }
                  showSearch
                  dropdownClassName="agent-dropdown"
                  style={{ width: 190 }}
                  placeholder="Search Agents"
                  optionFilterProp="children"
                  value="Tagged Agents"
                  // onChange={handleAgentsChange}
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                >
                  {selectedcountriesList?.map((data) => (
                    <Option value={data.country_id}>
                      {data.country}
                      <span className="float-end">
                        <CloseOutlined
                          className="float-right text-danger mr-0"
                          onClick={() =>
                            handleSelectedAgentsChangecountry(data?.country_id)
                          }
                        />
                      </span>
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col span={6}>
              <h5 className="fs-pink productZoneSession_assignstaffs_title ">
                {/* Assign Staff */}
              </h5>
            </Col>
            <Col span={9}>
              <h6 className="productZoneSession_assignstaffs_heading">
                WhiteList Job Title
              </h6>
            </Col>
            <Col span={9}>
              <h6 className="productZoneSession_assignstaffs_heading">
                BlackList Job Title
              </h6>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={6}></Col>
            <Col span={8}>
              <div
                id="areajobtitlew"
                style={{ position: "relative" }}
                className="agentDropdown-calendar"
              >
                <Select
                  getPopupContainer={() =>
                    document.getElementById("areajobtitlew")
                  }
                  showSearch
                  dropdownClassName="agent-dropdown"
                  style={{ width: 190 }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  value="All Agents"
                  onChange={handleAgentsChangejob}
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                >
                  {jobTitlesList?.map((data) => {
                    // console.log('datajobtitll', data)

                    return (
                      <Option value={data.job_title_id}>
                        {/* {data.first_name} {data.last_name} */}
                        {data.job_title}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col span={8} offset={1}>
              <div
                className="agentDropdown-calendar"
                id="areajobtitleb"
                style={{ position: "absolute" }}
              >
                <Select
                  getPopupContainer={() =>
                    document.getElementById("areajobtitleb")
                  }
                  showSearch
                  dropdownClassName="agent-dropdown"
                  style={{ width: 190 }}
                  placeholder="Search Agents"
                  optionFilterProp="children"
                  value="Tagged Agents"
                  // onChange={handleAgentsChange}
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                >
                  {selectedjobTitlesList?.map((data) => (
                    <Option value={data.job_title_id}>
                      {data.job_title}
                      <span className="float-end">
                        <CloseOutlined
                          className="float-right text-danger mr-0"
                          onClick={() =>
                            handleSelectedAgentsChangejob(data?.job_title_id)
                          }
                        />
                      </span>
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <h5 className="fs-pink productZoneSession_prevpending_title">
                Previous & Pending Invitees
              </h5>
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6}></Col>
            <Col span={8} md={12} className="productZoneSession_eyeViewBtn">
              <Empty description="No invitees" />
            </Col>
          </Row>

          {/* <Row className="productZoneSession_row">
              <Col span={6} className="productZoneSession_label">
                <p>Date & Time</p>
              </Col>
              <Col span={7}>
                <DatePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="productZoneSession_prev_pending_date_time_1"
                  defaultValue={
                    productZoneSessionFields.productZoneSession_prev_pending_date_time_1
                  }
                  format={"D/M/YYYY HH:mm"}
                  className="productZoneSession_date_time"
                  showTime
                  onChange={(val) =>
                    handleProductZoneSessionFieldsChange(
                      val,
                      "productZoneSession_prev_pending_date_time_1"
                    )
                  }
                  onBlur={() =>
                    productzonesession_validator.current.showMessageFor(
                      "productZoneSession_prev_pending_date_time_1"
                    )
                  }
                />
                {productzonesession_validator.current.message(
                  "",
                  productZoneSessionFields.productZoneSession_prev_pending_date_time_1,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )}
              </Col>

              <Col span={9} offset={2}>
                <Button
                  className="productZoneSession_prevpending_viewListBtn"
                  size={"large"}
                >
                  <EyeView />
                  <span className="ms-2">View List</span>
                </Button>
                <img style={{ marginLeft: "15px" }} src={blackbin} />
              </Col>
            </Row> */}

          <Row>
            <Col span={24}>
              <h5 className="fs-pink productZoneSession_new_invitees_title">
                New Invitees
              </h5>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={9}>
              <h6 className="productZoneSession_new_invitees_heading">
                Filter Attendees
              </h6>
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p>Brand</p>
            </Col>
            <Col span={18}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                name="invited_brands"
                // bordered={false}
                className="productZoneSession_selectBrand"
                value={values.invited_brands}
                mode="multiple"
                onChange={(value) => {
                  setFieldValue("invited_brands", value);
                }}
              >
                {brandsList?.map((data) => {
                  // console.log('databand', data)

                  return (
                    <Option value={data.brand_id}>
                      {/* {data.first_name} {data.last_name} */}
                      {data.brand_title}
                    </Option>
                  );
                })}
              </Select>
              {/* {productzonesession_validator.current.message(
                  "Brand",
                  productZoneSessionFields.productZoneSession_invitees_brand,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p>Zone</p>
            </Col>
            <Col span={18}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                name="invited_zones"
                bordered={false}
                className="productZoneSession_selectBrand"
                value={values.invited_zones}
                mode="multiple"
                onChange={(value) => {
                  setFieldValue("invited_zones", value);
                }}
              >
                {zoneList?.map((data) => {
                  // console.log('zooone', data)

                  return (
                    <Option value={data.zone_id}>
                      {/* {data.first_name} {data.last_name} */}
                      {data.zone_title}
                    </Option>
                  );
                })}
              </Select>
              {/* {productzonesession_validator.current.message(
                  "Zone",
                  productZoneSessionFields.productZoneSession_invitees_zone,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p>User Type</p>
            </Col>
            <Col span={18}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                name="invited_user_types"
                bordered={false}
                className="productZoneSession_selectBrand"
                value={values.invited_user_types}
                mode="multiple"
                onChange={(value) => {
                  setFieldValue("invited_user_types", value);
                }}
              >
                <Option value="AGENT">AGENT</Option>
                <Option value="DELEGATE">DELEGATE</Option>
                <Option value="SPEAKER">SPEAKER</Option>
              </Select>
              {/* {productzonesession_validator.current.message(
                  "User Type",
                  productZoneSessionFields.productZoneSession_invitees_user_type,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p>Country</p>
            </Col>
            <Col span={18}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                name="invited_countries"
                bordered={false}
                className="productZoneSession_selectBrand"
                value={values.invited_countries}
                mode="multiple"
                onChange={(value) => {
                  setFieldValue("invited_countries", value);
                }}
              >
                {countriesList?.map((data) => {
                  // console.log('zooone', data)

                  return (
                    <Option value={data.country_id}>
                      {/* {data.first_name} {data.last_name} */}
                      {data.country}
                    </Option>
                  );
                })}
              </Select>
              {/* {productzonesession_validator.current.message(
                  "Country",
                  productZoneSessionFields.productZoneSession_invitees_country,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p>Job Title</p>
            </Col>
            <Col span={18}>
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                name="invited_job_titles"
                bordered={false}
                className="productZoneSession_selectBrand"
                value={values.invited_job_titles}
                mode="multiple"
                onChange={(value) => {
                  setFieldValue("invited_job_titles", value);
                }}
              >
                {jobTitlesList?.map((data) => {
                  // console.log('zooone', data)

                  return (
                    <Option value={data.job_title_id}>
                      {/* {data.first_name} {data.last_name} */}
                      {data.job_title}
                    </Option>
                  );
                })}
              </Select>
              {/* {productzonesession_validator.current.message(
                  "Job Title",
                  productZoneSessionFields.productZoneSession_invitees_job_title,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <h5 className="productZoneSession_invitation_setup_title text-danger">
                Invitation Setup
              </h5>
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p>Title</p>
            </Col>
            <Col span={18}>
              <Input
                name="invitation_title"
                className="productZoneSession_inputTitle"
                placeholder="Enter a Title here"
                // value={
                //   productZoneSessionFields.productZoneSession_invitation_title
                // }
                onChange={handleChange}
                // onBlur={() =>
                //   productzonesession_validator.current.showMessageFor(
                //     "productZoneSession_invitation_title"
                //   )
                // }
              />
              {errors.invitation_title && touched.invitation_title ? (
                <div className="text-danger">{errors.invitation_title}</div>
              ) : null}
              {/* {productzonesession_validator.current.message(
                  "Title",
                  productZoneSessionFields.productZoneSession_invitation_title,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p style={{ marginTop: "9px" }}>Description</p>
            </Col>
            <Col span={18}>
              <TextArea
                name="invitation_description"
                className="productZoneSession_inputDescription"
                placeholder="Enter a description here"
                rows={4}
                // value={
                //   productZoneSessionFields.productZoneSession_invitation_description
                // }
                onChange={handleChange}
                // onBlur={() =>
                //   productzonesession_validator.current.showMessageFor(
                //     "productZoneSession_invitation_description"
                //   )
                // }
              />
              {errors.invitation_description &&
              touched.invitation_description ? (
                <div className="text-danger">
                  {errors.invitation_description}
                </div>
              ) : null}
              {/* {productzonesession_validator.current.message(
                  "Description",
                  productZoneSessionFields.productZoneSession_invitation_description,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )} */}
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p className="fs-14">Attach iCal</p>
            </Col>
            <Col span={18}>
              <Switch
                checked={values.is_iCal_download_enabled__invite}
                onChange={() =>
                  setFieldValue(
                    "is_iCal_download_enabled__invite",
                    !values.is_iCal_download_enabled__invite
                  )
                }
              />
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p className="fs-14">Re-invite</p>
            </Col>
            <Col span={18}>
              <Switch
                checked={values.is_reinvite_enabled}
                onChange={() =>
                  setFieldValue(
                    "is_reinvite_enabled",
                    !values.is_reinvite_enabled
                  )
                }
              />{" "}
              <span style={{ marginLeft: "30px" }}>(if already invited)</span>
            </Col>
          </Row>
          <Divider />
          <Row className="productZoneSession_row">
            <Col span={24}>
              <h5 className="fs-pink productZoneSession_whentosend_title">
                When to Send Invitation
              </h5>
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p className="fs-14">Scheduled</p>
            </Col>
            <Col span={18}>
              <Switch
                checked={!values.send_invitation_now}
                onChange={() =>
                  setFieldValue(
                    "send_invitation_now",
                    !values.send_invitation_now
                  )
                }
              />
            </Col>
          </Row>
          <Row className={values.send_invitation_now && "d-none"}>
            <Col span={6} className="productZoneSession_label">
              <p>Date & Time</p>
            </Col>
            <Col span={7}>
              <DatePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                name="date_time_to_invite"
                disabledDate={(d) => !d || d.isSameOrBefore(moment())}
                format={"D/M/YYYY HH:mm"}
                className="productZoneSession_date_time"
                showTime
                value={values.date_time_to_invite}
                onSelect={(val) => setFieldValue("date_time_to_invite", val)}
                // onBlur={() =>
                //   productzonesession_validator.current.showMessageFor(
                //     "productZoneSession_prev_pending_date_time_2"
                //   )
                // }
              />
            </Col>
          </Row>
          <Row>
            <Col span={6} className="productZoneSession_label"></Col>
            <Col span={7}>
              <span className="fs-pink productZoneSession_or">OR</span>
            </Col>
          </Row>
          <Row className="productZoneSession_row">
            <Col span={6} className="productZoneSession_label">
              <p className="fs-14">Now</p>
            </Col>
            <Col span={18}>
              <Switch
                checked={values.send_invitation_now}
                onChange={() =>
                  setFieldValue(
                    "send_invitation_now",
                    !values.send_invitation_now
                  )
                }
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end p-4 ">
            <Button
              className="productZoneSession_cancelBtn ms-4"
              onClick={() => appointmentData.extraProps.visibleChange()}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              className="productZoneSession_saveBtn ms-4"
              // onClick={() => saveZoneSessionDetails()}
              onClick={() => {
                console.log("isValid", isValid);

                if (!isValid) {
                  toast.error("Fields are Missing");
                }
              }}
            >
              Save Changes & Send Invitations
            </Button>
          </div>
          {/* </Modal> */}
        </form>
      )}
    </Formik>
    // </Modal>
  );
};
export default ProductZoneSessionCreate;
