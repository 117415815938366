import React, { Fragment, useState, useEffect } from "react";
import {
  Menu,
  Button,
  Select,
  Switch,
  Input,
  DatePicker,
  message,
  Collapse,
  TimePicker,
} from "antd";
import { iconSelected, info } from "../../../components/Images/Images";
import { useMediaQuery } from "react-responsive";
import { CalenderGrey } from "../../../components/svgJS/CalenderRed";
import moment from "moment";
import { ApiCall } from "../../../utils/api";
import _, { cloneDeep, isEmpty } from "lodash";
import { getUserTypeAgents } from "../../../utils/commonApis";
import { Download } from "../../../components/svgJS/Download";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { LOGO_DEMO } from "../../../components/constsnts/common.constants";
import ReactPlayer from "react-player";
import { isImage } from "../../../utils/commonFunctions";
var CryptoJS = require("crypto-js");

const EventEditor = ({ data }) => {
  const { Option } = Select;
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const [startTimeValue, setStartTimeValue] = useState(null);
  const [endTimeValue, setEndTimeValue] = useState(null);
  const [colorValue, setColorValue] = useState("#ef0855");
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [agentsTechnical, setAgentsTechnical] = useState([]);
  const [selectedAgentsTechnical, setSelectedAgentsTechnical] = useState([]);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [userType, setUserType] = useState("");

  useEffect(() => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);
    console.log("data in event editor after change ", data);
  }, [data]);

  useEffect(() => {
    if (agentsCollectionData) {
      setAgentsFromAPIAndUseEffect(data);
    }
  }, [agentsCollectionData]);

  const setAgentsFromAPIAndUseEffect = (dataHere) => {
    // console.log(
    //   "agents_list main in setAgentsFromAPIAndUseEffect = ",
    //   agentsCollectionData
    // );

    let event_support_agents = [];
    dataHere &&
      dataHere.event_support &&
      dataHere.event_support.agents.map((val) => {
        event_support_agents.push(val.user_id);
      });

    let event_technical_support_agents = [];
    dataHere &&
      dataHere.event_technical_support &&
      dataHere.event_technical_support.agents.map((val) => {
        event_technical_support_agents.push(val.user_id);
      });

    if (
      dataHere &&
      !isEmpty(dataHere.event_support) &&
      !isEmpty(event_support_agents)
    ) {
      let clonedAgents = cloneDeep(agentsCollectionData);

      event_support_agents.map((val) => {
        if (
          clonedAgents &&
          clonedAgents.some((agent) => agent.user_id === val)
        ) {
          let agents_list = clonedAgents;
          let selectedAgents_list = selectedAgents;

          let index = agents_list.findIndex((agent) => agent.user_id === val);
          selectedAgents_list.push(agents_list[index]);
          agents_list.splice(index, 1);
          selectedAgents_list = _.uniqWith(selectedAgents_list, _.isEqual);
          setSelectedAgents([...selectedAgents_list]);
          setAgents([...agents_list]);
        }
      });
    } else setAgents(agentsCollectionData);

    if (dataHere && !isEmpty(event_technical_support_agents)) {
      let clonedAgents = cloneDeep(agentsCollectionData);
      event_technical_support_agents.map((val) => {
        if (
          clonedAgents &&
          clonedAgents.some((agent) => agent.user_id === val)
        ) {
          let technical_agents_list = clonedAgents;
          let technical_selectedAgents_list = selectedAgentsTechnical;

          let index = technical_agents_list.findIndex(
            (agent) => agent.user_id === val
          );
          technical_selectedAgents_list.push(technical_agents_list[index]);
          technical_agents_list.splice(index, 1);
          technical_selectedAgents_list = _.uniqWith(
            technical_selectedAgents_list,
            _.isEqual
          );
          setSelectedAgentsTechnical([...technical_selectedAgents_list]);
          setAgentsTechnical([...technical_agents_list]);
        }
      });
    } else setAgentsTechnical(agentsCollectionData);
  };

  useEffect(async () => {
    if (data?.event_id && data?.organizer_brand_id) {
      const agentListAPI = await getUserTypeAgents({
        event_id: data?.event_id,
        brand_id: data?.organizer_brand_id,
      });
      console.log("check1", agentListAPI);
      setAgentsCollectionData(agentListAPI);
    }
  }, [data?.event_id]);

  useEffect(async () => {
    // const agentListAPI = await getUserTypeAgents();
    // setAgentsCollectionData(agentListAPI);
    //aditya removed data effect
  }, [data?.editor_details?.event_supports]);

  const menu = (
    <Menu>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          3rd menu item
        </a>
      </Menu.Item>
    </Menu>
  );

  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });

  const getAgentsList = () => {
    ApiCall("get", null, "/admin/user/get", null)
      .then((response) => {
        // setAllAgentsList(response?.data?.data?.rows);
        setAgents(response?.data?.data?.rows);
        // setAgentsTechnical(response?.data?.data?.rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  function onChange(value, dateString) {
    // console.log("Selected Time: ", value);
    // console.log("Formatted Selected Time: ", dateString);
  }

  function onOk(value) {
    // console.log("onOk: ", value);
  }

  const onStartTimeChange = (time) => {
    setStartTimeValue(time);
  };
  const onEndTimeChange = (time) => {
    setEndTimeValue(time);
  };
  const text = `
            A dog is a type of domesticated animal.
            Known for its loyalty and faithfulness,
            it can be found as a welcome guest in many households across the world.
            `;

  const accordianForm = (
    <Fragment>
      <div className="row">
        <div className="col-3 text-end">
          <label className="text-secondary mt-1">Start Date</label>
        </div>
        <div className="col-6">
          <DatePicker
            showTime
            suffixIcon={<CalenderGrey width="15" />}
            onChange={onChange}
            onOk={onOk}
            className="w-75"
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-3 text-end">
          <label className="text-secondary mt-1">Start & End Time</label>
        </div>
        <div className="col-4 d-flex m-0">
          <TimePicker value={startTimeValue} onChange={onStartTimeChange} />
          <TimePicker
            value={endTimeValue}
            onChange={onEndTimeChange}
            className="mx-4"
          />
        </div>
        <div className="col-5 text-start mt-1">
          <Switch defaultChecked className=" mr-0" />
          <span className="mx-2"> Use these times for all the days?</span>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-3 text-end">
          <label className="text-secondary mt-1">End Date</label>
        </div>
        <div className="col-6">
          <DatePicker
            showTime
            suffixIcon={<CalenderGrey width="15" />}
            onChange={onChange}
            onOk={onOk}
            className="w-75"
          />
        </div>
      </div>
    </Fragment>
  );

  const eventDate = (
    <span>
      <span className="fw-bold">4 Day Show </span>
      <span
        className="font-pink mx-2
            "
      >
        [20/02/2021, 9:30 - 14:30]
      </span>
    </span>
  );

  useEffect(() => {}, [selectedAgents]);

  function handleAgentsChange(value) {
    let allAgents = agents;
    let allSelectedAgents = selectedAgents;

    allSelectedAgents.push(allAgents[value]);
    allAgents.splice(value, 1);
    setSelectedAgents([...allSelectedAgents]);
    setAgents([...allAgents]);
  }

  const eventSupportTaggedAgents =
    data?.editor_details?.event_support?.agents.map((data) => {
      return data;
    });

  if (data) {
    return (
      <div className="container-fluid view-register-and-com">
        {/* section selected customer */}
        <section>
          <div className="row border-bottom-gray pb-3 mb-2">
            <h6 className="font-pink fw-bolder fs-20">Select Customer</h6>
            <div className="col-7 text-left px-5">
              <span className="mt-1">Customer Name </span>
              <Select
                readOnly
                // showSearch
                disabled
                defaultValue={data?.customer?.account_name}
                style={{ width: 300, marginLeft: "20px" }}
                placeholder="Search to Select"
                className="fs-bold-14"
              ></Select>
            </div>
            <div className="col-5">
              <p>
                <span className="text-secondary mx-3">Account Name </span> :
                <span className="fw-bold mx-3">
                  {data?.customer?.account_name}
                </span>
              </p>
              {userType === "CUSTOMER" ? (
                <p className="d-flex">
                  <span className="text-secondary mx-3">License Period </span> :
                  <span className="fw-bold mx-3">
                    {data.customer.license_period_start_date} -
                    {data.customer.license_period_end_date}
                  </span>
                </p>
              ) : (
                <p className="d-flex">
                  <span className="text-secondary mx-3">License Period </span> :
                  <span className="fw-bold mx-3">
                    {`${
                      data?.customer?.license_period_start_date
                        ? moment(
                            data?.customer?.license_period_start_date
                          ).format("DD-MM-YYYY")
                        : ""
                    } - ${
                      data?.customer?.license_period_end_date
                        ? moment(
                            data?.customer?.license_period_end_date
                          ).format("DD-MM-YYYY")
                        : ""
                    }`}
                  </span>
                </p>
              )}
            </div>
          </div>
        </section>

        {/* section event details */}
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Details </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary">
                Active
              </label>
            </div>
            <div className="col-3">
              <Switch
                disabled
                defaultChecked={data?.is_active}
                className="mx-3"
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Event Name
              </label>
            </div>
            <div className="col-3">
              <Input
                name=""
                id=""
                className="w-100 border-gray rounded fs-bold-14"
                value={data?.event_name}
                readOnly
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Sub domain
              </label>
            </div>
            <div className="col-3">
              <Input
                placeholder="large size"
                value={data?.sub_domain}
                suffix={<img src={iconSelected} alt="icon" />}
                className="rounded fs-bold-14"
                // disabled
                readOnly
              />
            </div>
            <div className="col-3 mt-1">
              <p>.gravit.io</p>
            </div>
          </div>

          {data?.events_organizer_brand_id?.brand_title ? (
            <div
              className={
                data?.events_organizer_brand_id?.brand_title ? "row mt-4" : ""
              }
            >
              <div className="col-3 text-end">
                <label htmlFor="" className="text-secondary">
                  Organiser
                </label>
              </div>

              <div className="col-3">
                <Input value={data?.events_organizer_brand_id?.brand_title} />
              </div>
              <div className="col-6">
                <span>
                  <Switch
                    readOnly
                    disabled
                    defaultChecked={data?.is_booth_at_event}
                    className="mx-2"
                  />
                  Do they have a booth at the event?
                </span>
              </div>
            </div>
          ) : null}

          {/* <div className="row mt-4">
            <div className="col-3"></div>
            {/* <div className="col-3 ">
              <Button className="px-2 button-pink rounded">
                + Create New Brand
              </Button>
            </div> */}
          {/* </div>  */}

          <div className="row mt-4">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Main Contact Name
              </label>
            </div>
            <div className="col-3">
              <Input
                name=""
                id=""
                className="w-100 border-gray rounded fs-bold-14"
                value={data?.main_contact_name}
                // disabled
                readOnly
              />
            </div>
          </div>

          <div className="row mt-4 pb-4 border-bottom-gray">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Email Address
              </label>
            </div>
            <div className="col-3">
              <Input
                name=""
                id=""
                className="w-100 border-gray rounded fs-bold-14"
                value={data?.email_address}
                // disabled
                readOnly
              />
            </div>
          </div>
        </section>

        {/* section registration details*/}
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">
                Registration Details
              </h6>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary">
                Start Date & Time
              </label>
            </div>
            <div className="col-3">
              <DatePicker
                suffixIcon={<CalenderGrey width="15" />}
                className="w-100 border-gray rounded disable-color ant-picker.ant-picker-disabled ant-picker-input > input[disabled]"
                disabled
                style={{
                  backgroundColor: "white",
                  color: "rgba(0, 0, 0, 0.85)",
                }}
                readOnly
                defaultValue={moment(data?.registration_start_date_time)}
                format={"DD/MM/YYYY HH:mm:ss"}
              />
            </div>
          </div>

          <div className="row mt-4 pb-3 border-bottom-gray">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary">
                End Date & Time
              </label>
            </div>
            <div className="col-3">
              <DatePicker
                suffixIcon={<CalenderGrey width="15" />}
                className="w-100 border-gray rounded disable-color ant-picker.ant-picker-disabled ant-picker-input > input[disabled]"
                disabled
                readOnly
                style={{
                  backgroundColor: "white",
                  color: "rgba(0, 0, 0, 0.85)",
                }}
                defaultValue={moment(data?.registration_end_date_time)}
                format={"DD/MM/YYYY HH:mm:ss"}
              />
            </div>
          </div>
        </section>

        {/* section event theme */}
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Theme</h6>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Event Colour Theme
              </label>
            </div>
            <div className="col-3">
              <Input
                type="color"
                id=""
                className="w-75 border-gray rounded fs-bold-14"
                value={data?.event_color_theme || colorValue}
                addonBefore={data?.event_color_theme || colorValue}
                onChange={(e) => setColorValue(e.target.value)}
                defaultValue={data?.event_color_theme}
                disabled
                readOnly
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Event Logo <img src={info} alt="info" />
              </label>
            </div>
            <div className="col-3">
              {data && data?.event_logo_url ? (
                <img
                  src={data && data?.event_logo_url}
                  alt="Logo File Preview"
                  className="img-fluid"
                  style={{ height: "60px", width: "60px" }}
                />
              ) : (
                <h4 className="fs-pink text-center py-3">File not uploaded</h4>
              )}
            </div>
          </div>

          <div className="row mt-4 pb-4 border-bottom-gray">
            <div className="col-3 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Logo Demo Size
              </label>
            </div>
            <div className="col-3">
              <Button
                onClick={() => {
                  window.open(LOGO_DEMO.URL, "_blank");
                }}
                download={LOGO_DEMO.FILENAME}
              >
                <Download />
                <span className="ms-2">Download</span>
              </Button>
            </div>
          </div>
        </section>

        {/* section event dates */}
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Dates</h6>
            </div>
          </div>

          <div className="row mt-4 border-bottom-gray pb-4">
            <div className="col-9 event-date-scroll-div">
              <Collapse
                bordered={false}
                accordion={false}
                collapsible={false}
                expandIcon={({ isActive }) =>
                  (isActive && (
                    <MinusOutlined className="rounded minus-logo" />
                  )) || <PlusOutlined className="rounded plus-logo" />
                }
                className="site-collapse-custom-collapse"
              >
                {data?.event_shows_details?.map((item, i) => {
                  return (
                    <Panel
                      header={
                        <span>
                          <span className="fw-bold">
                            Shows Days/Times - Block {i + 1}
                          </span>
                          <span className="font-pink mx-2">
                            {item &&
                              item.start_date_time &&
                              `${moment(item?.start_date_time).format(
                                "DD/MM/YYYY HH:mm"
                              )} - ${moment(item?.end_date_time).format(
                                "DD/MM/YYYY HH:mm"
                              )}`}
                          </span>
                        </span>
                      }
                      key={i}
                      className="site-collapse-custom-panel"
                    >
                      <div className="row">
                        <div className="col-3 text-end">
                          <label className="text-secondary mt-1">
                            Start Date
                          </label>
                        </div>
                        <div className="col-6">
                          <DatePicker
                            suffixIcon={<CalenderGrey width="15" />}
                            defaultValue={
                              item.start_date
                                ? moment(item.start_date, "YYYY-MM-DD")
                                : null
                            }
                            format="DD-MM-YYYY"
                            disabled
                            onOk={onOk}
                            className="w-75"
                          />
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-3 text-end">
                          <label className="text-secondary mt-1">
                            Start & End Time
                          </label>
                        </div>
                        <div className="col-4 d-flex m-0">
                          <TimePicker
                            suffixIcon={<CalenderGrey width="15" />}
                            renderExtraFooter={() => "extra footer"}
                            showTime
                            value={moment(item.start_time, "HH:mm")}
                            disabled
                            onOk={onOk}
                            className="w-100"
                            format={"HH:mm"}
                          />
                          <TimePicker
                            suffixIcon={<CalenderGrey width="15" />}
                            renderExtraFooter={() => "extra footer"}
                            showTime
                            disabled
                            value={moment(item.end_time, "HH:mm")}
                            onOk={onOk}
                            className="w-100"
                            format={"HH:mm"}
                          />
                        </div>
                        <div className="col-5 text-start mt-1">
                          <Switch defaultChecked disabled className=" mr-0" />
                          <span className="mx-2">
                            Use these times for all the days?
                          </span>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-3 text-end">
                          <label className="text-secondary mt-1">
                            End Date
                          </label>
                        </div>
                        <div className="col-6">
                          <DatePicker
                            disabled
                            defaultValue={
                              item.end_date
                                ? moment(item.end_date, "YYYY-MM-DD")
                                : null
                            }
                            format="DD-MM-YYYY"
                            suffixIcon={<CalenderGrey width="15" />}
                            onOk={onOk}
                            className="w-75"
                          />
                        </div>
                      </div>
                    </Panel>
                  );
                })}
                {/* <Panel
                  header={eventDate}
                  key="1"
                  className="site-collapse-custom-panel"
                  extra={<img src={bin} alt="bin" />}
                >
                  <p>{text}</p>
                </Panel>
                <Panel
                  header={eventDate}
                  key="2"
                  className="site-collapse-custom-panel"
                  extra={<img src={bin} alt="bin" />}
                >
                  <p>{accordianForm}</p>
                </Panel>
                <Panel
                  header={eventDate}
                  className="site-collapse-custom-panel"
                  extra={<img src={bin} alt="bin" />}
                >
                  <p>{text}</p>
                </Panel>
                <Panel
                  header={eventDate}
                  key="4"
                  className="site-collapse-custom-panel"
                  extra={<img src={bin} alt="bin" />}
                >
                  <p>{accordianForm}</p>
                </Panel>
                <Panel
                  header={eventDate}
                  key="5"
                  className="site-collapse-custom-panel"
                  extra={<img src={bin} alt="bin" />}
                >
                  <p>{text}</p>
                </Panel> */}
              </Collapse>
            </div>
            {/* <div className="col-3">
              <Button className="px-2 button-pink rounded">
                + Add Days with different times
              </Button>
            </div> */}
          </div>
        </section>

        {/* section event setting */}
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Settings</h6>
            </div>
          </div>
          <div className="row mt-4">
            <div className={Tablet ? "col-12 text-start" : "col-6"}>
              <div className="row">
                <div className="col-2"></div>
                <div
                  className={
                    Tablet ? "col-3 text-start mt-1" : "col-4 text-end mt-1"
                  }
                >
                  <label className="text-secondary">Registration</label>
                  <Switch
                    disabled
                    readOnly
                    defaultChecked={
                      data?.event_settings_details?.is_registration_active
                    }
                    className="mx-2"
                  />
                </div>
                {/* <div className="col-2 text-start">
                  <Button className="px-3 button-pink rounded">
                    Settings
                  </Button>
                </div> */}
              </div>
            </div>
            <div className={Tablet ? "col-12 mt-4 text-start" : "col-6 "}>
              <div className="row">
                <div className={Tablet ? "col-2" : "col-1"}></div>
                <div
                  className={
                    Tablet ? "col-3 text-start mt-1" : "col-4 text-end mt-1"
                  }
                >
                  <label className="text-secondary">Ticker Tape</label>
                  <Switch
                    disabled
                    readOnly
                    defaultChecked={
                      data?.event_settings_details?.is_ticker_tape_active
                    }
                    className="mx-2 invisible"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className={Tablet ? "col-12 mt-2 text-start" : "col-6 "}>
              <div className="row">
                <div className="col-2"></div>
                <div
                  className={
                    Tablet ? "col-3 text-start mt-1" : "col-4 text-end mt-1"
                  }
                >
                  <label className="text-secondary">Brand Tier</label>
                  <Switch
                    disabled
                    readOnly
                    defaultChecked={
                      data?.event_settings_details?.is_brand_tier_active
                    }
                    className="mx-2 invisible"
                  />
                </div>
                {/* <div className="col-2 text-start">
                  <Button className="px-3 button-pink rounded">
                    Settings
                  </Button>
                </div> */}
              </div>
            </div>
            <div className={Tablet ? "col-12 mt-4 text-start" : "col-6 "}>
              <div className="row">
                <div className={Tablet ? "col-2" : "col-1"}></div>
                <div
                  className={
                    Tablet ? "col-3 text-start mt-1" : "col-4 text-end mt-1"
                  }
                >
                  <label className="text-secondary">Expo Halls</label>
                  <Switch
                    disabled
                    readOnly
                    defaultChecked={
                      data?.event_settings_details?.is_expo_hall_active
                        ? true
                        : false
                    }
                    className="mx-2"
                  />
                </div>
                <div className="col-3">
                  <div
                    className="btn-group mx-auto border-gray event-editor-increment-btn  w-100 rounded"
                    role="group"
                    aria-label="Second group"
                  >
                    <button type="button" className="btn  py-1 ">
                      -
                    </button>
                    <button type="button" className="btn  py-1 ">
                      {data?.event_settings_details?.no_of_expo_halls ?? 0}
                    </button>
                    <button type="button" className="btn  py-1 ">
                      +
                    </button>
                  </div>
                </div>
                {/* <div className="col-4 text-start">
                  <Button
                    type="primary"
                    className=" button-pink rounded"
                    icon={
                      <EditOutlined
                        style={{ fontSize: "18px", padding: "2px 0px" }}
                      />
                    }
                  >
                    Edit Hall Plans
                  </Button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className={Tablet ? "col-12 text-start" : "col-6 "}>
              <div className="row">
                <div className="col-2"></div>
                <div
                  className={
                    Tablet ? "col-4 text-start mt-1" : "col-4 text-end mt-1"
                  }
                >
                  <label className="text-secondary">Navigation Bar</label>
                  <Switch
                    disabled
                    readOnly
                    defaultChecked={
                      data?.event_settings_details?.is_navigation_bar_active
                    }
                    className="mx-2"
                  />
                </div>
              </div>
            </div>
            <div className={Tablet ? "col-12 mt-4 text-start" : "col-6 "}>
              <div className="row">
                <div className={Tablet ? "col-2" : "col-1"}></div>
                <div
                  className={
                    Tablet ? "col-4 text-start mt-1" : "col-4 text-end mt-1"
                  }
                >
                  <label className="text-secondary">Main Auditorium</label>
                  <Switch
                    disabled
                    readOnly
                    defaultChecked={
                      data?.event_settings_details?.is_main_hall_active
                    }
                    className="mx-2"
                  />
                </div>
                <div className={Tablet ? " col-2" : "col-3"}></div>
                {/* <div className="col-4 text-start">
                  <Button
                    type="primary"
                    className=" button-pink rounded"
                    icon={<CalenderRed />}
                  >
                    <span className="mx-2">Calender</span>
                  </Button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className={Tablet ? "col-12 text-start" : "col-6 "}>
              <div className="row">
                <div className="col-2"></div>
                <div
                  className={
                    Tablet ? "col-4 text-start mt-1" : "col-4 text-end mt-1"
                  }
                >
                  <label className="text-secondary">Reception</label>
                  <Switch
                    disabled
                    readOnly
                    defaultChecked={
                      data?.event_settings_details?.is_reception_active
                    }
                    className="mx-2"
                  />
                </div>
                {/* <div className="col-2 text-start">
                  <Button className="px-3 button-pink rounded">
                    Settings
                  </Button>
                </div> */}
              </div>
            </div>
            <div className={Tablet ? "col-12 mt-4 text-start" : "col-6 "}>
              <div className="row">
                <div className={Tablet ? "col-2" : "col-1"}></div>
                <div
                  className={
                    Tablet ? "col-4 text-start mt-1" : "col-4 text-end mt-1"
                  }
                >
                  <label className="text-secondary">Sat. Auditoriums</label>
                  <Switch
                    disabled
                    readOnly
                    defaultChecked={
                      data?.event_settings_details?.is_sat_auditorium_active
                        ? true
                        : false
                    }
                    className="mx-2"
                  />
                </div>
                <div className="col-3">
                  <div
                    className="btn-group mx-auto border-gray event-editor-increment-btn  w-100 rounded"
                    role="group"
                    aria-label="Second group"
                  >
                    <button type="button" className="btn  py-1 ">
                      -
                    </button>
                    <button type="button" className="btn  py-1 ">
                      {data?.event_settings_details?.no_of_sat_auditorium ?? 0}
                    </button>
                    <button type="button" className="btn  py-1 ">
                      +
                    </button>
                  </div>
                </div>
                {/* <div className={Tablet ? "col-3" : "col-4 text-start"}>
                  <Button
                    type="primary"
                    className=" button-pink rounded"
                    icon={<CalenderRed />}
                  >
                    <span className="mx-2">Calender</span>
                  </Button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className={Tablet ? "col-12 text-start" : "col-6 "}>
              <div className="row">
                <div className="col-2"></div>
                <div
                  className={
                    Tablet ? "col-4 text-start mt-1" : "col-4 text-end mt-1"
                  }
                >
                  <label className="text-secondary">Social Wall</label>
                  <Switch
                    disabled
                    readOnly
                    defaultChecked={
                      data?.event_settings_details?.is_social_wall_active
                    }
                    className="mx-2"
                  />
                </div>
                {/* <div className="col-2">
                  <Button className="px-3 button-pink rounded">
                    Settings
                  </Button>
                </div> */}
                {/* <div className="col-lg-2 col-md-3 text-start ">
                  <Button
                    type="primary"
                    className="button-pink rounded mx-3 px-1"
                    icon={<CalenderRed />}
                  >
                    <span className="mx-2">Sponser Calender</span>
                  </Button>
                </div> */}
              </div>
            </div>
            <div className={Tablet ? "col-12 text-start mt-4" : "col-6 "}>
              <div className="row">
                <div className={Tablet ? "col-2" : "col-1"}></div>
                <div
                  className={
                    Tablet ? "col-4 text-start mt-1" : "col-4 text-end mt-1"
                  }
                >
                  <label className="text-secondary">Networking</label>
                  <Switch
                    disabled
                    readOnly
                    defaultChecked={
                      data?.event_settings_details?.is_networking_active
                        ? true
                        : false
                    }
                    className="mx-2"
                  />
                </div>
                <div className="col-3">
                  <div
                    className="btn-group mx-auto border-gray event-editor-increment-btn  w-100 rounded"
                    role="group"
                    aria-label="Second group"
                  >
                    <button type="button" className="btn  py-1 ">
                      -
                    </button>
                    <button type="button" className="btn  py-1 ">
                      {data?.event_settings_details?.no_of_networking ?? 0}
                    </button>
                    <button type="button" className="btn  py-1 ">
                      +
                    </button>
                  </div>
                </div>
                {/* <div className={Tablet ? "col-3" : "col-4 text-start"}>
                  <Button
                    type="primary"
                    className=" button-pink rounded"
                    icon={<CalenderRed />}
                  >
                    <span className="mx-2">Calender</span>
                  </Button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="row mt-4 border-bottom-gray pb-4">
            <div className={Tablet ? "col-12 text-start" : "col-6 "}>
              <div className="row">
                <div className="col-2"></div>
                <div
                  className={
                    Tablet ? "col-4 text-start mt-1" : "col-4 text-end mt-1"
                  }
                >
                  <label className="text-secondary">Concourse</label>
                  <Switch
                    disabled
                    readOnly
                    defaultChecked={
                      data?.event_settings_details?.is_concourse_active
                    }
                    className="mx-2"
                  />
                </div>
                <div className="col-2">
                  {/* <Button className="w-100 button-pink rounded">Settings </Button> */}
                </div>
                {/* <div className="col-2 text-start">
                  <Button
                    type="primary"
                    className="button-pink rounded mx-3 px-1"
                    icon={<CalenderRed />}
                  >
                    <span className="mx-2">Sponser Calender</span>
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="col-6"></div>
          </div>
        </section>

        {/* section Pre-event message */}
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Pre-event Message</h6>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Active</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  disabled
                  readOnly
                  defaultChecked={
                    data?.pre_event_messages?.pre_event_message_active
                  }
                  className=" mr-0"
                />
              </span>
              <span className="mx-3">
                NB: This video will switch OFF at the start of the event (even
                if set to ‘Active’)
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Force on 1st visit</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  disabled
                  readOnly
                  defaultChecked={
                    data?.pre_event_messages?.pre_event_message_forced
                  }
                  className=" mr-0"
                />
              </span>
              <span className="mx-3">
                NB: If on, the user will not be able to skip the first view of
                the video
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Title</span>
            </div>
            <div className="col-10 ">
              <Input
                readOnly
                placeholder="Enter Title Here"
                defaultValue={data?.pre_event_messages?.pre_event_message_title}
                className="w-50 fs-bold-14"
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Description</span>
            </div>
            <div className="col-10 ">
              <TextArea
                rows={4}
                className="w-50 fs-bold-14"
                placeholder="Enter a description here"
                defaultValue={
                  data?.pre_event_messages.pre_event_message_description
                }
                // disabled
                readOnly
              />
            </div>
          </div>

          <div className="row mt-4 pb-4 border-bottom-gray">
            <div className="col-2 text-end">
              <span className="text-secondary ">Video/Image Upload</span>
            </div>
            <div className="col-3">
              {data && data?.pre_event_messages?.pre_event_media_url ? (
                isImage(data.pre_event_messages.pre_event_media_url) ? (
                  <img
                    src={data && data?.pre_event_messages?.pre_event_media_url}
                    alt="Pre Event Media File Preview"
                    className="img-fluid"
                    style={{ height: "60px", width: "60px" }}
                  />
                ) : (
                  <div className="d-flex justify-content-center align-content-center">
                    <ReactPlayer
                      playing={true}
                      url={data.pre_event_messages.pre_event_media_url}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                )
              ) : (
                <h5 className="fs-pink">File not uploaded</h5>
              )}
            </div>
            {/* <div className="col-10 ">
              <Upload {...props}>
                <Button className=" rounded">
                  <img
                     src={data?.pre_event_messages?.pre_event_media_url || cloudComputing}
                    alt="cloudcomputing"
                    className="mx-1"
                  />
                  Video / Image Upload
                </Button>
              </Upload>
            </div> */}
          </div>
        </section>

        {/* section Welcome message */}

        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Welcome Message</h6>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Active</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  disabled
                  readOnly
                  defaultChecked={
                    data?.welcome_messages?.welcome_message_active
                  }
                  className=" mr-0"
                />
              </span>
              <span className="mx-3">
                {/* NB: This video will switch OFF at the start of the event (even
                if set to ‘Active’) */}
                NB: This video will switch ON at the start of the event (if set
                to 'Active')
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Force on 1st visit</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  disabled
                  readOnly
                  defaultChecked={
                    data?.welcome_messages?.welcome_message_forced
                  }
                  className=" mr-0"
                />
              </span>
              <span className="mx-3">
                NB: If on, the user will not be able to skip the first view of
                the video
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Title</span>
            </div>
            <div className="col-10 ">
              <Input
                readOnly
                defaultValue={data?.welcome_messages?.welcome_message_title}
                placeholder="Enter Title Here"
                className="w-50 fs-bold-14"
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Description</span>
            </div>
            <div className="col-10 ">
              <TextArea
                rows={4}
                className="w-50 fs-bold-14"
                placeholder="Enter a description here"
                defaultValue={
                  data?.welcome_messages?.welcome_message_description
                }
                readOnly
              />
            </div>
          </div>

          <div className="row mt-4 pb-4 border-bottom-gray">
            <div className="col-2 text-end">
              <span className="text-secondary ">Video/Image Upload</span>
            </div>
            <div className="col-3">
              {data && data?.welcome_messages?.welcome_media_url ? (
                isImage(data.welcome_messages.welcome_media_url) ? (
                  <img
                    src={data && data?.welcome_messages?.welcome_media_url}
                    alt="Welcome Media File Preview"
                    className="img-fluid"
                    style={{ height: "60px", width: "60px" }}
                  />
                ) : (
                  <div className="d-flex justify-content-center align-content-center">
                    <ReactPlayer
                      playing={true}
                      url={data.welcome_messages.welcome_media_url}
                    />
                  </div>
                )
              ) : (
                <h5 className="fs-pink">File not uploaded</h5>
              )}
            </div>
          </div>
        </section>

        {/* section Event Support */}

        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Support</h6>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Chat</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  disabled
                  readOnly
                  defaultChecked={data?.event_support?.is_chat_active}
                />
              </span>
            </div>
          </div>

          <div className="row mt-4 pb-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Video Call</span>
            </div>
            <div className="col-10  ">
              <span>
                <Switch
                  disabled
                  readOnly
                  defaultChecked={data?.event_support?.is_video_call_active}
                />
              </span>
            </div>
          </div>

          <div className="row mt-4 event-editor-dropdown-div">
            <div className="col-2 text-end mt-4">
              <span className="text-secondary mt-4">Agents</span>
            </div>

            <div
              className={Tablet ? "col-5" : "col-4"}
              id="area"
              style={{ position: "relative" }}
            >
              <label className="py-2 mx-2">
                <b>All Agents</b>
              </label>
              <br />
              <Select
                showSearch
                optionFilterProp="children"
                // defaultValue={
                //   data?.event_supports?.[0]?.event_support_agents?.[0]?.user
                //     ?.first_name +
                //   " " +
                //   data?.event_supports?.[0]?.event_support_agents?.[0]?.user
                //     ?.last_name
                // }
                style={{ width: 300 }}
                placeholder="Search Agents"
                open={true}
                listItemHeight={4}
                listHeight={130}
                className="border border-gray fs-bold-14"
                getPopupContainer={() => document.getElementById("area")}
                value="All Agents"
              >
                {agents &&
                  agents?.map((data) => {
                    return (
                      <Option className="select-text-dark">
                        {data?.first_name} {data?.last_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>

            <div className={Tablet ? "col-5" : "col-4"}>
              <label className="py-2 mx-2">
                <b>Tagged Agents</b>
              </label>
              <br />
              {/* <div className="event-editor-dropdown-background-gray rounded w-75  p-2">
                <Input className="w-100 my-1" disabled
                  defaultValue={data?.event_supports?.[0]?.event_support_agents?.[0]?.user?.first_name  +" "+ data?.event_supports?.[0]?.event_support_agents?.[0]?.user?.last_name } 
                 />
                <List
                  className="event-editor-tagged-agents-div"
                  itemLayout="horizontal"
                 
                    dataSource={data?.event_supports?.[0]?.event_support_agents}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <span className="d-flex justify-content-between bg-white m-0 p-2 text-danger ">
                            <span>{item?.user?.first_name} {item.user?.last_name}</span>
                          </span>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div> */}

              <div className="event-editor-dropdown-background-gray rounded w-75  p-2">
                <Select
                  showSearch
                  optionFilterProp="children"
                  // defaultValue={
                  //   data?.event_supports?.[0]?.event_support_agents?.[0]?.user
                  //     ?.first_name +
                  //   " " +
                  //   data?.event_supports?.[0]?.event_support_agents?.[0]?.user
                  //     ?.last_name
                  // }
                  style={{ width: 300 }}
                  placeholder="Search Tagged Agents"
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() => document.getElementById("area")}
                  value="Tagged Agents"
                >
                  {selectedAgents.map((val, idx) => {
                    return (
                      <Option key={idx} className="w-100">
                        {val.first_name} {val?.last_name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>

          <div className="row mt-4 border-bottom-gray pb-4">
            <div className="col-2"></div>
          </div>
        </section>

        {/* section Technical Support */}

        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Technical Support</h6>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Chat</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  disabled
                  readOnly
                  defaultChecked={data?.event_technical_support?.is_chat_active}
                />
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Video Call</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  disabled
                  readOnly
                  defaultChecked={
                    data?.event_technical_support?.is_video_call_active
                  }
                />
              </span>
            </div>
          </div>

          <div className="row mt-4 event-editor-dropdown-div">
            <div className="col-2 text-end mt-4">
              <span className="text-secondary mt-4">Agents</span>
            </div>

            <div
              className={Tablet ? "col-5" : "col-4"}
              id="area"
              style={{ position: "relative" }}
            >
              <label className="py-2 mx-2">
                <b>All Agents</b>
              </label>
              <br />
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: 300 }}
                placeholder="Search to Select"
                open={true}
                listItemHeight={4}
                listHeight={130}
                className="border border-gray fs-bold-14"
                getPopupContainer={() => document.getElementById("area")}
                value="All Agents"
              >
                {/* {data.event_supports?.[1]?.event_support_agents?.map((val, idx) => (
                  <Option  value={idx} className="w-100">
                    
                    {val?.user?.first_name} {val?.user?.last_name}
                  </Option>
                ))} */}
                {agentsTechnical &&
                  agentsTechnical?.map((data) => {
                    return (
                      <Option className="select-text-dark">
                        {data?.first_name} {data?.last_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>

            <div className={Tablet ? "col-5" : "col-4"}>
              <label className="py-2 mx-2">
                <b>Tagged Agents</b>
              </label>
              <br />
              {/* <div className="event-editor-dropdown-background-gray rounded w-75 event-editor-tagged-agents-div p-2">
                <Input className="w-100 my-1"
                disabled
                defaultValue={data?.event_supports?.[1]?.event_support_agents?.[0]?.user?.first_name  +" "+ data?.event_supports?.[0]?.event_support_agents?.[0]?.user?.last_name } />
                <List
                  itemLayout="horizontal"
                  dataSource={data?.event_supports?.[1]?.event_support_agents}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <span className="d-flex justify-content-between bg-white m-0 p-2 text-danger ">
                            <span>{item?.user?.first_name} {item.user?.last_name}</span>
                            
                          </span>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div> */}
              {/* <div className="event-editor-dropdown-background-gray rounded w-75 event-editor-tagged-agents-div p-2"> */}
              <div className="event-editor-dropdown-background-gray rounded w-75 p-2">
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: 300 }}
                  placeholder="Search to Select"
                  open={true}
                  listItemHeight={4}
                  listHeight={130}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() => document.getElementById("area")}
                  value="Tagged Agents"
                >
                  {selectedAgentsTechnical.map((val, idx) => {
                    return (
                      <Option key={idx} className="w-100">
                        {val.first_name} {val?.last_name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>

          <div className="row mt-4 border-bottom-gray pb-4">
            <div className="col-2"></div>
            {/* <div className="col-4">
              <Button className="px-3 fs-pink border-pink rounded">
                <img src={addGroupRed} alt="add group" className="img-fluid" />
                <span className="mx-2">Invite Agents</span>
              </Button>
              <Button
                className={
                  Tablet
                    ? "px-3 fs-pink border-pink rounded mt-2 "
                    : "px-3 fs-pink border-pink rounded mx-2"
                }
              >
                <img
                  src={calendarRed}
                  alt="add group"
                  className="img-fluid border-danger"
                />
                <span className="mx-2">Staffing Calendar</span>
              </Button>
            </div> */}
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Controls Help</h6>
            </div>
          </div>
          <div className="row mt-4 pb-4 mb-4 border-bottom-gray">
            <div className="col-2 text-end">
              <span className="text-secondary ">Upload</span>
            </div>
            <div className="col-3">
              {data && data?.control_media_url ? (
                isImage(data?.control_media_url) ? (
                  <img
                    src={data && data?.control_media_url}
                    alt="Control Media File Preview"
                    className="img-fluid"
                    style={{ height: "60px", width: "60px" }}
                  />
                ) : (
                  <div className="d-flex justify-content-center align-content-center">
                    <ReactPlayer playing={true} url={data?.control_media_url} />
                  </div>
                )
              ) : (
                <h5 className="fs-pink">File not uploaded</h5>
              )}
            </div>
          </div>
        </section>

        {/* section Event survey */}

        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Feedback Survey</h6>
            </div>
          </div>
          <div className="row border-bottom-gray pb-4">
            <div className="col-12 mt-1 pb-4">
              <span className="fs-16">
                NB: This survey will be available to all users in the "Feedback"
                section.
              </span>
            </div>
            <div className="col-2 text-end">
              <label className="text-secondary">Select Survey</label>
            </div>
            <div className="col-4">
              <Select
                readOnly
                disabled
                defaultValue={data.survey?.survey_title || ""}
                className="w-100 fs-bold-14"
              ></Select>
            </div>
          </div>
        </section>
      </div>
    );
  } else return null;
};

export default EventEditor;
