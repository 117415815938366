import React, { useState, useEffect, useRef } from "react";
import "./survey.css";
import {
  Breadcrumb,
  Select,
  Input,
  Divider,
  Button,
  Switch,
  Row,
  Col,
  Checkbox
} from "antd";
import { FastField, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addSurvey } from "../../redux/slice/surveyManagement";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ApiCall } from "../../utils/api";
import * as Yup from "yup";
import SimpleReactValidator from "simple-react-validator";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { Bin } from "../../components/svgJS/Bin";
import { clone, cloneDeep } from "lodash";
import { user } from "../../components/Images/Images";



const AddNewSurvey = props => {
  console.log("props in survey", props);
  var CryptoJS = require("crypto-js");
  const { Option } = Select;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.surveyManagement);
  const [userType, setUserType] = useState("");
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const validator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  let [id, setId] = useState(0);
  const [eventId, setEventId] = useState(null);
  const [loadingSurvey, setLoadingSurvey] = useState(false);
  const [questionArray, setquestionArray] = useState([
    {
      question_type: "TOGGLE",
      answer_details: [{ option: "", is_default_selected: false }],
      chart_type: "BAR",
      display_chart: false
    }
  ]);
  const [eventList, seteventList] = useState(null);


  const [inititalState, setInitialState] = useState({
    event_id: props?.event_id ?? null,
    survey_title: "",
    survey_description: "",
    survey_type:
      props?.isFromEventSecondStep == true
        ? "REGISTRATION"
        : props?.isFromEvent == true
          ? "EVENT" : null,
    question_details: questionArray
  })

  useEffect(() => {
    setLoadingSurvey(true)
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach(element => {
      if (element.route == window.location.pathname) {
        is_route_present = true
      }
    });

    if (is_route_present === false && decryptedData === "AGENT" || decryptedData === "DELEGATE") {
      window.location.href = '/pagenotfound'
    }

    getEventsList();
    setTimeout(() => {
      setLoadingSurvey(false);
    }, 1000);

  }, []);

  useEffect(() => {
    console.log("userType in survey", userType);
    console.log("eventId", eventId);
    if (userType === 'BRAND ADMIN' && eventId) {
      setInitialState({ ...inititalState, survey_type: "BRAND/ZONE", event_id: eventId })
    }
  }, [userType, eventId])

  const getEventsList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", null)
      .then(response => {
        const { rows } = response.data.data;
        seteventList(rows);
        if (rows.length == 1) {
          // setInitialState({ ...inititalState, event_id: rows[0]?.event_id });
          setEventId(rows[0]?.event_id)
        }
      })
      .catch(err => {
        console.error(err);
      });
  };


  const addAnotherAnswer = key => {
    let question_array = questionArray;

    question_array[key].answer_details.push({
      option: "",
      is_default_selected: false
    });
    setquestionArray([...question_array]);
  };

  const addAnotherQuestion = () => {
    let question_array = questionArray;
    question_array.push({
      question_type: "",
      answer_details: [{ option: "", is_default_selected: false }],
      chart_type: "BAR",
      display_chart: false
    });
    setquestionArray([...question_array]);
  };

  const handleQuestionArrChange = (val, key, name) => {
    let question_array = questionArray;
    question_array[key][[name]] = val;
    setquestionArray([...question_array]);
  };

  const handleQuestionArrAnswerChange = (val, key, index, name) => {
    let question_array = questionArray;
    question_array[key].answer_details[index][[name]] = val;
    setquestionArray([...question_array]);
  };

  const handleAddSurvey = async data => {
    setLoadingSurvey(true);
    // console.log("formik data", data);
    // if (validator.current.allValid()) {
    //   if (props.isFromEvent) props.onSuccessFromModal();

    //   alert("Form Submitted");
    // } else {
    //   validator.current.showMessages();
    //   setForceUpdate(!forceUpdate);
    // }

    const sequesArr = questionArray.map((val, index) => {
      return {
        ...val,
        sequence: index + 1
      };
    });

    const finalArr = sequesArr.map((val, index) => {
      if (val.question_type === "TOGGLE") {
        return {
          ...val,
          answer_details: val.answer_details.map(elem => {
            return {
              ...elem,
              is_toggle: true
            };
          })
        };
      } else {
        return val;
      }
    });

    // console.log("Final Arr", finalArr);

    let formData = {
      event_id: data.event_id,
      survey_type: data.survey_type,
      survey_title: data.survey_title,
      survey_description: data.survey_description,
      question_details: finalArr
    };
    console.log(formData);
    const addSurveyResp = await dispatch(addSurvey(formData));
    console.log(addSurveyResp.payload);

    if (addSurveyResp.payload.code === 200) {
      // console.log("200 success");
      toast.success(addSurveyResp.payload.message);
      props?.history?.push("surveyManagement");
      if (props.isFromEvent) props?.onSuccessFromModal();
    }
    if (addSurveyResp.payload.code !== 200) {
      toast.error(addSurveyResp.payload.message);
    }
    setLoadingSurvey(false);
  };
  const ValidationSchema = Yup.object().shape({
    event_id: Yup.number()
      .required("Required")
      .nullable(),
    survey_type: Yup.string()
      .required("Required")
      .nullable(),
    survey_title: Yup.string()
      .required("Required")
      .nullable(),
    survey_description: Yup.string()
      .required("Required")
      .nullable()
  });

  const children = [];
  for (let i = 1; i <= 50; i++) {
    children.push(<Option key={i}>{i}</Option>);
  }

  const handleDeleteAnswer = (key1, key2) => {
    let clonedArr = cloneDeep(questionArray);
    let answers = clonedArr[key1].answer_details;

    answers.forEach((elem, i) => {
      if (key2 === i) {
        answers.splice(i, 1);
      }
    })

    console.log("answers", answers);
    clonedArr[key1].answer_details = answers;
    console.log("clonedArr", clonedArr)

    setquestionArray(clonedArr);

  }

  const handleDeleteSurvey = (key) => {
    let newArr = questionArray.filter((elem, i) => {
      if (i !== key) {
        return elem
      }
    })

    setquestionArray(newArr);
  }



  if (loadingSurvey) {
    return <CommonLoader />
  } else
    return (
      <Formik
        initialValues={inititalState}
        // validationSchema={ValidationSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          console.log("values", values);
          handleAddSurvey(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div>
              {console.log("inititalState", inititalState)}
              <h4 className="fs-24">Add Survey</h4>

              {props.isFromEvent ? (
                ""
              ) : (
                <Breadcrumb className="my-2" separator=">">
                  <Breadcrumb.Item>
                    <Link to="/surveyManagement">Admin Console</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/surveyManagement">Surveys</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/addnewsurvey">Add Survey</Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              )}

              <div className="bg-white px-5 py-3">
                <div className="row my-3">
                  <div className="col-12 mt-4">
                    <h6 className="fs-pink fw-bolder fs-20">Survey Details</h6>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-3 text-end">
                    Select Event
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      style={{ width: 300 }}
                      placeholder="Select Event"
                      optionFilterProp="children"
                      name="event_id"
                      className="fs-bold-14"
                      value={values?.event_id}
                      disabled={props?.event_id ? true : userType === "BRAND ADMIN" ? true : false}
                      onChange={val => setFieldValue("event_id", val)}
                    >
                      {eventList &&
                        eventList.map(data => (
                          <Option value={data.event_id}>{data.event_name}</Option>
                        ))}
                    </Select>
                    {errors.event_id && touched.event_id ? (
                      <div className="text-danger">{errors.event_id}</div>
                    ) : null}
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-3 text-end">
                    Survey Type
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      style={{ width: 300 }}
                      placeholder="Select Type"
                      optionFilterProp="children"
                      name="survey_type"
                      value={values.survey_type}
                      className="fs-bold-14"
                      disabled={props?.isFromEvent == true ? true : userType === "BRAND ADMIN" ? true : false}
                      onChange={value => setFieldValue("survey_type", value)}
                    >
                      {/* <Option value="FEEDBACK">Feedback</Option> */}
                      {userType === "BRAND ADMIN" ? (
                        ""
                      ) : (
                        <Option value="EVENT">Event</Option>
                      )}

                      {userType === "BRAND ADMIN" ? (
                        ""
                      ) : (
                        <Option value="REGISTRATION">Registration</Option>
                      )}

                      <Option value="BRAND/ZONE">Brand/Zone</Option>
                    </Select>
                    {errors.survey_type && touched.survey_type ? (
                      <div className="text-danger">{errors.survey_type}</div>
                    ) : null}
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-3 text-end">
                    Title
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9">
                    <Input
                      name="survey_title"
                      value={values.survey_title}
                      className="w-50 border-gray rounded fs-bold-14"
                      placeholder="End of Show"
                      onChange={handleChange}
                    />
                    {errors.survey_title && touched.survey_title ? (
                      <div className="text-danger">{errors.survey_title}</div>
                    ) : null}
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-3 text-end">
                    Description
                    <sup>
                      <span
                        className="text-danger"
                        style={{ fontSize: "1.1rem" }}
                      >
                        *
                      </span>
                    </sup>
                  </div>
                  <div className="col-9">
                    <TextArea
                      rows={6}
                      className="w-50 fs-bold-14"
                      placeholder="Enter a Description here"
                      value={values.survey_description}
                      name="survey_description"
                      onChange={handleChange}
                    />
                    {errors.survey_description && touched.survey_description ? (
                      <div className="text-danger">
                        {errors.survey_description}
                      </div>
                    ) : null}
                  </div>
                </div>
                <Divider />

                {/* question answer section  */}

                <div className="row my-3">
                  <div className="col-10 mt-4">
                    <h6 className="fs-pink fw-bolder fs-20">Add Questions</h6>
                  </div>
                </div>

                {console.log(questionArray)}
                <div className="question-div d-flex flex-column align-items-center">
                  {questionArray &&
                    questionArray.map((val, key) => (
                      <>
                        <div key={key} className="question_container p-2 my-2">
                          <h5 className="survey_question_number ">{key + 1}</h5>
                          <span onClick={() => handleDeleteSurvey(key)} className="survey_delete_icon cursor-pointer"><Bin /></span>
                          <div className="survey_design">
                            {/* <UnionDesign /> */}

                          </div>
                          <div className="row my-3">
                            <div className="col-3 text-end">Question Type</div>
                            <div className="col-4">
                              <Select
                                showSearch
                                listHeight={120}
                                listItemHeight={4}
                                style={{ width: "100%" }}
                                placeholder="Select"
                                optionFilterProp="children"
                                value={questionArray[key].question_type}
                                name="question_type"
                                className="fs-bold-14"
                                onChange={val =>
                                  handleQuestionArrChange(
                                    val,
                                    key,
                                    "question_type"
                                  )
                                }
                                onBlur={() =>
                                  validator.current.showMessageFor(
                                    "question_type"
                                  )
                                }
                              >
                                <Option value="TOGGLE">Toggle</Option>
                                <Option value="SINGLE SELECT">
                                  Single Tick-box
                                </Option>
                                <Option value="MULTIPLE SELECT">
                                  Multiple Tick-boxes (select multiple)
                                </Option>
                                <Option value="MULTIPLE CHOICE">
                                  Multiple choice (select one)
                                </Option>
                                <Option value="FREEFORM TEXT">Freeform text</Option>
                              </Select>

                              {validator.current.message(
                                "question type",
                                val?.question_type,
                                "required",
                                { className: "text-danger py-1" }
                              )}
                            </div>
                          </div>
                          {val.question_type === "TOGGLE" ? (
                            <>
                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Question Text
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="question_text"
                                    // value={questionArray[key].question}
                                    className="border-gray rounded fs-bold-14"
                                    placeholder="Have you enjoyed the show"
                                    onChange={e =>
                                      handleQuestionArrChange(
                                        e.target.value,
                                        key,
                                        "question_text"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "question_text"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "question_text",
                                    val?.question_text,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>
                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Obligatory on to Procced? (*)
                                </div>
                                <div className="col-4">
                                  <Switch
                                    // checked={questionArray[key].display_chart}
                                    name="obligatory"
                                    onChange={checked =>
                                      // setFieldValue("is_required", !values.is_required)
                                      handleQuestionArrChange(
                                        checked,
                                        key,
                                        "is_required"
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">Default</div>
                                <div className="col-4">
                                  <span>Off label</span>
                                  <Switch
                                    name="default"
                                    className="mx-1"
                                    onChange={checked =>
                                      handleQuestionArrAnswerChange(
                                        checked,
                                        key,
                                        0,
                                        "is_default_selected"
                                      )
                                    }
                                  />
                                  <span>On label</span>
                                </div>
                              </div>
                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  'Off' label text{" "}
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="off_label_text"
                                    className="border-gray rounded fs-bold-14"
                                    placeholder=""
                                    onChange={e =>
                                      handleQuestionArrAnswerChange(
                                        e.target.value,
                                        key,
                                        0,
                                        "offText"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "off_label_text"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "",
                                    val?.offText,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  'On' label text{" "}
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="on_label_text"
                                    className="border-gray rounded fs-bold-14"
                                    placeholder=""
                                    onChange={e =>
                                      handleQuestionArrAnswerChange(
                                        e.target.value,
                                        key,
                                        0,
                                        "onText"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "on_label_text"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "",
                                    val?.onText,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>
                            </>
                          ) : val.question_type === "SINGLE SELECT" ? (
                            <>
                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Question Text
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="question_text"
                                    className="border-gray rounded fs-bold-14"
                                    placeholder="Have you enjoyed the show"
                                    onChange={e =>
                                      handleQuestionArrChange(
                                        e.target.value,
                                        key,
                                        "question_text"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "question_text"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "question_text",
                                    val?.question_text,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>
                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Obligatory on to Procced? (*)
                                </div>
                                <div className="col-4">
                                  <Switch
                                    name="obligatory"
                                    onChange={checked =>
                                      handleQuestionArrChange(
                                        checked,
                                        key,
                                        "is_required"
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">Default</div>
                                <div className="col-4">
                                  <Checkbox
                                    onChange={e => {
                                      console.log("Eeee", e);
                                      handleQuestionArrAnswerChange(
                                        e.target.checked,
                                        key,
                                        0,
                                        "is_default_selected"
                                      );
                                    }}
                                  >
                                    Checkbox
                                  </Checkbox>
                                </div>
                              </div>
                            </>
                          ) : val.question_type === "MULTIPLE SELECT" ? (
                            <>
                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Question Text
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="question_text"

                                    className="border-gray rounded fs-bold-14"
                                    placeholder="Have you enjoyed the show"
                                    onChange={e =>
                                      handleQuestionArrChange(
                                        e.target.value,
                                        key,
                                        "question_text"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "question_text"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "question_text",
                                    val?.question_text,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Minimum Obligatory Answers
                                </div>
                                <div className="col-4">
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    // value={questionArray[key].question_type}
                                    name="min_obligatory"
                                    className="fs-bold-14"
                                    onChange={val => {
                                      let intVal = parseInt(val);
                                      handleQuestionArrChange(
                                        intVal,
                                        key,
                                        "min_obligatory"
                                      );
                                    }}
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "min_obligatory"
                                      )
                                    }
                                  >
                                    <Option value="1">1</Option>
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                    <Option value="4">4</Option>
                                    <Option value="5">5</Option>
                                  </Select>
                                  {validator.current.message(
                                    "min_obligatory",
                                    val?.min_obligatory,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Maximum Answers?
                                </div>
                                <div className="col-4">
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    name="max_obligatory"
                                    className="fs-bold-14"
                                    onChange={val => {
                                      let intVal = parseInt(val);
                                      handleQuestionArrChange(
                                        intVal,
                                        key,
                                        "max_obligatory"
                                      );
                                    }}
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "max_obligatory"
                                      )
                                    }
                                  >
                                    <Option value="1">1</Option>
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                    <Option value="4">4</Option>
                                    <Option value="5">5</Option>
                                  </Select>
                                  {validator.current.message(
                                    "max_obligatory",
                                    val?.max_obligatory,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              {val.answer_details &&
                                val.answer_details.map((elem, index) => (
                                  <>
                                    <div key={index} className="row my-3">
                                      <div className="col-3 text-end">
                                        Answer {index + 1}
                                      </div>
                                      <div className="col-4">
                                        <Input
                                          name="option"
                                          className="border-gray rounded"
                                          placeholder="Yes"

                                          onChange={e =>
                                            handleQuestionArrAnswerChange(
                                              e.target.value,
                                              key,
                                              index,
                                              "option"
                                            )
                                          }
                                          onBlur={() =>
                                            validator.current.showMessageFor(
                                              "option"
                                            )
                                          }
                                        />
                                        {validator.current.message(
                                          "answer",
                                          elem?.option,
                                          "required",
                                          { className: "text-danger py-1" }
                                        )}
                                      </div>
                                      <div className="col-1">
                                        <Checkbox
                                          onChange={e => {
                                            handleQuestionArrAnswerChange(
                                              e.target.checked,
                                              key,
                                              index,
                                              "is_default_selected"
                                            );
                                          }}
                                        />

                                      </div>
                                      <div className="col-1" onClick={() => handleDeleteAnswer(key, index)}>
                                        <Bin className="" />
                                      </div>
                                    </div>

                                  </>
                                ))}

                              <div className="row my-3">
                                <div className="col-3 text-end"></div>
                                <div className="col-4">
                                  <Button
                                    type="primary"
                                    onClick={() => addAnotherAnswer(key)}
                                  >
                                    + Add Another Answer
                                  </Button>
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Display Chart ?
                                </div>
                                <div className="col-4">
                                  <Switch
                                    // checked={questionArray[key].display_chart}
                                    name="display_chart"
                                    onChange={checked =>
                                      // setFieldValue("is_required", !values.is_required)
                                      handleQuestionArrChange(
                                        checked,
                                        key,
                                        "display_chart"
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">Chart Type ?</div>
                                <div className="col-4">
                                  <span className="mx-1">Bar Chart</span>
                                  <Switch
                                    // checked={questionArray[key].display_chart}
                                    name="chart_type"
                                    onChange={checked =>
                                      // setFieldValue("is_required", !values.is_required)
                                      handleQuestionArrChange(
                                        checked === true ? "PIE" : "BAR",
                                        key,
                                        "chart_type"
                                      )
                                    }
                                  />
                                  <span className="mx-1">Pie Chart</span>
                                </div>
                              </div>
                            </>
                          ) : val.question_type === "MULTIPLE CHOICE" ? (
                            <>
                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Question Text
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="question_text"
                                    // value={questionArray[key].question}
                                    className="border-gray rounded fs-bold-14"
                                    placeholder="Have you enjoyed the show"
                                    onChange={e =>
                                      handleQuestionArrChange(
                                        e.target.value,
                                        key,
                                        "question_text"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "question_text"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "question_text",
                                    val?.question_text,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Quiz or Survey?
                                </div>
                                <div className="col-4">
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    // value={questionArray[key].question_type}
                                    name="quiz_or_survey"
                                    className="fs-bold-14"
                                    onChange={val =>
                                      handleQuestionArrChange(
                                        val,
                                        key,
                                        "multi_select_question_type"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "quiz_or_survey"
                                      )
                                    }
                                  >
                                    <Option value="QUIZ">Quiz</Option>
                                    <Option value="SURVEY">Survey</Option>
                                  </Select>
                                  {validator.current.message(
                                    "quiz_or_survey",
                                    val?.multi_select_question_type,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              {val.answer_details &&
                                val.answer_details.map((elem, index) => (
                                  <>
                                    <div className="row my-3">
                                      <div className="col-3 text-end">
                                        Answer {index + 1}
                                      </div>
                                      <div className="col-4">
                                        <Input
                                          name="option"
                                          value={elem.option}
                                          className="border-gray rounded"
                                          placeholder="Yes"
                                          onChange={e =>
                                            handleQuestionArrAnswerChange(
                                              e.target.value,
                                              key,
                                              index,
                                              "option"
                                            )
                                          }
                                          onBlur={() =>
                                            validator.current.showMessageFor(
                                              "option"
                                            )
                                          }
                                        />
                                        {validator.current.message(
                                          "answer",
                                          elem?.option,
                                          "required",
                                          { className: "text-danger py-1" }
                                        )}
                                      </div>
                                      <div className="col-2">
                                        {val?.multi_select_question_type ===
                                          "QUIZ" ? (
                                          <>
                                            <Checkbox

                                              onChange={e => {

                                                handleQuestionArrAnswerChange(
                                                  e.target.checked,
                                                  key,
                                                  index,
                                                  "is_default_selected"
                                                )
                                              }
                                              }
                                            />
                                            <span className="fs-pink ms-2">
                                              Correct
                                            </span>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-1" onClick={() => handleDeleteAnswer(key, index)}>
                                        <Bin className="" />
                                      </div>
                                    </div>
                                  </>
                                ))}

                              <div className="row my-3">
                                <div className="col-3 text-end"></div>
                                <div className="col-4">
                                  <Button
                                    type="primary"
                                    onClick={() => addAnotherAnswer(key)}
                                  >
                                    + Add Another Answer
                                  </Button>
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Display Chart ?
                                </div>
                                <div className="col-4">
                                  <Switch
                                    // checked={questionArray[key].display_chart}
                                    name="display_chart"
                                    onChange={checked =>
                                      // setFieldValue("is_required", !values.is_required)
                                      handleQuestionArrChange(
                                        checked,
                                        key,
                                        "display_chart"
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">Chart Type ?</div>
                                <div className="col-4">
                                  <span className="mx-1">Bar Chart</span>
                                  <Switch
                                    // checked={questionArray[key].display_chart}
                                    name="chart_type"
                                    onChange={checked =>
                                      // setFieldValue("is_required", !values.is_required)
                                      handleQuestionArrChange(
                                        checked === true ? "PIE" : "BAR",
                                        key,
                                        "chart_type"
                                      )
                                    }
                                  />
                                  <span className="mx-1">Pie Chart</span>
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  One Question per Page?
                                </div>
                                <div className="col-4">
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    name="ques_per_page"
                                    className="fs-bold-14"
                                    onChange={val =>
                                      handleQuestionArrChange(
                                        val,
                                        key,
                                        "one_question_per_page"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "ques_per_page"
                                      )
                                    }
                                  >
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                  </Select>
                                  {validator.current.message(
                                    "ques_per_page",
                                    val?.one_question_per_page,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>
                            </>
                          ) : val.question_type === "FREEFORM TEXT" ? (
                            <>
                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Question Text
                                </div>
                                <div className="col-4">
                                  <Input
                                    name="question_text"
                                    className="border-gray rounded fs-bold-14"
                                    placeholder="Have you enjoyed the show"
                                    onChange={e =>
                                      handleQuestionArrChange(
                                        e.target.value,
                                        key,
                                        "question_text"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "question_text"
                                      )
                                    }
                                  />
                                  {validator.current.message(
                                    "question_text",
                                    val?.question_text,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Obligatory on to Procced? (*)
                                </div>
                                <div className="col-4">
                                  <Switch
                                    // checked={questionArray[key].display_chart}
                                    name="obligatory"
                                    onChange={checked =>
                                      // setFieldValue("is_required", !values.is_required)
                                      handleQuestionArrChange(
                                        checked,
                                        key,
                                        "is_required"
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">Format</div>
                                <div className="col-4">
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    name="format"
                                    className="fs-bold-14"
                                    onChange={val =>
                                      handleQuestionArrChange(
                                        val,
                                        key,
                                        "format"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor("format")
                                    }
                                  >
                                    <Option value="MULTI LINE">
                                      Multiple line
                                    </Option>
                                    <Option value="SINGLE LINE">
                                      Single line
                                    </Option>
                                  </Select>
                                  {validator.current.message(
                                    "format",
                                    val?.format,
                                    "required",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>

                              <div className="row my-3">
                                <div className="col-3 text-end">
                                  Character Limit
                                </div>
                                <div className="col-4">
                                  <Input
                                    placeholder="Enter Value"
                                    style={{ width: "100%" }}
                                    name="character_limit"
                                    className="fs-bold-14"
                                    onChange={e =>
                                      handleQuestionArrAnswerChange(
                                        e.target.value,
                                        key,
                                        0,
                                        "format_line_character_limit"
                                      )
                                    }
                                    onBlur={() =>
                                      validator.current.showMessageFor(
                                        "character_limit"
                                      )
                                    }
                                  />

                                  {validator.current.message(
                                    "character_limit",
                                    val?.answer_details?.[0]
                                      ?.format_line_character_limit,
                                    "required|number",
                                    { className: "text-danger py-1" }
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ))}
                </div>
                <div className="row my-2">
                  <div className="col-2"></div>

                  <div className="col-2 mt-4">
                    <Button
                      className=" px-3 d-block button-pink rounded"
                      onClick={addAnotherQuestion}
                    >
                      + Add Another Question
                    </Button>
                  </div>
                </div>
              </div>

              <div
                className="shadow-sm p-3 d-flex justify-content-between"
                style={{ backgroundColor: "#eeeeee" }}
              >
                {props.onCancelFromModal ? (
                  <Button
                    onClick={() => props.onCancelFromModal()}
                    className="rounded  px-3"
                  >
                    Cancel
                  </Button>
                ) : (
                  <Link to={`surveymanagement`}>
                    <Button className="px-3 d-block button-pink rounded">
                      Cancel
                    </Button>
                  </Link>
                )}

                <Button htmlType="submit" type="primary" disabled={loading}>
                  {!loading ? (
                    "Save Survey"
                  ) : (
                    <div style={{ width: "70px" }}>
                      <Spin indicator={loadingIcon} />
                    </div>
                  )}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
};

export default AddNewSurvey;
