import React, { useState, useEffect, Fragment } from "react";
import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "./brandCalendar.css";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getBrandCalendar } from "./../../redux/slice/brandCalendar";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import SearchBar from "../common/SearchBar";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { Modal, Breadcrumb } from "antd";
import ProductZoneSession from "./popups/ProductZoneSessionPopup";
import ProductZoneChat from "./popups/ProductZoneChat";
import _ from "lodash";

const BrandCalendarUI = ({ match }) => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [showZoneChatPopup, setShowZoneChatPopup] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const [timeWiseEvents, setTimeWiseEvents] = useState([
    {
      startTime: "12:00 AM",
      startHours: 0,
      endTime: "1:00 AM"
    },
    {
      startTime: "1:00 AM",
      startHours: 1,
      endTime: "2:00 AM"
    },
    {
      startTime: "2:00 AM",
      startHours: 2,
      endTime: "2:00 AM"
    },
    {
      startTime: "3:00 AM",
      startHours: 3,
      endTime: "4:00 AM"
    },
    {
      startTime: "4:00 AM",
      startHours: 4,
      endTime: "5:00 AM"
    },
    {
      startTime: "5:00 AM",
      startHours: 5,
      endTime: "6:00 AM"
    },
    {
      startTime: "6:00 AM",
      startHours: 6,
      endTime: "7:00 AM"
    },
    {
      startTime: "7:00 AM",
      startHours: 7,
      endTime: "8:00 AM"
    },
    {
      startTime: "8:00 AM",
      startHours: 8,
      endTime: "9:00 AM"
    },
    {
      startTime: "9:00 AM",
      startHours: 9,
      endTime: "10:00 AM"
    },
    {
      startTime: "10:00 AM",
      startHours: 10,
      endTime: "11:00 AM"
    },
    {
      startTime: "11:00 AM",
      startHours: 11,
      endTime: "12:00 PM"
    },
    {
      startTime: "12:00 PM",
      startHours: 12,
      endTime: "1:00 PM"
    },
    {
      startTime: "1:00 PM",
      startHours: 13,
      endTime: "2:00 PM"
    },
    {
      startTime: "2:00 PM",
      startHours: 14,
      endTime: "3:00 PM"
    },
    {
      startTime: "3:00 PM",
      startHours: 15,
      endTime: "4:00 PM"
    },
    {
      startTime: "4:00 PM",
      startHours: 16,
      endTime: "5:00 PM"
    },
    {
      startTime: "5:00 PM",
      startHours: 17,
      endTime: "6:00 PM"
    },
    {
      startTime: "6:00 PM",
      startHours: 18,
      endTime: "7:00 PM"
    },
    {
      startTime: "7:00 PM",
      startHours: 19,
      endTime: "8:00 PM"
    },
    {
      startTime: "8:00 PM",
      startHours: 20,
      endTime: "9:00 PM"
    },
    {
      startTime: "9:00 PM",
      startHours: 21,
      endTime: "10:00 PM"
    },
    {
      startTime: "10:00 PM",
      startHours: 22,
      endTime: "11:00 PM"
    },
    {
      startTime: "11:00 PM",
      startHours: 23,
      endTime: "12:00 AM"
    },
  ]);
  const [isForcePickerOpen, setIsOpen] = useState(false);
  const [selectedDate, setDate] = useState(new Date());
  const [searchData, setSearchData] = useState([]);
  const [appointmentData, setAppointmentData] = useState(null);
  const [zoneChatData, setZoneChatData] = useState({});
  const [zoneChataExtraProps, setZoneChataExtraProps] = useState({});

  const handleChange = newValue => {
    setDate(newValue);
  };

  useEffect(async () => {
    await getSelectedBrandDetails();
  }, []);

  const getSelectedBrandDetails = async () => {
    const response = await dispatch(
      getBrandCalendar({
        event_id: match?.params?.event_id,
        brand_id: match?.params?.brand_id
      })
    );
    console.log("response in brand details", response.payload.data.data);
    let brandCalendarData = response.payload.data.data;

    let formatAMPM = date => {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    };

    let diff_hours = (dt2, dt1) => {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60;
      return Math.abs(diff);
    };

    let hoursArray = [];

    if (brandCalendarData) {
      let searchData = [];

      brandCalendarData.product_zone_demo_details.map(x => {
        x.product_zone_session_configurations.map(e => {
          let minutes = new Date(e.start_date_time).getMinutes();
          e["offsetHeight"] = minutes > 0 ? 75 - (75 * minutes) / 60 : 0;
          e["startHours"] = new Date(e.start_date_time).getHours();
          e["endHours"] = new Date(e.end_date_time).getHours();
          e["startTime"] = formatAMPM(new Date(e.start_date_time));
          e["endTime"] = formatAMPM(new Date(e.end_date_time));
          e["duration"] = diff_hours(
            new Date(e.end_date_time),
            new Date(e.start_date_time)
          );
          searchData.push(e);
        });
        x["events"] = x.product_zone_session_configurations;
        let hours = _.map(x.events, "endHours");
        hoursArray = hoursArray.concat(hours);
      });

      brandCalendarData.product_zone_chat_details.map(x => {
        x.product_area_chat_configurations.map(e => {
          let minutes = new Date(e.start_date_time).getMinutes();
          e["offsetHeight"] = minutes > 0 ? 75 - (75 * minutes) / 60 : 0;
          e["startHours"] = new Date(e.start_date_time).getHours();
          e["endHours"] = new Date(e.end_date_time).getHours();
          e["startTime"] = formatAMPM(new Date(e.start_date_time));
          e["endTime"] = formatAMPM(new Date(e.end_date_time));
          e["duration"] = diff_hours(
            new Date(e.end_date_time),
            new Date(e.start_date_time)
          );
          searchData.push(e);
        });
        x["events"] = x.product_area_chat_configurations;
        let hours = _.map(x.events, "endHours");
        hoursArray = hoursArray.concat(hours);
      });

      brandCalendarData.auditorium_session_details.map(x => {
        let minutes = new Date(x.start_date_time).getMinutes();
        x["offsetHeight"] = minutes > 0 ? 75 - (75 * minutes) / 60 : 0;
        x["startHours"] = new Date(x.start_date_time).getHours();
        x["endHours"] = new Date(x.end_date_time).getHours();
        x["startTime"] = formatAMPM(new Date(x.start_date_time));
        x["endTime"] = formatAMPM(new Date(x.end_date_time));
        x["duration"] = diff_hours(
          new Date(x.end_date_time),
          new Date(x.start_date_time)
        );
        let hours = _.map(x.events, "endHours");
        hoursArray = hoursArray.concat(hours);
        searchData.push(x);
      });

      brandCalendarData.networking_lounge_session_details.map(x => {
        let minutes = new Date(x.start_date_time).getMinutes();
        x["offsetHeight"] = minutes > 0 ? 75 - (75 * minutes) / 60 : 0;
        x["startHours"] = new Date(x.start_date_time).getHours();
        x["endHours"] = new Date(x.end_date_time).getHours();
        x["startTime"] = formatAMPM(new Date(x.start_date_time));
        x["endTime"] = formatAMPM(new Date(x.end_date_time));
        x["duration"] = diff_hours(
          new Date(x.end_date_time),
          new Date(x.start_date_time)
        );
        let hours = _.map(x.events, "endHours");
        hoursArray = hoursArray.concat(hours);
        searchData.push(x);
      });

      // hoursArray = hoursArray.sort((a, b) => b - a);
      // let existingHoursArray = _.map(timeWiseEvents, "startHours");

      // if (hoursArray[0] > existingHoursArray[existingHoursArray.length - 1]) {
      //   let timeArray = timeWiseEvents;
      //   for (
      //     let i = existingHoursArray[existingHoursArray.length - 1];
      //     i <= hoursArray[0];
      //     i++
      //   ) {
      //     let startHours = new Date().setHours(i);
      //     let endHours = new Date().setHours(i + 1);
      //     let obj = {
      //       startTime: formatAMPM(new Date(new Date(startHours).setMinutes(0))),
      //       endTime: formatAMPM(new Date(new Date(endHours).setMinutes(0))),
      //       startHours: i
      //     };
      //     timeArray.push(obj);
      //   }
      //   setTimeWiseEvents(timeArray);
      // }

      let modifiedResponse = [
        {
          key: "productZoneSession",
          title: brandCalendarData.product_zone_demo_title,
          zones: brandCalendarData.product_zone_demo_details
        },
        {
          key: "productAuditoriumSession",
          title: brandCalendarData.auditorium_session_title,
          zones: [],
          events: brandCalendarData.auditorium_session_details
        },
        {
          key: "productNetworkingSession",
          title: brandCalendarData.networking_lounge_session_title,
          zones: [],
          events: brandCalendarData.networking_lounge_session_details
        },
        {
          key: "productZoneChat",
          title: brandCalendarData.product_zone_chat_title,
          zones: brandCalendarData.product_zone_chat_details
        }
      ];

      setCalendarData(modifiedResponse);
      setSearchData(searchData);
      // console.log(modifiedResponse);
    }
    // setBrandDetails(response?.payload?.data?.data?.details);
  };

  const previousDate = () => {
    setDate(moment(new Date(selectedDate)).subtract("days", 1));
  };

  const nextDate = () => {
    setDate(moment(new Date(selectedDate)).add("days", 1));
  };

  const openZonePopup = (sessionData, zone_id, type) => {
    let appointmentData = {
      event_id: match?.params?.event_id,
      brand_id: match?.params?.brand_id,
      zone_id,
      configuration_id: sessionData.configuration_id || null
    };

    if (!sessionData.configuration_id) {
      let startTime = new Date(selectedDate).setHours(sessionData.startHours);
      let endTime = new Date(selectedDate).setHours(sessionData.startHours + 1);
      appointmentData["startTime"] = moment(new Date(startTime).setMinutes(0));
      appointmentData["endTime"] = moment(new Date(endTime).setMinutes(0));
    }

    if (type != 'productZoneChat') {
      setAppointmentData(appointmentData);
      setShowPopup(true);
    } else {
      const extraProps = {
        eventId: match?.params?.event_id,
        brandId: match?.params?.brand_id,
        zoneId: zone_id
      };
      let zoneChatData = {
        chat_configuration_id: sessionData.chat_configuration_id
      }
      setZoneChataExtraProps(extraProps);
      setZoneChatData(zoneChatData);
      setShowZoneChatPopup(true);
    }
  };

  const onSave = async data => {
    await getSelectedBrandDetails();
    setShowPopup(false);
    setShowZoneChatPopup(false);
  };

  const onCancel = () => {
    setShowPopup(false);
    setShowZoneChatPopup(false);
  };

  return (
    <Fragment>
      <div>
        <h4 className="fs-24">Brand Calendar</h4>
        <Breadcrumb className="my-2" separator=">">
          <Breadcrumb.Item>Expo Map</Breadcrumb.Item>
          <Breadcrumb.Item>Brands</Breadcrumb.Item>
          <Breadcrumb.Item>Visit Booth</Breadcrumb.Item>
          <Breadcrumb.Item>Brand Calendar</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Paper>
        <div class="brand-calendar-wrapper">
          <div class="search-container">
            <SearchBar
              className="rounded w-100"
              details={searchData}
              placeholder={"Search session, speaker"}
            />
          </div>
          <div className="calendar-container">
            <div className="today-container">
              <Button
                className="fs-pink custom_border"
                size="medium"
                onClick={() => setDate(new Date())}
              >
                TODAY
              </Button>

              <IconButton aria-label="prev" onClick={() => previousDate()}>
                <NavigateBeforeIcon />
              </IconButton>

              <IconButton aria-label="next" onClick={() => nextDate()}>
                <NavigateNextIcon />
              </IconButton>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label=""
                  inputFormat="d MMM, yyyy"
                  value={selectedDate}
                  onChange={handleChange}
                  className={"custom-date-picker"}
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="calendar-header">
              <div className="calendar-header-item time-header"></div>
              {calendarData?.map(e => {
                return (
                  <div
                    className="calendar-header-item"
                    style={{ width: 100 / calendarData.length + "%" }}
                  >
                    <div
                      className={
                        e.zones.length > 0 ? "zone-title" : "header-title"
                      }
                    >
                      {e.title}
                    </div>
                    {e.zones.length > 0 ? (
                      <div className="zone-container">
                        {e.zones.map(zone => {
                          return (
                            <div
                              style={{ width: 100 / calendarData.length + "%" }}
                            >
                              {zone.zone_title}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div className="calendar-data pt-2">
              {timeWiseEvents?.map(e => {
                return (
                  <div
                    className={
                      e.defaultTime ? "calendar-row empty-row" : "calendar-row "
                    }
                  >
                    <div className="time-element">{e.startTime}</div>
                    {calendarData?.map(data => {
                      return (
                        <div
                          className="calendar-section"
                          style={{ width: 100 / calendarData.length + "%" }}
                        >
                          {data.zones.length > 0 ? (
                            data.zones.map(zone => {
                              return (
                                <div
                                  className="zone-item"
                                  onClick={event => {
                                    openZonePopup(e, zone.zone_id, data.key);
                                  }}
                                  style={{
                                    width: 100 / data.zones.length + "%"
                                  }}
                                >
                                  {zone.events
                                    ? zone.events.map(eventData => {
                                      return eventData.startHours ==
                                        e.startHours &&
                                        new Date(
                                          eventData.start_date_time
                                        ).toDateString() ==
                                        new Date(
                                          selectedDate
                                        ).toDateString() ? (
                                        <div
                                          className="event-card-left"
                                          style={{
                                            height:
                                              75 * eventData.duration + "px",
                                            marginTop:
                                              eventData.offsetHeight + "px"
                                          }}
                                          onClick={event => {
                                            openZonePopup(
                                              eventData,
                                              zone.zone_id,
                                              data.key
                                            );
                                            event.preventDefault();
                                            event.stopPropagation();
                                          }}
                                        >
                                          <div>
                                            <div>
                                              {eventData.session_title}
                                            </div>
                                            <div>
                                              {eventData.session_description}
                                            </div>
                                            <div>
                                              {eventData.startTime
                                                ? eventData.startTime +
                                                " - " +
                                                eventData.endTime
                                                : ""}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="border p-1">
                                              <BoothRed />
                                            </div>
                                          </div>
                                        </div>
                                      ) : null;
                                    })
                                    : null}
                                </div>
                              );
                            })
                          ) : (
                            <div className="session-item">
                              {data.events.length > 0
                                ? data.events.map(eventData => {
                                  return eventData.startHours ==
                                    e.startHours &&
                                    new Date(
                                      eventData.start_date_time
                                    ).toDateString() ==
                                    new Date(
                                      selectedDate
                                    ).toDateString() ? (
                                    <div
                                      className="event-card"
                                      style={{
                                        height:
                                          75 * eventData.duration + "px",
                                        marginTop:
                                          eventData.offsetHeight + "px"
                                      }}
                                    >
                                      <div>{eventData.session_title}</div>
                                      <div>
                                        {eventData.session_description}
                                      </div>
                                      <div>
                                        {eventData.startTime
                                          ? eventData.startTime +
                                          " - " +
                                          eventData.endTime
                                          : null}
                                      </div>
                                    </div>
                                  ) : null;
                                })
                                : null}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Paper>

      <Modal
        wrapClassName="brand-calendar-zone-session-popup"
        visible={showPopup}
        onCancel={onCancel}
        footer={null}
      >
        <ProductZoneSession
          data={appointmentData}
          onSave={onSave}
          onCancel={onCancel}
        />
      </Modal>

      <Modal
        wrapClassName="brand-calendar-zone-session-popup"
        visible={showZoneChatPopup}
        onCancel={onCancel}
        footer={null}
      >
        <ProductZoneChat
          data={zoneChatData}
          extraProps={zoneChataExtraProps}
          onSave={onSave}
          onCancel={onCancel}
        />
      </Modal>
    </Fragment>
  );
};

export default BrandCalendarUI;
