import React, { useEffect, useState } from "react";
import { Table, Popover, Breadcrumb, Input, Button } from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { onShowSizeChange } from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyList } from "../../redux/slice/surveyManagement";
import "./survey.css";
import moment from "moment";
import { isEmpty } from "lodash";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";

const SurveyManagement = () => {
  const [searchData, setsearchData] = useState("");
  const dispatch = useDispatch();
  const surveySelector = useSelector(
    (state) => state.surveyManagement.surveyList
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSurveyList());
  }, []);

  useEffect(() => {
    const searchObj = {
      search: searchData,
    };
    dispatch(getSurveyList(searchObj));
  }, [searchData]);

  const handleDeleteSurvey = async (surveyData) => {
    console.log("Survey Data", surveyData);

    const body = {
      survey_id: surveyData.survey_id,
      is_delete: true,
    };

    const response = await ApiCall(
      "DELETE",
      body,
      "/admin/survey/delete",
      "",
      ""
    );
    console.log("Delete Survey response ", response);
    if (response.data.code === 200) {
      toast.success(response.data.message);
      dispatch(getSurveyList());
    } else {
      toast.error(response.data.message);
    }
  };

  const tableData =
    surveySelector &&
    !isEmpty(surveySelector) &&
    surveySelector.rows.map((elem) => {
      return {
        // key: "1",
        survey_id: elem.survey_id,
        created_at: moment(elem.created_at).format("DD-MM-YYYY HH:mm"),
        surveytitle: elem.survey_title,
        eventdomainname: elem.survey_type,
        survey_type: elem.survey_type,
        count_question: elem.count_question,
        displaychart: "On",
        question_details: elem.surveys_qa_survey_id,
        customername: elem.created_user_name,
        // eventdomain: elem?.events[0]?.sub_domain,
        createdby: elem.created_user_name,
        // displaychart: elem.display_chart,
      };
    });

  const columns = [
    {
      title: "Survey Id",
      dataIndex: "survey_id",
      key: "survey_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      //   render: text => <a href="#3">{text}</a>,
    },
    {
      title: "Survey Type",
      dataIndex: "survey_type",
      key: "survey_type",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Survey Title",
      dataIndex: "surveytitle",
      key: "surveytitle",
      // width: "25%",
    },
    {
      title: "Customer Name",
      dataIndex: "customername",
      key: "customername",
      // width: "25%",
    },
    {
      title: "Event Domain",
      dataIndex: "eventdomain",
      key: "eventdomain",
      // width: "25%",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      // width: "25%",
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
      // width: "25%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // width: "25%",
    },
    {
      title: "Question",
      dataIndex: "count_question",
      key: "count_question",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Created By",
      dataIndex: "createdby",
      key: "createdby",
      // width: "25%",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    // {
    //   title: "Display Chart",
    //   dataIndex: "displaychart",
    //   key: "displaychart",
    //   sorter: true,
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    // },
    {
      title: "",
      dataIndex: "operation",
      fixed: "right",
      render: (text, record, index) => (
        <Popover
          content={
            <div>
              <Link to={`viewsurvey/${record.survey_id}`}>
                <p className="options">
                  <EyeOutlined className="mx-1" />{" "}
                  <span className="">View Details</span>
                </p>
              </Link>
              <Link to={`editsurvey/${record.survey_id}`}>
                <p className="options">
                  <EditOutlined className="mx-1" />{" "}
                  <span className="">Edit Survey</span>
                </p>
              </Link>

              <p
                className="options cursor-pointer"
                onClick={() => {
                  handleDeleteSurvey(record);
                }}
              >
                <DeleteOutlined className="mx-1" />{" "}
                <span className="">Delete Survey</span>
              </p>
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      fixed: "right",
      render: (text, record, index) => (
        <div className="px-1">
          <Link to={`editsurvey/${record.survey_id}`}>
            <p className="options m-0">
              <EditOutlined className="" />
            </p>
          </Link>
        </div>
      ),
    },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    const sortObj = {
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    };

    dispatch(getSurveyList(sortObj));
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="fs-24">Survey Management</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
            <Breadcrumb.Item>Survey Management</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>
          <Link to="/addnewsurvey">
            <Button className="rounded px-3 py-1 mt-2">
              <span className="fw-bold"> + Add New Survey </span>
            </Button>
          </Link>
        </div>
      </div>
      <div className="bg-white p-4">
        <div className="d-flex justify-content-between mb-4">
          <div className="w-50">
            <Input
              size="medium"
              placeholder="Search Title, Survey ID"
              style={{ width: "70%" }}
              prefix={<SearchOutlined />}
              value={searchData}
              onChange={handleInputSearch}
            />
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            // total: data.length,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
          }}
          scroll={{
            x: 1300,
          }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
};

export default SurveyManagement;
