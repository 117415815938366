import React, { Fragment } from "react";
import { Button, Select } from "antd";

const FilterContent = () => {
  const { Option } = Select;

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <Fragment>
      <div className="container-fluid w-100">
        <div className="row">
          <div className="col-4">
            <div className="row">
              <div className="col-5 text-end pt-1">Brand</div>
              <div className="col-7">
                <Select
                  defaultValue="Select if required"
                  className="w-100"
                  onChange={handleChange}
                >
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-5 text-end pt-1">Zone</div>
              <div className="col-7">
                <Select
                  defaultValue="Select if required"
                  className="w-100"
                  onChange={handleChange}
                >
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-5 text-end pt-1">User Type</div>
              <div className="col-7">
                <Select
                  defaultValue="Select if required"
                  className="w-100"
                  onChange={handleChange}
                >
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="row">
              <div className="col-5 text-end pt-1">Country</div>
              <div className="col-7">
                <Select
                  defaultValue="Select if required"
                  className="w-100"
                  onChange={handleChange}
                >
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-5 text-end pt-1">Job Title</div>
              <div className="col-7">
                <Select
                  defaultValue="Select if required"
                  className="w-100"
                  onChange={handleChange}
                >
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-5 text-end pt-1">Brand Booth Visited</div>
              <div className="col-7">
                <Select
                  defaultValue="Select if required"
                  className="w-100"
                  onChange={handleChange}
                >
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="row">
              <div className="col-5 text-end pt-1">Brand Sessions(s)</div>
              <div className="col-7">
                <Select
                  defaultValue="Select if required"
                  className="w-100"
                  onChange={handleChange}
                >
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-5 text-end pt-1">Booth Zone Visited</div>
              <div className="col-7">
                <Select
                  defaultValue="Select if required"
                  className="w-100"
                  onChange={handleChange}
                >
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-5 text-end pt-1">Zone Session</div>
              <div className="col-7">
                <Select
                  defaultValue="Select if required"
                  className="w-100"
                  onChange={handleChange}
                >
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div>
            </div>
            <div className="col-12 mt-4 text-end">
              <Button
                key="back"
                className="ant-btn ant-btn-primary py-1 px-4 rounded mx-4"
              >
                Apply
              </Button>
              <Button
                key="back"
                className="ant-btn button-pink py-1 px-4 rounded"
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default FilterContent;
