import React, { Fragment, useState, useEffect } from "react";
import { Select, Switch, Button, Breadcrumb, Input, Modal } from "antd";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { CalenderGrey } from "../../components/svgJS/CalenderRed";
import { AddGroup } from "../../components/svgJS/AddGroup";
import { EyeView } from "../../components/svgJS/EyeView";
import { useDispatch } from "react-redux";
import { getBrandDetails } from "../../redux/slice/brandManagementSlice";
import { ApiCall } from "../../utils/api";
import { getUserTypeAgents } from "../../utils/commonApis";
import { cloneDeep, isEmpty, isEqual, uniqWith } from "lodash-es";
import ExpoHalls from "../ExpoMap/ExpoHalls";
import { Download } from "../../components/svgJS/Download";
import CommonLoader from "../../components/Widgets/CommonLoader";

const ViewBrandDetails = ({ match }) => {
  const { Option } = Select;
  var CryptoJS = require("crypto-js");
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [agents, setAgents] = useState([]);
  const [brandDetails, setBrandDetails] = useState({});
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [isModalWallGraphicVisible, setIsModalWallGraphicVisible] = useState(
    false
  );
  const [isFinalBoothUrlVisible, setIsFinalBoothUrlVisible] = useState(false);

  // ExpoHall State
  const [expoHallModalVisible, setExpoHallModalVisible] = useState(false);

  const [fields, setFields] = useState({
    brand_id: match.params.brand_id,
    brand_agent_details: [],
    brand_social_media_details: []
  });

  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });

  const { TextArea } = Input;
  const dispatch = useDispatch();

  useEffect(async () => {
    setLoadingBrand(true)

    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // setUserType(decryptedData);


    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach(element => {
      if (element.route == window.location.pathname) {
        is_route_present = true
      }
    });

    if (is_route_present === false && decryptedData === "AGENT" || decryptedData === "DELEGATE") {
      window.location.href = '/pagenotfound'
    }


    await getSelectedBrandDetails();
    getSocialMediaList();
    if (brandDetails && brandDetails.event_id) {
      const agentListAPI = await getUserTypeAgents({ event_id: brandDetails && brandDetails.event_id });
      setAgentsCollectionData(agentListAPI);
    }

    setTimeout(() => {
      setLoadingBrand(false);
    }, 1000);
  }, [brandDetails.event_id]);

  useEffect(() => {
    if (agentsCollectionData) {
      setAgentsFromAPIAndUseEffect(brandDetails);
    }
  }, [agentsCollectionData]);

  const setAgentsFromAPIAndUseEffect = dataHere => {
    let brands_agents = [];
    dataHere &&
      dataHere.brands_agents &&
      dataHere.brands_agents.map(val => {
        brands_agents.push(val.user_id);
      });
    if (
      dataHere &&
      !isEmpty(dataHere.brands_agents) &&
      !isEmpty(brands_agents)
    ) {
      let clonedAgents = cloneDeep(agentsCollectionData);
      brands_agents.map(val => {
        if (clonedAgents && clonedAgents.some(agent => agent.user_id === val)) {
          let agents_list = clonedAgents;
          let selectedAgents_list = selectedAgents;
          let index = agents_list.findIndex(agent => agent.user_id === val);
          selectedAgents_list.push(agents_list[index]);
          agents_list.splice(index, 1);
          selectedAgents_list = uniqWith(selectedAgents_list, isEqual);

          console.log("selectedAgents_list..1122", selectedAgents_list);
          setSelectedAgents([...selectedAgents_list]);
          setAgents([...agents_list]);
        }
      });
    } else setAgents(agentsCollectionData);
  };

  const getSelectedBrandDetails = async () => {
    const response = await dispatch(
      getBrandDetails({ brand_id: match.params.brand_id })
    );
    console.log("response in brand details", response.payload.data.data);
    setBrandDetails(response?.payload?.data?.data?.details);
  };

  const showWallGraphicModal = () => {
    setIsModalWallGraphicVisible(true);
  };

  const handleOk = () => {
    setIsModalWallGraphicVisible(false);
  };

  const handleCancel = () => {
    setIsModalWallGraphicVisible(false);
  };

  const handleOkFinalBoothUrl = () => {
    setIsFinalBoothUrlVisible(false);
  };

  const handleCancelFinalBoothUrl = () => {
    setIsFinalBoothUrlVisible(false);
  };

  const showFinalBoothUrlModal = () => {
    setIsFinalBoothUrlVisible(true);
  };

  const getSocialMediaList = () => {
    console.log("getSocialMediaList");
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then(response => {
        setSocialMediaList(response.data.data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleOkExpoHall = () => {
    setExpoHallModalVisible(false);
  };

  const handleCancelExpoHall = () => {
    setExpoHallModalVisible(false);
  };

  const getDataFromExpoHall = (expo_hall_configuration_id, elemIndex) => {
  };

  if (loadingBrand) {
    return <CommonLoader />;
  } else
    return (
      <Fragment>
        <div className="view-all-common">
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="fs-24">View Brand Details</h4>
              <Breadcrumb className="my-2" separator=">">
                <Breadcrumb.Item>
                  <Link to="/brandmanagement">Admin Console</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/brandmanagement">Brand Management</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>View Brand Details</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>

          {/* <Main Div */}
          <div className="bg-white p-3">
            {/* section Brand Editor */}
            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20 px-2">Brand Editor</h6>
                </div>
              </div>
              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    {" "}
                    Active
                  </label>
                </div>
                <div className="col-3 text-start">
                  <Switch checked={brandDetails?.is_active} className="mx-3" />
                </div>
              </div>
              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    {" "}
                    Logo{" "}
                  </label>
                </div>
                <div className="col-3">
                  <img
                    src={brandDetails && brandDetails?.logo_url}
                    alt="Logo File Preview"
                    className="img-fluid"
                    style={{ height: "60px", width: "60px" }}
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Customer Account
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Select
                    placeholder="Boeing"
                    className="w-100 fs-bold-14"
                    allowClear
                    value={brandDetails && brandDetails?.customer?.account_name}
                    disabled
                  >
                    <Option value="a">A</Option>
                    <Option value="b">B</Option>
                    <Option value="c">C</Option>
                  </Select>
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Select Event
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Select
                    placeholder="Brand Within the system"
                    className="w-100 fs-bold-14"
                    allowClear
                    value={brandDetails && brandDetails?.event_name}
                    disabled
                  ></Select>
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Brand Name{" "}
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Input
                    disabled
                    name=""
                    id=""
                    className="w-100 fs-bold-14 border-gray rounded"
                    value={brandDetails && brandDetails.brand_title}
                    placeholder="Enter A Title Here"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Brand Description{" "}
                  </label>
                </div>
                <div className="col-5">
                  <TextArea
                    disabled
                    rows={4}
                    className="fs-bold-14"
                    value={brandDetails && brandDetails.brand_description}
                    placeholder="Enter A Description Here"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Brand Tier
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Input
                    disabled
                    name=""
                    id=""
                    className="w-100 fs-bold-14 border-gray rounded"
                    value={brandDetails && brandDetails.tier?.tier}
                    placeholder="Enter A Title Here"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Brand Color
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-2"}>
                  <Input
                    disabled
                    type="color"
                    id=""
                    className="border-gray fs-bold-14 rounded"
                    value={brandDetails && brandDetails?.brand_color}
                    addonBefore={brandDetails && brandDetails.brand_color}
                  />
                </div>
              </div>

              {/* New UI Code */}

              <div className="row mt-4">
                <div className={Tablet ? "col-2 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Default BTN Background
                  </label>
                </div>
                <div className={Tablet ? "col-2" : "col-2"}>
                  <Input
                    disabled
                    type="color"
                    id=""
                    defaultValue={brandDetails && brandDetails?.default_button_background_color}
                    value={brandDetails && brandDetails?.default_button_background_color}
                    addonBefore={brandDetails && brandDetails?.default_button_background_color}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </div>

                <div className={Tablet ? "col-2" : "col-2 text-center"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Gradient
                  </label>
                  <Switch className="mx-2"
                    disabled
                    checked={brandDetails && brandDetails?.is_default_button_gradient_active}
                  />
                </div>
                {
                  brandDetails && brandDetails.is_default_button_gradient_active ? (
                    <div className={Tablet ? "col-6" : "col-6 text-start"}>
                      <div className="d-flex">
                        <label htmlFor="" className="text-secondary mt-1">
                          Angle
                        </label>
                        <Input className="ms-2 mx-2" style={{ width: "50px" }}
                          value={brandDetails && brandDetails?.default_button_gradient_angle}
                          disabled
                        />
                        <label htmlFor="" className="text-secondary mx-4 mt-1">
                          Event 2nd Color
                        </label>
                        <Input
                          disabled
                          type="color"
                          id=""
                          defaultValue={
                            brandDetails && brandDetails?.default_button_background_second_color
                          }
                          value={brandDetails && brandDetails?.default_button_background_second_color}
                          addonBefore={
                            brandDetails && brandDetails?.default_button_background_second_color
                          }
                          className="eventtheme_input_style border-gray rounded fs-bold-14"
                        />
                      </div>
                    </div>
                  ) : ""
                }

              </div>
              <div className="row mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Default BTN Text Colour
                  </label>
                </div>
                <div className="col-3">
                  <Input
                    disabled
                    type="color"
                    id=""
                    defaultValue={brandDetails && brandDetails?.default_button_text_color}
                    addonBefore={brandDetails && brandDetails?.default_button_text_color}
                    value={brandDetails && brandDetails?.default_button_text_color}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Button Preview
                  </label>
                </div>
                <div className="col-3">
                  <Button style={{ color: brandDetails && brandDetails.default_button_text_color, background: brandDetails && brandDetails.is_default_button_gradient_active ? `linear-gradient(${brandDetails && brandDetails.default_button_gradient_angle}deg, ${brandDetails && brandDetails.default_button_background_color} 30%,${brandDetails && brandDetails.default_button_background_second_color} 100%)` : `${brandDetails && brandDetails.default_button_background_color}` }} className="rounded">Example Test</Button>
                </div>
              </div>

              <div className="row w-100 mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Highlight BTN Background
                  </label>
                </div>
                <div className="col-2">
                  <Input
                    disabled
                    type="color"
                    id=""
                    defaultValue={brandDetails && brandDetails?.highlight_button_background_color}
                    value={brandDetails && brandDetails?.highlight_button_background_color}
                    addonBefore={brandDetails && brandDetails?.highlight_button_background_color}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </div>


                <div className={Tablet ? "col-2" : "col-2 text-center"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Gradient
                  </label>
                  <Switch
                    checked={brandDetails && brandDetails?.is_highlight_button_gradient_active}
                    className="mx-2" />
                </div>

                {
                  brandDetails && brandDetails?.is_highlight_button_gradient_active ? (
                    <div className={Tablet ? "col-6" : "col-6 text-start"}>
                      <div className="d-flex">
                        <label htmlFor="" className="text-secondary mt-1">
                          Angle
                        </label>
                        <Input className="ms-2 mx-2" style={{ width: "50px" }}
                          value={brandDetails && brandDetails?.highlight_button_gradient_angle}
                        />

                        <label htmlFor="" className="text-secondary mx-4 text-nowrap mt-1">
                          Event 2nd Color
                        </label>
                        <Input
                          disabled
                          type="color"
                          id=""
                          defaultValue={
                            brandDetails && brandDetails?.highlight_button_background_second_color
                          }
                          value={
                            brandDetails && brandDetails?.highlight_button_background_second_color
                          }
                          addonBefore={
                            brandDetails && brandDetails?.highlight_button_background_second_color
                          }
                          className="eventtheme_input_style ms-2 border-gray rounded fs-bold-14"
                        />
                      </div>
                    </div>
                  ) : ""
                }

              </div>
              <div className="row mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Highlight BTN Text Colour
                  </label>
                </div>
                <div className="col-3">
                  <Input
                    disabled
                    type="color"
                    id=""
                    defaultValue={brandDetails && brandDetails?.highlight_button_text_color}
                    value={brandDetails && brandDetails?.highlight_button_text_color}
                    addonBefore={brandDetails && brandDetails?.highlight_button_text_color}
                    className="eventtheme_input_style border-gray rounded fs-bold-14"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-2 text-end">
                  <label htmlFor="" className="text-secondary text-nowrap mt-1">
                    Button Preview
                  </label>
                </div>
                <div className="col-3">
                  <Button
                    style={{ color: brandDetails && brandDetails.highlight_button_text_color, background: brandDetails && brandDetails.is_highlight_button_gradient_active ? `linear-gradient(${brandDetails && brandDetails.highlight_button_gradient_angle}deg, ${brandDetails && brandDetails.highlight_button_background_color} 30%,${brandDetails && brandDetails.highlight_button_background_second_color} 100%)` : `${brandDetails && brandDetails.highlight_button_background_color}` }}
                    className="rounded">Example Test</Button>
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary">
                    Booth Location{" "}
                  </label>
                </div>
                <div className={Tablet ? "col-4" : "col-3"}>
                  <Button
                    className="rounded btn-svg px-3"
                    onClick={() => setExpoHallModalVisible(true)}
                  >
                    Select Expo Hall
                  </Button>

                  <Modal
                    visible={expoHallModalVisible}
                    onOk={handleOkExpoHall}
                    onCancel={handleCancelExpoHall}
                    disabled={true}
                    width={1000}
                  >
                    <ExpoHalls
                      event_id={brandDetails?.event_id ?? null}
                      getDataFromExpoHall={getDataFromExpoHall}
                    />
                  </Modal>
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    Download Graphics Template
                  </label>
                </div>
                <div className={Tablet ? "col-2" : "col-1"}>
                  <Button
                    className="rounded  btn-svg px-3"
                    onClick={showWallGraphicModal}
                    icon={<EyeView />}
                  >
                    View
                  </Button>
                </div>
                <div className="col-2 text-start">
                  <Button type="primary" className="px-2">
                    <Download />
                    <span className="ms-2">Download</span>
                  </Button>
                </div>
              </div>

              <div className="row mt-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    {" "}
                    Upload Graphics{" "}
                  </label>
                </div>

                <div className="col-3">
                  <Button className=" rounded" onClick={showWallGraphicModal}>
                    View File
                  </Button>
                  <Modal
                    title="Wall Graphics File "
                    visible={isModalWallGraphicVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                    width={400}
                  >
                    <img
                      src={brandDetails && brandDetails.wall_graphics_url}
                      alt="wall graphics upload file"
                      className="img-fluid uploaded-image-modal-preview"
                    />
                  </Modal>
                </div>
              </div>

              <div className="row mt-4 border-bottom-gray pb-4">
                <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                  <label htmlFor="" className="text-secondary mt-1">
                    {" "}
                    360 Booth Upload
                  </label>
                </div>
                <div className="col-3">
                  <Button className=" rounded" onClick={showFinalBoothUrlModal}>
                    View File
                  </Button>
                  <Modal
                    title="Wall Graphics File "
                    visible={isFinalBoothUrlVisible}
                    onOk={handleOkFinalBoothUrl}
                    onCancel={handleCancelFinalBoothUrl}
                    footer={null}
                    width={400}
                  >
                    <img
                      src={brandDetails && brandDetails.final_booth_url}
                      alt="final_booth_url"
                      className="img-fluid uploaded-image-modal-preview"
                    />
                  </Modal>
                </div>
              </div>
            </section>

            {/* section External & Social Links */}
            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20">
                    External & Social Links
                  </h6>
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-12 mt-1">
                  <span className="fs-16">
                    NB: If you paste your details into these boxes then anyone
                    using this site will be able to view/contact you via these
                    platforms.
                  </span>
                </div>
              </div>

              <div className="social-media-div border-bottom-gray pb-4 mb-4">
                {socialMediaList &&
                  socialMediaList.length > 1 &&
                  socialMediaList?.map((data, idx) => {
                    return (
                      <div className="row mt-4 ">
                        <div className="col-2 text-end ">
                          {data?.social_media}
                        </div>
                        <div className="col-1 text-center ">
                          <Switch
                            checked={
                              brandDetails?.brands_social_media_handles_id?.some(
                                social_media =>
                                  social_media.social_media_id ===
                                  data.social_media_id &&
                                  social_media.social_media_url.length > 0
                              )
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="col-4 text-start ">
                          <Input
                            placeholder="https://webaddress.com"
                            className="w-100 fs-bold-14"
                            disabled={
                              brandDetails?.brand_social_media_details?.some(
                                social_media =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                                ? false
                                : true
                            }
                            value={
                              brandDetails.brands_social_media_handles_id?.some(
                                social_media =>
                                  social_media.social_media_id ===
                                  data?.social_media_id
                              )
                                ? brandDetails.brands_social_media_handles_id[
                                brandDetails.brands_social_media_handles_id.findIndex(
                                  social_media =>
                                    social_media.social_media_id ===
                                    data?.social_media_id
                                )
                                ]["social_media_url"]
                                : ""
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </section>

            {/* section default zone */}
            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20 px-2">
                    Default Zone{" "}
                  </h6>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 px-3">
                  <span className="fs-16">
                    NB: This zones content (including
                    assets/descriptions/interactivity etc.) will be used when
                    sponsoring areas/events and the brand profile page.
                    <br /> (Unless a different zone is selected when setting up an
                    event/sponsorship etc.)
                  </span>
                </div>
              </div>

              <div className="row mt-4 border-bottom-gray pb-4">
                <div className="col-2 text-end mt-1">Default Zone</div>
                <div className="col-3 text-start">
                  <Select
                    showSearch
                    placeholder="Default Zone"
                    value={
                      brandDetails?.brands_default_zone_id?.zone_title
                        ? brandDetails?.brands_default_zone_id?.zone_title
                        : "NA"
                    }
                    optionFilterProp="children"
                    className="w-100 fs-bold-14"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    disabled
                  >
                    <Option value="1" Selected>
                      Not Identified
                    </Option>
                    <Option value="2">2.Closed</Option>
                    <Option value="3">3.Communicated</Option>
                    <Option value="4">4.Identified</Option>
                    <Option value="5">Resolved</Option>
                    <Option value="6">Cancelled</Option>
                  </Select>
                </div>
              </div>
            </section>

            {/* section Assign Agents to this Brand */}
            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20 px-2">
                    Assign Agents to this Brand{" "}
                  </h6>
                </div>
              </div>
              <div className="row mt-4 event-editor-dropdown-div">
                <div className="col-2 text-end mt-4">
                  <span className="text-secondary mt-4">Agents</span>
                </div>

                <div
                  className={Tablet ? "col-5" : "col-4"}
                  id="area"
                  style={{ position: "relative" }}
                >
                  <label className="py-2 mx-2">
                    <b>All Agents</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ width: 300 }}
                    placeholder="Search Agents"
                    open={true}
                    value="All Agents"
                    className="border fs-bold-14 border-gray"
                    getPopupContainer={() => document.getElementById("area")}
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {agents?.map(data => (
                      <Option className="select-text-dark" value={data?.user_id}>
                        {data?.first_name} {data?.last_name}{" "}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className={Tablet ? "col-5" : "col-4"}>
                  <label className="py-2 mx-2">
                    <b>Tagged Agents</b>
                  </label>
                  <br />
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ width: 300 }}
                    placeholder="Search Tagged Agents"
                    open={true}
                    value="Tagged Agents"
                    className="border fs-bold-14 border-gray"
                    getPopupContainer={() => document.getElementById("area")}
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {selectedAgents.map((val, idx) => {
                      console.log("agents console..11", val);
                      return (
                        <Option key={idx} className="w-100">
                          {val.first_name} {val?.last_name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="row border-bottom-gray pb-4">
                <div className="col-2"></div>
                <div className="col-4">
                  <Button className="px-3  rounded btn-svg">
                    <AddGroup fill="#ef0855" />{" "}
                    <span className="mx-2">Invite Agents</span>{" "}
                  </Button>
                  <Button
                    className={
                      "px-3  rounded btn-svg" + (Tablet ? " mt-2" : " mx-2")
                    }
                    onClick={() =>
                      window.open(
                        `/brandCalendar/${brandDetails?.event_id}/${match.params.brand_id}`,
                        "_blank"
                      )
                    }
                  >
                    <CalenderGrey />{" "}
                    <span className="mx-2">Staffing Calendar</span>{" "}
                  </Button>
                </div>
              </div>
            </section>
            {/* section Cancel */}
            <section>
              <div className="d-flex justify-content-between py-4 px-4 background-footer-create-customer-account w-100">
                <div>
                  <Link to="/brandmanagement">
                    <Button className="rounded button-pink px-3">Cancel</Button>
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Fragment>
    );
};

export default ViewBrandDetails;
