import React, { useState, Fragment } from 'react';
import { Input } from 'antd';
import "./SearchBar.css";

const SearchBar = (props) => {

    const [searchField, setSearchField] = useState("");

    const details = props.details;

    const filteredSearchData = details?.filter(data => {
        return (
            data?.brand_title?.toLowerCase().includes(searchField?.toLowerCase()) ||
            data?.session_title?.toLowerCase().includes(searchField?.toLowerCase()) ||
            data?.event_name?.toLowerCase().includes(searchField?.toLowerCase())
        )
    })

    const handleChange = e => {
        setSearchField(e.target.value);
    };

    console.log("details", details)

    return (
        <div className="search_bar">
            <Input
                placeholder={props.placeholder}
                className={props.className}
                onChange={handleChange}
                allowClear
            />
            <div className="row mt-1 p-2">

                {searchField && searchField.length > 0 ?
                    <span className="text-secondary p-0 m-0 px-1">{filteredSearchData.length} Results</span>
                    : ""
                }
                {searchField && searchField.length > 0 ? filteredSearchData?.map((data) => {
                    console.log("SearchData", data)
                    return <Fragment>
                        <div className="col-12 side_searchbar_div my-1" style={{ backgroundColor: `${data.brand_color}` }}>
                            <p className="mt-1 mb-0 fs-14"><b>{data.session_title}</b></p>
                            <p className="m-0 fs-12">{data.session_description}</p>
                            <div className="row mb-1">
                                {/* join_session_url */}
                                <span className="col-6 fs-12 border-end" onClick={() => window.open(data?.join_session_url, "_blank").focus()}><b>Go to Session</b></span>
                                <span className="col-6 fs-12"><b>Go to Booth</b></span>
                            </div>
                        </div>
                    </Fragment>
                }) : ""}

            </div>
        </div>
    )
}

export default SearchBar