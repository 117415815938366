import React, { useState, useEffect, useRef } from "react";
import "./MyProfile.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Select, Row, Col, Switch, Modal, DatePicker } from "antd";
import { CalenderGrey } from "../../components/svgJS/CalenderRed";

import { BinWhite } from "../../components/svgJS/Bin";
import { PadLock } from "../../components/svgJS/PadLock";
import { toast } from "react-toastify";
import CommonLoader from "../../components/Widgets/CommonLoader";
import SimpleReactValidator from "simple-react-validator";
import { getCountryList, getJobTitles } from "../../utils/commonApis";
import FileUpload from "../common/FileUpload";
import {  updateProfile } from "../../redux/slice/myProfile";
import moment from "moment";


const MyProfileCustomer = ({ profileData, getProfileDetails }) => {
    const [fields, setFields] = useState({
        account_name: profileData.account_name,
        main_contact_person: profileData.main_contact_person,
        email: profileData.main_contact_email
    });
    const validator = useRef(new SimpleReactValidator());
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [forceUpdate, setForceUpdate] = useState(false);




    const handleFieldsChange = (name, val) => {
        setFields({ ...fields, [name]: val })
    }

    const updateCustomerProfileAPI = async () => {
        if (validator.current.allValid()) {
            setLoading(true);


            let formData = new FormData();
            formData.append("data", JSON.stringify(fields));
            const updateRes = await dispatch(updateProfile(fields));
            if (updateRes?.payload?.code === 200) {
                await dispatch(getProfileDetails());
                toast.success(updateRes.payload.message);
                setLoading(false);
            } else {
                setLoading(false);
                console.log("in error update profile");
                if (updateRes?.payload?.code !== 200)
                    toast.error(updateRes.payload.message);
                if (updateRes.error && updateRes.error === "Network Error")
                    toast.error("Please check the internet connection");
            }
        } else {
            setLoading(false);
            validator.current.showMessages();
            setForceUpdate(!forceUpdate);
        }
    };
    return (
        <>
            <div style={{ height: "80vh" }} className="w-100 bg-white pt-4">
                <Row className="mt-4">
                    <Col span={8} className="text-end px-2">
                        <lable>Active</lable>
                    </Col>
                    <Col span={12}>
                        <Switch checked={profileData.is_active} />
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col span={8} className="text-end px-2">
                        <lable>Account Name</lable>
                    </Col>
                    <Col span={12}>
                        <Input
                            name="account_name"
                            className="w-50" value={fields.account_name}
                            onChange={(e) => {
                                handleFieldsChange("account_name", e.target.value)
                            }}
                            onBlur={() =>
                                validator.current.showMessageFor("account_name")
                            }
                        />
                        {validator.current.message(
                            "Account Name",
                            fields.account_name,
                            "required",
                            {
                                className: "text-danger py-1"
                            })}
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col span={8} className="text-end px-2">
                        <lable>License Start Date</lable>
                    </Col>
                    <Col span={12}>
                        <DatePicker
                            suffixIcon={<CalenderGrey width="15" />}
                            format="DD-MM-YYYY"
                            // showTime
                            disabled
                            className="w-50 fs-bold-14"
                            name="license_period_start_date"
                            value={moment(profileData.license_period_start_date)}
                        // defaultPickerValue={profileData.license_period_start_date}
                        />
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col span={8} className="text-end px-2">
                        <lable>License End Date</lable>
                    </Col>
                    <Col span={12}>
                        <DatePicker
                            suffixIcon={<CalenderGrey width="15" />}
                            format="DD-MM-YYYY"
                            // showTime
                            disabled
                            className="w-50 fs-bold-14"
                            name="license_period_end_date"
                            value={moment(profileData.license_period_end_date)}

                        //   defaultPickerValue={values.license_period_start_date}
                        />
                    </Col>
                </Row>


                <Row className="mt-4">
                    <Col span={8} className="text-end px-2">
                        <lable>Main Contact Name</lable>
                    </Col>
                    <Col span={12}>
                        <Input
                            name="manin_contact_person"
                            onChange={(e) => {
                                handleFieldsChange("main_contact_person", e.target.value)
                            }}
                            onBlur={() =>
                                validator.current.showMessageFor("main_contact_person")
                            }
                            className="w-50" value={fields.main_contact_person} />
                        {validator.current.message(
                            "Main Account Name",
                            fields.main_contact_person,
                            "required",
                            {
                                className: "text-danger py-1"
                            })}
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col span={8} className="text-end px-2">
                        <lable>Email</lable>
                    </Col>
                    <Col span={12}>
                        <Input className="w-50" value={profileData.main_contact_email}
                            disabled
                        />
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col span={8} className="text-end px-2">
                        <lable></lable>
                    </Col>
                    <Col span={12}>
                        <Button className="" size={"large"} type="primary" onClick={updateCustomerProfileAPI}>
                            Update
                        </Button>
                    </Col>
                </Row>


            </div>

        </>
    )
}

export default MyProfileCustomer;