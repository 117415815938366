import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Popconfirm,
  Popover,
  Breadcrumb,
  Input,
  Menu,
  Dropdown,
  message,
  Select,
} from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SelectOutlined,
  SearchOutlined,
  DownOutlined,
  UserOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { filter, plusSquare } from "../../components/Images/Images";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import { data } from "./temp-data";
import { getUser, changeStatus } from "../../redux/slice/userManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import UserFilterContent from "./UserFilterContent";
import { ApiCall } from "../../utils/api";
import { Filter } from "../../components/svgJS/Filter";
import CommonLoader from "../../components/Widgets/CommonLoader";


const UserManagement = () => {
  const [userType, setUserType] = useState("");
  const [searchData, setsearchData] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [jobRoleList, setJobRoleList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  const dispatch = useDispatch();
  const { Option } = Select;
  var CryptoJS = require("crypto-js");

  useEffect(() => {
    dispatch(getUser());
    getUserTypeList();
    getBrandList();
    getCountryList();
  }, []);

  useEffect(() => {
    setLoadingUser(true)
    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach(element => {
      if (element.route == window.location.pathname) {
        is_route_present = true
      }
    });
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    window.scrollTo(0, 0);
    const searchObj = {
      search: searchData,
    };

    dispatch(getUser(searchObj));

    if (is_route_present === false) {
      window.location.href = '/pagenotfound'
    }

    setTimeout(() => {
      setLoadingUser(false);
    }, 1000);
  }, [searchData]);

  const { rows } = useSelector((state) => state.userManagement.data);

  const tableData =
    rows &&
    rows.map((elem) => {
      return {
        user_id: elem.user_id,
        // created_at: moment(elem.created_at).format("YYYY-MM-DD HH:mm"),
        created_at: moment(elem.created_at).format("DD-MM-YYYY HH:mm"),
        username: elem.first_name + " " + elem.last_name,
        brand: elem.brand_title ?? "-",
        event: elem.event_title ?? "NA",
        emailaddress: elem.email,
        profile: elem.image_url,
        user_type: elem.user_type,
        country: elem?.country?.country,
        is_active: elem.is_active,
        is_admin: elem.is_admin,
      };
    });

  function handleButtonClick(e) {
    message.info("Click on left button.");
    // console.log("click left button", e);
  }

  function handleMenuClick(e) {
    message.info("Click on menu item.");
    // console.log("click", e);
  }

  function handleChange(value) {
    // console.log(`selected ${value}`);
  }

  const content = (
    <div>
      <p className="options">
        <EyeOutlined /> <span className="">View Details</span>
      </p>
      <p className="options">
        {" "}
        <EditOutlined /> <span className="">Edit Profile</span>
      </p>
      <p className="options">
        <SelectOutlined /> <span className=""> Join Networking Table </span>
      </p>
    </div>
  );

  const columns = [
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      //   render: text => <a href="#3">{text}</a>,
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Profile",
      dataIndex: "profile",
      // key: "profile",
      render: (record) =>
        record ? (
          <img src={record} alt="pro pic" className="user-profile-picture" />
        ) : (
          "NA"
        ),
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Email Address",
      dataIndex: "emailaddress",
      key: "emailaddress",
    },
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <Button
          className={
            record.is_active === true
              ? "bg-button-green active_btns"
              : "bg-button-red inactive_btns"
          }
          onClick={() => handleStatus(record.user_id, record.is_active)}
          shape="round"
        >
          {record.is_active === true ? "Active" : "Inactive"}
        </Button>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "",
      dataIndex: "operation",
      render: (text, record, index) => (
        <Popover
          content={
            <div>
              <Link to={`viewuser/${record.user_id}`}>
                <p className="options">
                  <EyeOutlined className="px-1" />{" "}
                  <span className="">View Details</span>
                </p>
              </Link>
              {userType && userType === "BRAND ADMIN" ? (
                record.is_admin === false ? (
                  <Link to={`edituser/${record.user_id}`}>
                    <p className="options">
                      <EditOutlined className="px-1" />{" "}
                      <span className="">Edit User</span>
                    </p>
                  </Link>
                ) : (
                  ""
                )
              ) : (
                <Link to={`edituser/${record.user_id}`}>
                  <p className="options">
                    <EditOutlined className="px-1" />{" "}
                    <span className="">Edit User</span>
                  </p>
                </Link>
              )}

              <p className="options">
                {/* <img src = {plusSquare} alt = "plusSquaresvg logo" className="px-1" /> Join Networking Table */}
                <PlusSquareOutlined className="px-1" />
                <span> Join Networking Table</span>
              </p>
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      render: (text, record, index) => (
        <div className="px-1">
          <Link to={`edituser/${record.user_id}`}>
            {record.is_admin === false ?
              <p className="options m-0">
                <EditOutlined className="" />
              </p>
              : ""
            }
          </Link>
        </div>
      ),
    },
  ];

  const handleStatus = (id, bool) => {
    dispatch(
      changeStatus({
        user_id: id,
        is_active: !bool,
      })
    );
  };

  const hideFilter = () => {
    setFilterVisible(false);
  };

  const handleVisibleChange = (filterVisible) => {
    setFilterVisible(filterVisible);
  };

  const handleFilterChange = (name, val) => {
    dispatch(
      getUser({
        [name]: val,
      })
    );
  };

  const handleClear = () => {
    dispatch(getUser());
    setFilterVisible(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // console.log('sorter', sorter)

    const sortObj = {
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    };
    // console.log("obj",sortObj)
    dispatch(getUser(sortObj));
  };

  const getBrandList = () => {
    ApiCall("get", null, "/admin/brand/get", null)
      .then((response) => {
        const { rows } = response.data.data;
        setBrandList(rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getUserTypeList = () => {
    ApiCall("get", null, "/admin/user/get", null)
      .then((response) => {
        const { rows } = response.data.data;
        setUserTypeList(rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCountryList = () => {
    ApiCall("get", null, "/admin/country/get", null)
      .then((response) => {
        const { data } = response.data;
        setCountryList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
  };


  if (loadingUser) {
    return <CommonLoader />;
  } else
    return (
      <Fragment>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="fs-24">User Management</h4>
            <Breadcrumb className="my-2" separator=">">
              <Breadcrumb.Item>
                <Link to="/usermanagement">Admin Console</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>User Management</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div>
            <Link to="adduser">
              <Button className="rounded px-3 py-1 m-2">
                <span className="fw-bold"> + Add User </span>
              </Button>
            </Link>
            <Link to="inviteAttend">
              <Button className="rounded px-3 py-1 m-2">
                <span className="fw-bold"> Invite to Attend </span>
              </Button>
            </Link>
          </div>
        </div>

        <div className="bg-white p-4">
          <div className="d-flex justify-content-between mb-4">
            <div className="w-50">
              <Input
                size="medium"
                value={searchData}
                onChange={handleInputSearch}
                placeholder="Search User Name, User ID "
                style={{ width: "70%" }}
                prefix={<SearchOutlined />}
              />
            </div>

            <div className="d-flex">

              <Button className=" rounded" onClick={() => setShowFilter(!showFilter)}>
                <Filter />
                <span className="ms-2"> FILTER</span> <DownOutlined />
              </Button>


              {/* <Popover placement="bottomRight" title={text} content={content} trigger="click">
            <Button>BR</Button>
            </> */}
            </div>

            {/* <div className="d-flex">
            <Dropdown overlay={menu}>

              <Button className="button-pink-filter rounded">
                <img src={filter} alt="filter logo" className="mx-1 filter-logo" />
                <span> FILTER</span> <DownOutlined />
              </Button>
            </Dropdown>

          </div> */}
          </div>
          {
            showFilter ? (
              <div className="mb-3">
                <div className="w-100">
                  <div className="row">
                    <div className="col-4">
                      <div className="row">
                        <div className="col-5 text-end pt-1">Brand</div>
                        <div className="col-7">
                          <Select
                            defaultValue="Select if required"
                            className="w-100"
                            onChange={(val) => handleFilterChange("brand", val)}
                          >
                            {brandList &&
                              brandList?.map((data) => {
                                return (
                                  <Option value={data.brand_title}>
                                    {data.brand_title}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-5 text-end pt-1">Zone</div>
                        <div className="col-7">
                          <Select
                            defaultValue="Select if required"
                            className="w-100"
                            onChange={handleChange}
                          >
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="disabled" disabled>
                              Disabled
                            </Option>
                            <Option value="Yiminghe">yiminghe</Option>
                          </Select>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-5 text-end pt-1">User Type</div>
                        <div className="col-7">
                          <Select
                            defaultValue="Select if required"
                            className="w-100"
                            onChange={(val) => handleFilterChange("user_type", val)}
                          >
                            <Option value="AGENT">AGENT</Option>
                            <Option value="DELEGATE">DELEGATE</Option>
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="row">
                        <div className="col-5 text-end pt-1">Country</div>
                        <div className="col-7">
                          <Select
                            defaultValue="Select if required"
                            className="w-100"
                            onChange={(val) => handleFilterChange("usercountry_type", val)}
                          >
                            {countryList &&
                              countryList?.map((data) => {
                                return <Option value={data.country}>{data.country}</Option>;
                              })}
                            {/* <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option> */}
                          </Select>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-5 text-end pt-1">Job Title</div>
                        <div className="col-7">
                          <Select
                            defaultValue="Select if required"
                            className="w-100"
                            onChange={(val) => handleFilterChange("job_role", val)}
                          >
                            {userTypeList &&
                              userTypeList?.map((data) => {
                                return (
                                  <Option value={data.job_role}>{data.job_role}</Option>
                                );
                              })}
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="disabled" disabled>
                              Disabled
                            </Option>
                            <Option value="Yiminghe">yiminghe</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-5 text-end pt-1">Brand Booth Visited</div>
                        <div className="col-7">
                          <Select
                            defaultValue="Select if required"
                            className="w-100"
                            onChange={handleChange}
                          >
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="disabled" disabled>
                              Disabled
                            </Option>
                            <Option value="Yiminghe">yiminghe</Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="row">
                        <div className="col-5 text-end pt-1">Brand Sessions(s)</div>
                        <div className="col-7">
                          <Select
                            defaultValue="Select if required"
                            className="w-100"
                            onChange={handleChange}
                          >
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="disabled" disabled>
                              Disabled
                            </Option>
                            <Option value="Yiminghe">yiminghe</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-5 text-end pt-1">Booth Zone Visited</div>
                        <div className="col-7">
                          <Select
                            defaultValue="Select if required"
                            className="w-100"
                            onChange={handleChange}
                          >
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="disabled" disabled>
                              Disabled
                            </Option>
                            <Option value="Yiminghe">yiminghe</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-5 text-end pt-1">Zone Session</div>
                        <div className="col-7">
                          <Select
                            defaultValue="Select if required"
                            className="w-100"
                            onChange={handleChange}
                          >
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="disabled" disabled>
                              Disabled
                            </Option>
                            <Option value="Yiminghe">yiminghe</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-12 mt-4 text-end">
                        <Button
                          key="back"
                          className="ant-btn ant-btn-primary py-1 px-4 rounded mx-4"
                          onClick={hideFilter}
                        >
                          Apply
                        </Button>
                        <Button
                          key="back"
                          className="ant-btn button-pink py-1 px-4 rounded"
                          onClick={handleClear}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : ""
          }

          <Table
            columns={columns}
            dataSource={tableData}
            pagination={{
              // total: data.length,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
            }}
            bordered={false}
            showSizeChange={true}
            onChange={handleTableChange}
          />
        </div>
      </Fragment>
    );
};

export default UserManagement;


// const UserFilterContent = (
//   <div className="container-fluid w-100">
//     <div className="row">
//       <div className="col-4">
//         <div className="row">
//           <div className="col-5 text-end pt-1">Brand</div>
//           <div className="col-7">
//             <Select
//               defaultValue="Select if required"
//               className="w-100"
//               onChange={(val) => handleFilterChange("brand", val)}
//             >
//               {brandList &&
//                 brandList?.map((data) => {
//                   return (
//                     <Option value={data.brand_title}>
//                       {data.brand_title}
//                     </Option>
//                   );
//                 })}
//             </Select>
//           </div>
//         </div>

//         <div className="row mt-4">
//           <div className="col-5 text-end pt-1">Zone</div>
//           <div className="col-7">
//             <Select
//               defaultValue="Select if required"
//               className="w-100"
//               onChange={handleChange}
//             >
//               <Option value="jack">Jack</Option>
//               <Option value="lucy">Lucy</Option>
//               <Option value="disabled" disabled>
//                 Disabled
//               </Option>
//               <Option value="Yiminghe">yiminghe</Option>
//             </Select>
//           </div>
//         </div>

//         <div className="row mt-4">
//           <div className="col-5 text-end pt-1">User Type</div>
//           <div className="col-7">
//             <Select
//               defaultValue="Select if required"
//               className="w-100"
//               onChange={(val) => handleFilterChange("user_type", val)}
//             >
//               <Option value="AGENT">AGENT</Option>
//               <Option value="DELEGATE">DELEGATE</Option>
//             </Select>
//           </div>
//         </div>
//       </div>

//       <div className="col-4">
//         <div className="row">
//           <div className="col-5 text-end pt-1">Country</div>
//           <div className="col-7">
//             <Select
//               defaultValue="Select if required"
//               className="w-100"
//               onChange={(val) => handleFilterChange("usercountry_type", val)}
//             >
//               {countryList &&
//                 countryList?.map((data) => {
//                   return <Option value={data.country}>{data.country}</Option>;
//                 })}
//               {/* <Option value="jack">Jack</Option>
//               <Option value="lucy">Lucy</Option>
//               <Option value="disabled" disabled>
//                 Disabled
//               </Option>
//               <Option value="Yiminghe">yiminghe</Option> */}
//             </Select>
//           </div>
//         </div>
//         <div className="row mt-4">
//           <div className="col-5 text-end pt-1">Job Title</div>
//           <div className="col-7">
//             <Select
//               defaultValue="Select if required"
//               className="w-100"
//               onChange={(val) => handleFilterChange("job_role", val)}
//             >
//               {userTypeList &&
//                 userTypeList?.map((data) => {
//                   return (
//                     <Option value={data.job_role}>{data.job_role}</Option>
//                   );
//                 })}
//               <Option value="jack">Jack</Option>
//               <Option value="lucy">Lucy</Option>
//               <Option value="disabled" disabled>
//                 Disabled
//               </Option>
//               <Option value="Yiminghe">yiminghe</Option>
//             </Select>
//           </div>
//         </div>
//         <div className="row mt-4">
//           <div className="col-5 text-end pt-1">Brand Booth Visited</div>
//           <div className="col-7">
//             <Select
//               defaultValue="Select if required"
//               className="w-100"
//               onChange={handleChange}
//             >
//               <Option value="jack">Jack</Option>
//               <Option value="lucy">Lucy</Option>
//               <Option value="disabled" disabled>
//                 Disabled
//               </Option>
//               <Option value="Yiminghe">yiminghe</Option>
//             </Select>
//           </div>
//         </div>
//       </div>
//       <div className="col-4">
//         <div className="row">
//           <div className="col-5 text-end pt-1">Brand Sessions(s)</div>
//           <div className="col-7">
//             <Select
//               defaultValue="Select if required"
//               className="w-100"
//               onChange={handleChange}
//             >
//               <Option value="jack">Jack</Option>
//               <Option value="lucy">Lucy</Option>
//               <Option value="disabled" disabled>
//                 Disabled
//               </Option>
//               <Option value="Yiminghe">yiminghe</Option>
//             </Select>
//           </div>
//         </div>
//         <div className="row mt-4">
//           <div className="col-5 text-end pt-1">Booth Zone Visited</div>
//           <div className="col-7">
//             <Select
//               defaultValue="Select if required"
//               className="w-100"
//               onChange={handleChange}
//             >
//               <Option value="jack">Jack</Option>
//               <Option value="lucy">Lucy</Option>
//               <Option value="disabled" disabled>
//                 Disabled
//               </Option>
//               <Option value="Yiminghe">yiminghe</Option>
//             </Select>
//           </div>
//         </div>
//         <div className="row mt-4">
//           <div className="col-5 text-end pt-1">Zone Session</div>
//           <div className="col-7">
//             <Select
//               defaultValue="Select if required"
//               className="w-100"
//               onChange={handleChange}
//             >
//               <Option value="jack">Jack</Option>
//               <Option value="lucy">Lucy</Option>
//               <Option value="disabled" disabled>
//                 Disabled
//               </Option>
//               <Option value="Yiminghe">yiminghe</Option>
//             </Select>
//           </div>
//         </div>
//         <div className="col-12 mt-4 text-end">
//           <Button
//             key="back"
//             className="ant-btn ant-btn-primary py-1 px-4 rounded mx-4"
//             onClick={hideFilter}
//           >
//             Apply
//           </Button>
//           <Button
//             key="back"
//             className="ant-btn button-pink py-1 px-4 rounded"
//             onClick={handleClear}
//           >
//             Clear
//           </Button>
//         </div>
//       </div>
//     </div>
//   </div>
// );