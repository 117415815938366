import { UPLOAD_BASIC_PROPS } from "../components/constsnts/common.constants";
import { toast } from "react-toastify";
import moment from "moment";
import { sendFile } from "./commonApis";
import { cloneDeep } from "lodash";

export const isValidImage = (fileObj) => {
  const isCorrectMime = UPLOAD_BASIC_PROPS.imageProps.mimeTypeArr.includes(
    fileObj.type
  );
  const isWithinMaxLimit =
    fileObj.size / 1024 / 1024 < UPLOAD_BASIC_PROPS.imageProps.maxSize;
  if (!isCorrectMime) {
    toast.error(
      "Please upload " + UPLOAD_BASIC_PROPS.imageProps.fileType + " file"
    );
    return false;
  } else if (!isWithinMaxLimit) {
    toast.error(
      "File must be smaller than " +
        UPLOAD_BASIC_PROPS.imageProps.maxSize.toString() +
        " MB"
    );
    return false;
  } else return true;
};

export const isValidImageVideo = (fileObj) => {
  const isCorrectMime = UPLOAD_BASIC_PROPS.videoProps.mimeTypeArr.includes(
    fileObj.type
  );
  const isWithinMaxLimit =
    fileObj.size / 1024 / 1024 < UPLOAD_BASIC_PROPS.videoProps.maxSize;
  if (!isCorrectMime) {
    toast.error(
      "Please upload " + UPLOAD_BASIC_PROPS.videoProps.fileType + " file"
    );
    return false;
  } else if (!isWithinMaxLimit) {
    toast.error(
      "File must be smaller than " +
        UPLOAD_BASIC_PROPS.videoProps.maxSize.toString() +
        " MB"
    );
    return false;
  } else return true;
};

export const getDisabledHours = (d, t) => {
  let hours = [];
  if (d && t) {
    for (let i = 0; i < moment(d + " " + t).hour(); i++) {
      hours.push(i);
    }
    return hours;
  } else {
    return null;
  }
};

export function getFileNameFromPath(path) {
  let filename = "";
  if (path) filename = path.replace(/^.*[\\\/]/, "");
  if (filename === null || filename === "null") return "";
  else return filename;
}

export function getDatesBetweenDates(startDate, endDate) {
  let dateArray = [];
  let currentDate = moment(startDate);
  let stopDate = moment(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
}

export function getNearestFutureDate(dates) {
  let todayDateString = moment().format("YYYY-MM-DD");
  const now = new Date(todayDateString);
  let closest = Infinity;
  dates.forEach(function (d) {
    const dateToCheck = new Date(d);
    if (
      dateToCheck >= now &&
      (dateToCheck < new Date(closest) || dateToCheck < closest)
    ) {
      closest = d;
    }
  });
  if (closest === Infinity) return [];
  return closest;
}

export const chunkFileUpload = (file, cb) => {
  let chunkSize = 1024 * 1024 * 5;
  const chunksQuantity = Math.ceil(file.size / chunkSize);
  const chunksQueue = new Array(chunksQuantity)
    .fill(file)
    .map((_, index) => index)
    .reverse();
  const fileProgressCount = 100 / chunksQuantity;
  sendNext(chunksQueue, file, fileProgressCount, null, cb);
};

const sendNext = async (
  chunksQueue,
  file,
  fileProgressCount,
  fileKey = null,
  cb
) => {
  let chunkSize = 1024 * 1024 * 5;
  let _chunkSize = 1024 * 1024 * 5;
  let fileName = file.name;
  let _fileKey = fileKey;
  /**passing key from first chunk responce */
  if (fileKey) {
    fileName = fileKey;
  }
  if (chunksQueue.length === 1) {
    _chunkSize = file.size - _chunkSize * chunksQueue[0];
  }
  const chunkId = chunksQueue.pop();
  const sentSize = chunkId * _chunkSize;
  const chunk = file.slice(sentSize, sentSize + _chunkSize);
  const headerParam = {
    chunkId: chunkId,
    fileName: fileName,
    originalFileName: file.name,
    fileSize: file.size,
    chunkSize: _chunkSize,
    lastChunk: chunksQueue.length == 0 ? true : false,
    defaultChunkSize: chunkSize,
  };

  let responseData = await sendFile({ chunk, headerParam });

  if (chunkId === 0) {
    _fileKey = responseData.data["key"].toString();
    console.log(fileKey, _fileKey, responseData.data["key"].toString());
  }
  if (chunksQueue.length !== 0) {
    sendNext(chunksQueue, file, fileProgressCount, _fileKey, cb);
  } else {
    // file uploaded
    cb(responseData.data["key"].toString());
  }
};

export function isImage(urlToCheck) {
  if (
    urlToCheck &&
    (urlToCheck.includes("jpg") ||
      urlToCheck.includes("jpeg") ||
      urlToCheck.includes("png") ||
      urlToCheck.includes("JPG") ||
      urlToCheck.includes("JPEG") ||
      urlToCheck.includes("PNG"))
  )
    return true;
  else return false;
}

export const todayIsBeetweenTwoDates = (startDate, endDate) => {
  console.log("endDate", endDate);
  console.log("startDate", startDate);
  const today = moment().format("LL");

  const todayMoment = moment(today);

  const sd = moment(startDate).format("LL");
  const ed = moment(endDate).format("LL");

  // if (today > startDate && today > endDate) {
  //   return false;
  // }

  // if ((today <= endDate && today >= startDate) || sd == ed) {
  //   return true;
  // } else {
  //   return false;
  // }
  console.log("daytes", sd, ed, today);

  if ((sd == ed && sd == today) || ed == today || sd == today) {
    return true;
  } else if (todayMoment.isAfter(startDate) && todayMoment.isBefore(endDate)) {
    return true;
  } else {
    return false;
  }
};

export function capitalizeFirstLetter(str) {
  return str[0].toUpperCase() + str.slice(1);
}

export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform =
      window.navigator?.userAgentData?.platform ?? window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

export function compareAllStartEndDates(data) {
  let areEventDatesProper = "yes";
  data &&
    data.forEach((ele) => {
      if (
        ele.start_date &&
        ele.end_date &&
        moment(ele.end_date) < moment(ele.start_date)
      )
        areEventDatesProper = "no";
    });
  return areEventDatesProper;
}

export function compareAllDatesWithEveryArrayElement(data) {
  console.log("data  = ", data);
  let areAllDatesDifferent = "yes";
  let clonedData = [];
  if (data) clonedData = cloneDeep(data);
  data &&
    data.forEach((ele, indexHere) => {
      console.log("ele ,index = ", ele, indexHere);
      let cutArray = []; //after removing current item, will check in every array

      if (clonedData.length > 1) {
        cutArray = clonedData.filter((ele, eleIndex) => eleIndex !== indexHere);
      }

      console.log("cutArray = ", cutArray);
      cutArray &&
        cutArray.forEach((checkEle) => {
          const startEleDate = moment(ele.start_date);
          const endEleDate = moment(ele.end_date);
          const startCheckDate = moment(checkEle.start_date);
          const endCheckDate = moment(checkEle.end_date);
          if (
            ele.start_date === checkEle.start_date ||
            ele.end_date === checkEle.end_date ||
            ele.end_date === checkEle.start_date ||
            ele.start_date === checkEle.end_date ||
            (startEleDate.isBefore(startCheckDate) &&
              startEleDate.isBefore(endCheckDate) &&
              endEleDate.isAfter(startCheckDate) &&
              endEleDate.isBefore(endCheckDate))
          ) {
            areAllDatesDifferent = "no";
            console.log("condition mila step 222", ele);
          }
        });
    });
  console.log("areAllDatesDifferent = ", areAllDatesDifferent);
  return areAllDatesDifferent;
}
