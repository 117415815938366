import React, { Fragment, useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Card, Checkbox, Input, Select, Button } from "antd";
import SimpleReactValidator from "simple-react-validator";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "./index.css";
import { logo } from "../../components/Images/Images";
import { useSelector, useDispatch } from "react-redux";
import {
  getUser,
  getJobTitles,
  getRegisterUser
} from "../../redux/slice/register";
import { addUser } from "../../redux/slice/register";
import { getCountries } from "../../redux/slice/register";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { cloneDeep } from "@babel/types";
import { login } from "../../redux/slice/login";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { getUsers } from "../../utils/commonApis";

const Registration = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  var CryptoJS = require("crypto-js");

  const user = useSelector(state => state?.register?.registerUser);

  const loading = useSelector(state => state?.register?.registerLoading);

  const countries = useSelector(state => state?.register?.countries);
  const jobTitles = useSelector(state => state?.register?.jobtitles);
  const validator = useRef(new SimpleReactValidator());
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const { Option } = Select;
  const [forceUpdate, setForceUpdate] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);

  const [showError, setshowError] = useState(false);

  const [fields, setFields] = useState({});

  const checkCountryAndJobTitle = () => {
    //if country and jobtitle is not in array then adding it manually
    const newCountryArr = user?.country_details?.slice();
    const newJobTitleArr = user?.job_title_details?.slice();

    if (
      !newCountryArr?.some(
        item => item?.country_id === user?.user_details?.country_id
      )
    ) {
      newCountryArr?.push({
        country_id: user?.user_details?.country_id,
        country: user?.user_details?.country
      });
    }

    if (
      !newJobTitleArr?.some(
        item => item?.job_title_id === user?.user_details?.job_title_id
      )
    ) {
      newJobTitleArr?.push({
        job_title_id: user?.user_details?.job_title_id,
        job_title: user?.user_details?.job_title
      });
    }

    return {
      newCountryArr,
      newJobTitleArr
    };
  };

  const getLogoUrl = (userType, eventLogo, brandLogo) => {
    if (userType === "AGENT") {
      return brandLogo;
    } else if (eventLogo) {
      return eventLogo;
    } else {
      return logo;
    }
  };

  const initializeUserData = () => {
    const query = new URLSearchParams(location.search);
    const user_id = parseInt(query.get("user_id"));

    const { newCountryArr, newJobTitleArr } = checkCountryAndJobTitle();

    setUserType(user?.user_details?.user_type)
    if (user) {
      if (user?.user_details?.user_type === "AGENT") {
        let color = user?.user_details?.brand_color;
        window.less.modifyVars({ "@primary-color": color }).then(() => { });
      }

      setFields({
        user_id: user_id,
        first_name: user?.user_details?.first_name ?? "",
        last_name: user?.user_details?.last_name ?? "",
        email: user?.user_details?.email ?? "",
        company: user?.user_details?.company ?? "",
        country: user?.user_details?.country ?? "",
        job_title: user?.user_details?.job_title ?? "",
        job_title_id: user?.user_details?.job_title_id ?? null,
        country_id: user?.user_details?.country_id ?? null,
        user_zones: user?.user_details?.user_zones,
        event_id: user?.user_details?.event_id ?? null,
        password: "",
        confirmPassword: "",
        allow_other_country: user?.allow_other_country ?? false,
        block_other_country_text:
          user?.block_other_country_text ?? "Other Option not available",
        allow_other_job_title: user?.allow_other_job_title ?? false,
        block_other_job_title_text:
          user?.block_other_job_title_text ?? "Other Option not available",
        selectedCountries: newCountryArr,
        selectedJobTitles: newJobTitleArr,
        logo_url: getLogoUrl(
          user?.user_details?.user_type,
          user?.user_details?.event_logo_url,
          user?.user_details?.brand_logo_url
        )
      });
    }
  };

  useEffect(async () => {
    const query = new URLSearchParams(location.search);
    const user_id = query.get("user_id");
    setUserId(user_id);
    console.log("user_id = ", user_id);

    dispatch(getRegisterUser({ user_id }));

    dispatch(getCountries());
    dispatch(getJobTitles());
  }, []);

  useEffect(() => {
    if (user) {
      setInitialLoading(true);
      initializeUserData();
      setTimeout(() => {
      setInitialLoading(false);

      }, 500)
    }
  }, [user]);

  const handleFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  const handleRegisterSubmit = async e => {
    e.preventDefault();

    const finalUserZones = fields?.user_zones?.map(item => {
      return {
        zone_id: item?.zone_id,
        is_assigned: item?.is_assigned,
        is_always_get_messages: item?.is_always_get_messages
      };
    });

    const newUser = {
      first_name: fields?.first_name,
      last_name: fields?.last_name,
      email: fields?.email,
      user_id: fields?.user_id,
      company: fields?.company,
      job_title_id:
        fields?.job_title_id === "others" ? null : fields?.job_title_id,
      job_title: fields?.job_title,
      country_id: fields?.country_id === "others" ? null : fields?.country_id,
      country: fields?.country,
      password: fields?.password,
      zone_tag_details: finalUserZones
    };


    if (validator.current.allValid()) {

      if (fields.password !== fields.confirmPassword) {
        toast.error("Confirm Passord does not match");
      } else {
        console.log("FInal data to Send ->", newUser);
        const addUserResponse = await dispatch(addUser(newUser));
        console.log(addUserResponse.payload);
        if (addUserResponse.payload.code === 200) {
          let email = newUser.email;
          let password = newUser.password;
          let eventId = fields.event_id;
          loginDirectly(email, password, eventId);
          toast.success(addUserResponse.payload.message);
          setshowError(false);
        } else {
          toast.error(addUserResponse.payload.message);
          setshowError(true);
        }
      }
    } else {
      validator.current.showMessages();

      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setForceUpdate(!forceUpdate);
    }
  };

  const loginDirectly = async (email, password, eventId) => {
    let loginData = { email, password, event_id: eventId };
    const loginResp = await dispatch(login(loginData));
    console.log(loginResp);

    if (loginResp?.payload?.code === 200) {
      const sideMenuData = loginResp.payload.data.menu_acces_details;
      const userType = loginResp.payload.data.userType;
      const userName = loginResp.payload.data.user_name;

      const color = loginResp.payload.data.color;
      const eventColor = loginResp.payload.data.eventColor;

      setUserType(loginResp.payload.data.userType);
      console.log("userrtpe", userType);

      var ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(sideMenuData),
        "side_menu"
      ).toString();
      var ciphertext_user_type = CryptoJS.AES.encrypt(
        JSON.stringify(userType),
        "user_type"
      ).toString();
      var ciphertext_user_name = CryptoJS.AES.encrypt(
        JSON.stringify(userName),
        "user_name"
      ).toString();

      localStorage.setItem("side_menu", ciphertext);
      localStorage.setItem("user_type", ciphertext_user_type);
      localStorage.setItem("user_name", ciphertext_user_name);
      localStorage.setItem("Authorization", loginResp.payload.data.token);
      localStorage.setItem("event_id", loginResp.payload.data.event_id);
      localStorage.setItem("chat_uid", loginResp.payload.data.chat_uid);
      localStorage.setItem("customer_id", loginResp.payload.data.customer_id);

      let customThemeColorAndRoute = {
        color: "",
        route: ""
      };

      if (userType && (userType === "AGENT" || userType === "BRAND ADMIN")) {
        customThemeColorAndRoute = {
          color: color.brand_color,
          route: "/mycalendar"
        };
      } else if (userType === "DELEGATE") {
        customThemeColorAndRoute = {
          color: eventColor.event_color_theme,
          route: "/mycalendar"
        };
      } else if (userType === "SPEAKER") {
        customThemeColorAndRoute = {
          color: eventColor.event_color_theme
            ? eventColor?.event_color_theme
            : "#ef0855",
          route: "/mycalendar"
        };
      } else {
        customThemeColorAndRoute = {
          color: "#ef0855",
          route: "/eventmanagement"
        };
      }
      console.log("cusususus", customThemeColorAndRoute);
      window.less
        .modifyVars({
          "@primary-color": customThemeColorAndRoute.color
        })
        .then(() => { });
      localStorage.setItem("event_color_theme", customThemeColorAndRoute.color);

      toast.success(loginResp.payload.message);
      props.history.push(customThemeColorAndRoute.route);
      setshowError(false);
    }
    if (loginResp?.payload?.code === 403) {
      localStorage.removeItem("Authorization");
      toast.error(loginResp.payload.message);
      props.history.push("/login");
      setshowError(false);
    }

    if (loginResp?.payload?.code === 500) {
      localStorage.removeItem("Authorization");
      toast.error(loginResp.payload.message);
      props.history.push("/login");
      setshowError(false);
    }
  };



  if (loading || initialLoading) {
    return <CommonLoader />;
  } else
    return (
      <Fragment>
        <div className="registration-bg-img-div">

          <div className="site-card-border-less-wrapper py-5 text-center">
            <img
              className="regis_event_logo"
              src={fields?.logo_url}
              alt="logo"
            />

            <Card
              bordered={true}
              style={{ width: 500 }}
              headStyle={{ width: 500 }}
              className={
                Tablet
                  ? "m-auto w-50 mt-4 rounded registration-div"
                  : "m-auto w-25 mt-4 rounded registration-div"
              }
            >
              <h5 className="text-center mt-3 fs-26"> Sign up </h5>

              <form autoComplete="off">
                <div className="container px-5">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group text-start">
                        <label className="fs-16">
                          Forename
                          <sup>
                            <span
                              className="text-danger"
                              style={{ fontSize: "1.1rem" }}
                            >
                              *
                            </span>
                          </sup>
                        </label>
                        <Input
                          name="forename"
                          type="text"
                          className="form-control"
                          placeholder="Enter Forename"
                          autoComplete="forename"
                          className="py-2"
                          value={fields.first_name}
                          onChange={e =>
                            handleFieldsChange(e.target.value, "first_name")
                          }
                          onBlur={() =>
                            validator.current.showMessageFor("forename")
                          }
                        />
                        {validator.current.message(
                          "ForeName",
                          fields.first_name,
                          "required",
                          {
                            className: "text-danger py-1"
                          }
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group text-start">
                        <label className="fs-16">
                          Surname
                          <sup>
                            <span
                              className="text-danger"
                              style={{ fontSize: "1.1rem" }}
                            >
                              *
                            </span>
                          </sup>
                        </label>
                        <Input
                          name="surname"
                          type="text"
                          className="form-control"
                          placeholder="Enter Surname"
                          autoComplete="surname"
                          className="py-2"
                          value={fields.last_name}
                          onChange={e =>
                            handleFieldsChange(e.target.value, "last_name")
                          }
                          onBlur={() =>
                            validator.current.showMessageFor("surname")
                          }
                        />
                        {validator.current.message(
                          "Surname",
                          fields.last_name,
                          "required",
                          {
                            className: "text-danger py-1"
                          }
                        )}
                      </div>
                    </div>

                    <div className="col-6 mt-4">
                      <div className="form-group text-start">
                        <label className="fs-16">
                          Email Address
                          <sup>
                            <span
                              className="text-danger"
                              style={{ fontSize: "1.1rem" }}
                            >
                              *
                            </span>
                          </sup>
                        </label>
                        <Input
                          name="email"
                          type="email"
                          disabled
                          className="form-control"
                          placeholder="Enter Email Address"
                          autoComplete="username"
                          className="py-2"
                          value={fields.email}
                          onChange={e =>
                            handleFieldsChange(e.target.value, "email")
                          }
                          onBlur={() =>
                            validator.current.showMessageFor("email")
                          }
                        />
                        {validator.current.message(
                          "Email",
                          fields.email,
                          "required",
                          {
                            className: "text-danger py-1"
                          }
                        )}
                      </div>
                    </div>

                    {
                      userType === "AGENT" || userType === "BRAND ADMIN" ? (
                        <div className="col-6 mt-4">
                          </div>
                      ) : (
                        <>
                          <div className="col-6 mt-4">

                            <div className="form-group text-start">

                              <label className="fs-16">Company</label>
                              <Input
                                name="company"
                                type="text"
                                className="form-control"
                                placeholder="Enter Company"
                                autoComplete="company"
                                className="py-2"
                                value={fields.company}
                                onChange={e =>
                                  handleFieldsChange(e.target.value, "company")
                                }
                                onBlur={() =>
                                  validator.current.showMessageFor("company")
                                }
                              />

                        
                        
                            </div>
                          </div>
                        </>
                      )
                    }


                    <div className="col-6 mt-4">
                      <div className="form-group text-start">
                        <label className="fs-16">
                          Country
                          <sup>
                            <span
                              className="text-danger"
                              style={{ fontSize: "1.1rem" }}
                            >
                              {/* * */}
                            </span>
                          </sup>
                        </label>
                        <Select
                          showSearch
                          name="country"
                          listHeight={120}
                          listItemHeight={4}
                          className="w-100 mt-1"
                          placeholder="Select Country"
                          onChange={val => {
                            if (val === "others") {
                              setFields({
                                ...fields,
                                country: "",
                                country_id: val
                              });
                            } else {
                              const countryObj = fields?.selectedCountries.find(
                                item => {
                                  if (item.country_id === val) {
                                    return item?.country;
                                  }
                                }
                              );
                              setFields({
                                ...fields,
                                country_id: val,
                                country: countryObj?.country
                              });
                            }
                          }}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={
                            fields.country_id
                              ? fields.country_id
                              : "Select Country"
                          }
                          onBlur={() =>
                            validator.current.showMessageFor("country")
                          }
                        >
                          {fields?.selectedCountries?.map(item => {
                            return (
                              <Option
                                key={item.country_id}
                                value={item.country_id}
                              >
                                {item.country}
                              </Option>
                            );
                          })}
                          <Option key="others" value="others">
                            Others
                          </Option>
                        </Select>

                        {fields?.country_id === "others" &&
                          (fields?.allow_other_country ? (
                            <>
                              <p className="text-danger py-1">
                                {fields?.block_other_country_text}
                              </p>
                            </>
                          ) : (
                            <Input
                              name="otherCountry"
                              type="text"
                              className="form-control"
                              placeholder="Enter Other Country Name"
                              autoComplete="off"
                              className="py-2 mt-2"
                              onChange={e =>
                                handleFieldsChange(e.target.value, "country")
                              }
                              onBlur={() =>
                                validator.current.showMessageFor("otherCountry")
                              }
                            />
                          ))}
                      </div>
                    </div>

                    <div className="col-6 mt-4">
                      <div className="form-group text-start">
                        <label className="fs-16">Job Title</label>

                        <Select
                          showSearch
                          name="job_title"
                          className="w-100 mt-1"
                          placeholder="Select Job Title"
                          autoComplete="jobtitle"
                          listHeight={120}
                          listItemHeight={4}
                          onChange={val => {
                            if (val === "others") {
                              setFields({
                                ...fields,
                                job_title: "",
                                job_title_id: val
                              });
                            } else {
                              const jobTitleObj = fields?.selectedJobTitles.find(
                                item => {
                                  if (item.job_title_id === val) {
                                    return item?.job_title;
                                  }
                                }
                              );
                              setFields({
                                ...fields,
                                job_title_id: val,
                                job_title: jobTitleObj?.job_title
                              });
                            }
                          }}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={
                            fields.job_title_id
                              ? fields.job_title_id
                              : "Select Job Title"
                          }
                          onBlur={() =>
                            validator.current.showMessageFor("job_title")
                          }
                        >
                          {fields?.selectedJobTitles?.map(item => {
                            return (
                              <Option
                                key={item.job_title_id}
                                value={item.job_title_id}
                              >
                                {item.job_title}
                              </Option>
                            );
                          })}
                          <Option key="others" value="others">
                            Others
                          </Option>
                        </Select>
                        {fields?.job_title_id === "others" &&
                          (fields?.allow_other_job_title ? (
                            <p className="text-danger py-1">
                              {fields?.block_other_job_title_text}
                            </p>
                          ) : (
                            <Input
                              name="otherJobTitle"
                              type="text"
                              className="form-control"
                              placeholder="Enter Other Job Title"
                              autoComplete="off"
                              className="py-2 mt-2"
                              value={fields.jobInput}
                              onChange={e =>
                                handleFieldsChange(e.target.value, "job_title")
                              }
                            />
                          ))}
                      </div>
                    </div>

                    {fields?.user_zones?.length !== 0 ? (
                      <div className="col-12 text-start mt-4">
                        <label className="fs-16 d-block">Zone Tags</label>

                        <div className="w-100 border p-2 d-flex justify-content-between">
                          <span className="w-25">Zones</span>
                          <span className="w-25">Assign</span>
                          <span className="w-25">Always get Messages</span>
                        </div>
                        {console.log("Fields", fields)}

                        <div className="w-100 border p-2">
                          {fields?.user_zones &&
                            fields?.user_zones?.map(data => {
                              return (
                                <>
                                  <div className="mb-2 d-flex justify-content-between">
                                    <span className="w-25">
                                      {data?.zone_title}
                                    </span>
                                    <span className="w-25">
                                      <Checkbox
                                        checked={data?.is_assigned}
                                        className=""
                                        onChange={() =>
                                          setFields({
                                            ...fields,
                                            user_zones: fields?.user_zones?.map(
                                              elem => {
                                                if (
                                                  data?.zone_id ===
                                                  elem?.zone_id
                                                ) {
                                                  return {
                                                    ...elem,
                                                    is_assigned: !elem?.is_assigned
                                                  };
                                                }
                                                return elem;
                                              }
                                            )
                                          })
                                        }
                                      />
                                    </span>
                                    <span className="w-25">
                                      <Checkbox
                                        checked={data?.is_always_get_messages}
                                        disabled={!data?.is_assigned}
                                        onChange={() =>
                                          setFields({
                                            ...fields,
                                            user_zones: fields?.user_zones?.map(
                                              elem => {
                                                if (
                                                  data?.zone_id ===
                                                  elem?.zone_id
                                                ) {
                                                  return {
                                                    ...elem,
                                                    is_always_get_messages: !elem?.is_always_get_messages
                                                  };
                                                }
                                                return elem;
                                              }
                                            )
                                          })
                                        }
                                        className=""
                                      />
                                    </span>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* </>
                  ) : (
                    ""
                  )} */}

                    <div className="col-6 mt-2">
                      <div className="form-group text-start">
                        <label className="fs-16">Password</label>
                        <Input.Password
                          name="password"
                          type="password"
                          className="form-control"
                          placeholder="Enter password"
                          iconRender={visible =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                          className="py-2"
                          onBlur={() =>
                            validator.current.showMessageFor("password")
                          }
                          onChange={e =>
                            handleFieldsChange(e.target.value, "password")
                          }
                          autoComplete="new-password"
                        />
                        {validator.current.message(
                          "Password",
                          fields.password,
                          "required",
                          {
                            className: "text-danger py-1"
                          }
                        )}
                      </div>
                    </div>
                    <div className="col-6 mt-2">
                      <div className="form-group text-start">
                        <label className="fs-16">Confirm Password</label>
                        <Input.Password
                          name="confirmPassword"
                          type="password"
                          className="form-control"
                          placeholder="Enter password"
                          iconRender={visible =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                          className="py-2"
                          onBlur={() =>
                            validator.current.showMessageFor("password")
                          }
                          onChange={e =>
                            handleFieldsChange(
                              e.target.value,
                              "confirmPassword"
                            )
                          }
                          autoComplete="off"
                        />
                        {validator.current.message(
                          "Confirm Password",
                          fields.confirmPassword,
                          "required",
                          {
                            className: "text-danger py-1"
                          }
                        )}
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <Checkbox className="mx-1" />
                      <span>
                        Please check the box to show that you have read and
                        understood our{" "}
                        <span className="fs-pink">
                          <u>Terms & Conditions</u>
                        </span>
                      </span>
                    </div>
                    <div className="col-12">
                      <Button
                        type="submit"
                        className="w-100 mt-2 register_submit_btn"
                        onClick={handleRegisterSubmit}
                        loading={loading}
                      >
                        Register
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="mt-2 text-danger"></div>
              <p className="fs-14">
                Already Registered?{" "}

                <Link
                  to={{
                    pathname: `/login`,
                    state: { user_id: userId }
                  }}
                >
                  <span className="fs-pink">
                    <u>Sign In</u>
                  </span>
                </Link>
              </p>
            </Card>
          </div>
        </div>
      </Fragment>
    );
};

export default Registration;
