import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  Button,
  Popover,
  Breadcrumb,
  Input,
  Menu,
  Dropdown,
  message,
} from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  DownOutlined,
  UserOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./index.css";
import { onShowSizeChange } from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import {
  getBrand,
  changeBrandStatus,
} from "../../redux/slice/brandManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Filter } from "../../components/svgJS/Filter";
import { Select } from 'antd';
import CommonLoader from "../../components/Widgets/CommonLoader";


const BrandManagement = () => {
  var CryptoJS = require("crypto-js");
  const [searchData, setsearchData] = useState("");
  const [userType, setUserType] = useState("");
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [loadingBrand, setLoadingBrand] = useState(false);

  const { Option } = Select;

  useEffect(() => {
    dispatch(getBrand());
  }, []);

  useEffect(() => {
    setLoadingBrand(true);

    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    const cipherTextSideMenu = localStorage.getItem("side_menu");
    var bytes = CryptoJS.AES.decrypt(cipherTextSideMenu, "side_menu");
    var decryptedDataSideMenu = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let is_route_present = false;
    decryptedDataSideMenu.forEach(element => {
      if (element.route == window.location.pathname) {
        is_route_present = true
      }
    });

    if (is_route_present === false && decryptedData === "AGENT" || decryptedData === "DELEGATE") {
      window.location.href = '/pagenotfound'
    }

    window.scrollTo(0, 0);
    const searchObj = {
      search: searchData,
    };
    dispatch(getBrand(searchObj));
    setTimeout(() => {
      setLoadingBrand(false);
    }, 2000);
  }, [searchData]);

  const { rows } = useSelector((state) => state?.brandManagement?.data);

  const tableData =
    rows &&
    rows.map((elem) => {
      return {
        brand_id: elem.brand_id,
        brand: elem.brand_title,
        name: elem.customer.account_name,
        domain: elem.event_domain_name ?? "NA",
        tier: elem.tier?.tier ?? "NA",
        zones: elem.no_of_zones + '/' + elem.total_zone_count,
        assets: elem.no_of_assets_per_zone + "/" + elem.total_assets_count,
        agents: elem.no_of_agents + "/" + elem.total_agents_count,
        storage: elem.storage ?? "NA",
        streaming: elem.streaming ?? "NA",
        videoHours: elem.videoHours ?? "NA",
        contact: elem.contact ?? "NA",
        createdBy: elem.brands_created_by.first_name + " " + elem.brands_created_by.last_name ?? "NA",
        created_at: elem.created_at,
        logos: elem?.logos ?? "NA",
        gfx: elem.gfx ?? "NA",
        panos: elem.panos ?? "NA",
        is_active: elem.is_active,
      };
    });

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  function handleMenuClick(e) {
    message.info("Click on menu item.");
  }

  const handleStatus = (id, bool) => {
    dispatch(
      changeBrandStatus({
        brand_id: id,
        is_active: !bool,
      })
    );
  };

  const columns = [
    {
      title: "Brand ID",
      dataIndex: "brand_id",
      key: "brand_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      sorter: false,
      width: 120,
      className: "p-4",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: false,
      width: 100
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      sorter: false,
      width: 100
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <Button
          className={
            record.is_active === true
              ? "bg-button-green active_btns "
              : "bg-button-red  inactive_btns"
          }
          onClick={() => handleStatus(record.brand_id, record.is_active)}
          shape="round"
        >
          {record.is_active === true ? "Active" : "Inactive"}
        </Button>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100
    },
    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
      sorter: false,
      width: 100
    },
    {
      title: "# Zones",
      dataIndex: "zones",
      key: "zones",
      sorter: false,
      width: 100
    },
    {
      title: "# Assets",
      dataIndex: "assets",
      key: "assets",
      sorter: false,
      width: 100
    },
    {
      title: "# Agents",
      dataIndex: "agents",
      key: "agents",
      sorter: false,
      width: 100
    },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
      sorter: false,
      width: 100
    },
    {
      title: "Streaming",
      dataIndex: "streaming",
      key: "streaming",
      sorter: false,
      width: 100
    },
    {
      title: "Video Hours",
      dataIndex: "videoHours",
      key: "videoHours",
      sorter: false,
      width: 100
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      sorter: false,
      width: 100
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: false,
      width: 130
    },
    {
      title: "Created D/T",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120
    },
    {
      title: "Logos",
      dataIndex: "logos",
      key: "logos",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100
    },
    {
      title: "GFX",
      dataIndex: "gfx",
      key: "gfx",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100
    },
    {
      title: "Panos",
      dataIndex: "panos",
      key: "panos",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100
    },
    {
      title: "",
      dataIndex: "operation",
      fixed: "right",
      width: 70,
      align: "Center",
      render: (_, record) => (
        <Popover
          content={
            <div>
              <p className="options">
                <Link
                  className="options"
                  to={`viewbranddetails/${record.brand_id}`}
                >
                  <EyeOutlined /> <span className="">View Details</span>
                </Link>
              </p>
              <p className="options">
                <Link
                  className="options"
                  to={`editbranddetails/${record.brand_id}`}
                >
                  <EditOutlined /> <span className="">Edit Brand</span>
                </Link>
              </p>
              {userType && userType === "BRAND ADMIN" ? null : (
                <p className="options">
                  <DeleteOutlined /> <span className=""> Delete Brand </span>
                </p>
              )}
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      fixed: "right",
      width: 70,
      render: (_, record) => (
        <div className="px-1">
          <Link to={`editbranddetails/${record.brand_id}`}>
            <p className="options m-0">
              <EditOutlined className="" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const sortObj = {
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    };
    dispatch(getBrand(sortObj));
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
  };

  if (loadingBrand) {
    return <CommonLoader />;
  } else
    return (
      <Fragment>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="fs-24">Brand Management</h4>
            <Breadcrumb className="my-2" separator=">">
              <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
              <Breadcrumb.Item>Brand Management</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div>
            <Link to="createnewbrand">
              {userType && userType === "BRAND ADMIN" ? null : (
                <Button className="button-pink rounded px-3 py-1 mt-2">
                  <span className="fw-normal"> + Add New Brand </span>
                </Button>
              )}
            </Link>
          </div>
        </div>

        <div className="bg-white p-4">
          <div className="d-flex justify-content-between  mb-4">
            <div className="w-50">
              <Input
                size="medium"
                value={searchData}
                onChange={handleInputSearch}
                placeholder="Search Name, ID, Customer Name"
                style={{ width: "70%" }}
                prefix={<SearchOutlined />}
              />
            </div>

            <div className="d-flex">

              <Button className=" rounded" onClick={() => setShowFilter(!showFilter)}>
                <Filter />
                <span className="ms-2"> FILTER</span> <DownOutlined />
              </Button>

            </div>
          </div>
          <div className="mb-2">
            {
              showFilter ? (
                <div>
                  <Select
                    placeholder="Customer Name"
                    style={{
                      width: 280,
                    }}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                      Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>

                  <Select
                    placeholder="Event Name"
                    className="ms-2"
                    style={{
                      width: 280,
                    }}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                      Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>

                  <Select
                    placeholder="Creator Name"
                    className="ms-2"

                    style={{
                      width: 280,
                    }}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                      Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>

                  <Select
                    placeholder="Tier"
                    className="ms-2"

                    style={{
                      width: 280,
                    }}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                      Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>

                </div>
              ) : ""
            }

          </div>

          <Table
            className="listing_table"
            columns={columns}
            dataSource={tableData}
            pagination={{
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
            }}
            scroll={{
              x: 1300,
            }}
            bordered={false}
            showSizeChange={true}
            onChange={handleTableChange}
          />
        </div>
      </Fragment>
    );
};

export default BrandManagement;
